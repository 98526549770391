import React, {useMemo, useState} from 'react';
import { useQueries } from '@tanstack/react-query';
import { CartesianGrid, Legend, Line, LineChart, Tooltip, XAxis, YAxis } from "recharts";
import {Button, MenuItem, Select} from "@mui/material";
import ScenarioDataTable from "./ScenarioDataTable";
import PropTypes from "prop-types";
import { fetchForecastScenarioForecastTimeSlices, fetchForecastScenarioPositions } from "../../api";
import "./style/ForecastScenarioComparison.css";

export const transformData = (liquidityData, timeSliceData, filterId, forecastScenarioIds) => {

    const allTimeSlices = new Set(timeSliceData.flatMap(sliceArray => sliceArray.map(slice =>
        slice.year_month)));
    const sortedTimeSlices = Array.from(allTimeSlices).sort((a, b) => new Date(a) - new Date(b));

    const timeSliceIdToYearMonthMapping = timeSliceData.map(sliceArray => sliceArray.reduce((acc, slice) => {
        acc[slice.id] = slice.year_month;
        return acc;
    }, {}));

    return sortedTimeSlices.map(yearMonth => {
        const entry = {name: yearMonth};
        liquidityData.forEach((scenario, index) => {
            const scenarioId = forecastScenarioIds[index];
            const filteredData = scenario.find(item => item.id === filterId);
            if (filteredData) {
                const sliceId = Object.keys(timeSliceIdToYearMonthMapping[index]).find(id =>
                    timeSliceIdToYearMonthMapping[index][id] === yearMonth);
                if (sliceId) {
                    entry[scenarioId] = filteredData[sliceId];
                }
            }
        });
        return entry;
    });
};

   const get_liquidity_indicator = (indicatorKey, liquidityResults, timeSliceResults, selectedPosition,
                                    forecastScenarioIds) => {
        const isDataAvailable = liquidityResults.every(result => result?.data) &&
            timeSliceResults.every(result => result?.data);
        if (isDataAvailable) {
            return transformData(liquidityResults.map(result => result.data),
                timeSliceResults.map(result => result.data), indicatorKey, forecastScenarioIds);
        }
        return [];
   };
const FilterDropdown = ({ liquidityPositions, selectedPosition, handlePositionChange }) => (
    <div>
        <label htmlFor="position-select">Select Liquidity Position:</label>
        <Select id="position-select" value={selectedPosition} onChange={handlePositionChange} style={{ width: '200px' }}>
            {liquidityPositions.map(filter => (
                <MenuItem key={filter.key} value={filter.key}>
                    {filter.label}
                </MenuItem>
            ))}
        </Select>
    </div>
);

const Chart = ({ forecastScenarioIds, forecastScenarioNames, colors, transformedData, renderLines, selectedPosition,
                   liquidityPositions, handlePositionChange }) => (
    <div className='forecast-scenario-comparison'>
        <div className='forecast-heading'>
            <div className="chart-heading-content">
                <p className="chart-title"><strong>{
                    liquidityPositions.find(option => option.key === selectedPosition)?.label}</strong></p>
            </div>
            <div className="chart-heading-controls">
                <div className="filter-dropdown">
                    <FilterDropdown liquidityPositions={liquidityPositions}
                                    selectedPosition={selectedPosition} handlePositionChange={handlePositionChange} />
                </div>
            </div>
        </div>
        <div className="chart-container">
            <LineChart width={730} height={250} data={transformedData}
                       margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Legend />
                <Tooltip />
                {renderLines()}
            </LineChart>
        </div>
    </div>
);

const LoadingIndicator = () => <div>Loading...</div>;

const ErrorIndicator = () => <div>Error occurred</div>;

const ForecastScenarioComparison = ({ forecastScenarios }) => {
    const [selectedPosition, setSelectedPosition] = useState('liq_interest_payments');
    const liquidityPositions = [];

    const forecastScenarioIds = forecastScenarios.map(scenario => scenario.id);
    const forecastScenarioNames = forecastScenarios.map(scenario => scenario.name);
    const forecastScenarioData = forecastScenarios.map(scenario => ({id: scenario.id, name: scenario.name}));

    const colors = ['#3182bd', '#9ecae1', '#ff7f0e', '#2ca02c', '#d62728', '#9467bd', '#8c564b', '#e377c2', '#7f7f7f', '#bcbd22'];

    const [tableVisibility, setTableVisibility] = useState({
        table1: true,
        table2: true,
        table3: true,
    });

    const liquidityResults = useQueries({
        queries: forecastScenarioIds.map(id => ({
            queryKey: ['forecastScenarioLiquidity', id],
            queryFn: () => fetchForecastScenarioPositions(id, 'liquidity')
        }))
    });

    const timeSliceResults = useQueries({
        queries: forecastScenarioIds.map(id => ({
            queryKey: ['forecastScenarioForecastTimeSlices', id],
            queryFn: () => fetchForecastScenarioForecastTimeSlices(id)
        }))
    })

    const isLoading = liquidityResults.some(result => result.isLoading) ||
        timeSliceResults.some(result => result.isLoading);
    const isError = liquidityResults.some(result => result.isError) ||
        timeSliceResults.some(result => result.isError);

    const transformedData = useMemo(() => {
        if (!isLoading && !isError) {
            const isDataAvailable = liquidityResults.every(result => result?.data) &&
                timeSliceResults.every(result => result?.data);
            if (isDataAvailable) {
                console.log('timeSliceResults ', timeSliceResults)
                return transformData(liquidityResults.map(result => result.data),
                    timeSliceResults.map(result => result.data), selectedPosition, forecastScenarioIds);
            }
        }
        return [];
    }, [liquidityResults, timeSliceResults, isLoading, isError]);

    const handlePositionChange = (event) => {
        setSelectedPosition(event.target.value);
    };

    if (isLoading) {
        return <LoadingIndicator />;
    }

    if (isError) {
        return <ErrorIndicator />;
    }

    const renderLines = () => forecastScenarioIds.map((id, index) => (
        <Line key={forecastScenarioIds[index]} name={forecastScenarioNames[index]} type="linear" dataKey={id}
              stroke={colors[index % colors.length]} />
    ));

    function extractLiquidityPositions(liquidityResults) {
        const results = extractResultData(liquidityResults);
        results.forEach(addToLiquidityPositions);
        return liquidityPositions;
    }

    function extractResultData(liquidityResults) {
        return liquidityResults.map(result => result.data);
    }

    function addToLiquidityPositions(resultArray) {
        resultArray.forEach(addItemToPositions);
    }

    function addItemToPositions(item) {
        if (!liquidityPositions.some(lp => lp.key === item.key)) {
            liquidityPositions.push({ key: item.key, label: item.name });
        }
    }

    const toggleTableVisibility = (tableNumber) => {
        setTableVisibility((prevState) => ({
            ...prevState,
            [tableNumber]: !prevState[tableNumber],
        }));
    };

    extractLiquidityPositions(liquidityResults);

    return (
        <div className='forcast'>
            <Chart
                forecastScenarioIds={forecastScenarioIds}
                forecastScenarioNames={forecastScenarioNames}
                colors={colors}
                transformedData={transformedData}
                renderLines={renderLines}
                selectedPosition={selectedPosition}
                liquidityPositions={liquidityPositions}
                handlePositionChange={handlePositionChange}
            />

            <div className="container general-comparision-tables-container">
                <ScenarioDataTable indicator={liquidityPositions.find(option => option.key === selectedPosition)?.label}
                                   data={transformedData} lemmatization_length={130}
                                   forecastScenarioData={forecastScenarioData}/>
            </div>


            <div>
                <Button
                    sx={{margin: "20px"}}
                    color="primary"
                    variant="contained"
                    className="toggle-button" onClick={() => toggleTableVisibility('table1')}>
                    {tableVisibility.table1 ? 'Hide DSCR' : 'Show DSCR'}</Button>

                <Button
                    sx={{margin: "20px"}}
                    color="primary"
                    variant="contained"
                    className="toggle-button" onClick={() => toggleTableVisibility('table2')}>
                    {tableVisibility.table2 ? 'Hide DSCR (inkl. Cash und freier Linie)' : 'Show DSCR (inkl. Cash und freier Linie)'}</Button>

                <Button
                    sx={{margin: "20px"}}
                    color="primary"
                    variant="contained"
                    className="toggle-button" onClick={() => toggleTableVisibility('table3')}>
                    {tableVisibility.table3 ? 'Hide DSCR operativ' : 'Show DSCR operativ'}</Button>

            </div>


            <div className="container liquidity-tables-container">

                <div>
                    {tableVisibility.table1 && (
                        <ScenarioDataTable
                            indicator="DSCR"
                            data={get_liquidity_indicator('liq_indicators_1', liquidityResults,
                                timeSliceResults, selectedPosition, forecastScenarioIds)}
                            lemmatization_length={20} forecastScenarioData={forecastScenarioData}/>
                    )}
                </div>

                <div>
                    {tableVisibility.table2 && (
                        <ScenarioDataTable
                            indicator="DSCR (inkl. Cash und freier Linie)"
                            data={get_liquidity_indicator('liq_indicators_2', liquidityResults,
                                timeSliceResults, selectedPosition, forecastScenarioIds)}
                            lemmatization_length={50} forecastScenarioData={forecastScenarioData}/>
                    )}
                </div>

                <div>
                    {tableVisibility.table3 && (
                        <ScenarioDataTable
                            indicator="DSCR operativ (FFO + Zinsaufwendungen+working capital Veränderungen / Tilgungen + Zinsaufwendungen)"
                            data={get_liquidity_indicator('liq_indicators_3', liquidityResults,
                                timeSliceResults, selectedPosition, forecastScenarioIds)}
                            lemmatization_length={30} forecastScenarioData={forecastScenarioData}
                        />
                    )}
                </div>

            </div>
        </div>
    );
};

FilterDropdown.propTypes = {
    liquidityPositions: PropTypes.array.isRequired,
    selectedPosition: PropTypes.string.isRequired,
    handlePositionChange: PropTypes.func.isRequired
};

Chart.propTypes = {
    liquidityPositions: PropTypes.array.isRequired,
    selectedPosition: PropTypes.string.isRequired,
    handlePositionChange: PropTypes.func.isRequired,
    forecastScenarioNames: PropTypes.array.isRequired,
    colors: PropTypes.array.isRequired,
    transformedData: PropTypes.array.isRequired,
    renderLines: PropTypes.func.isRequired,
    forecastScenarioIds: PropTypes.number.isRequired
};

ForecastScenarioComparison.propTypes = {
    forecastScenarios: PropTypes.array.isRequired
};

export default ForecastScenarioComparison;