import {Box, Typography} from "@mui/material";
import {useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import * as api from "../../api";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import {riskStudioColors as colors} from "../../styles/colors";
import useMediaQuery from "@mui/material/useMediaQuery";

const IndustryComponent = ({refreshData}) => {
    const [data, setData] = useState([]);
    const {companyId} = useParams();
    const matches = useMediaQuery("(max-width:1920px)");
    const fetchData = async () => {
        try {
            const result = await api.fetchIndustryData(companyId);
            if (result?.data) {
                setData(result.data);
            }
        } catch (error) {
            console.error("An error occurred:", error);
            // You can also set some state to show an error message or perform some other action.
        }
    };

    useEffect(() => {
        fetchData();
    }, [refreshData]);

    return (<Box sx={{ margin: matches ? "1rem 2rem" : "1rem 0rem 1rem 6rem" }}>
            <Stack
                direction="row"
                divider={<Divider orientation="vertical" flexItem/>}
                spacing={3}
                sx={{
                    margin: matches ? "1rem 2rem" : "1rem 0rem 1rem 6rem",
                }}
            >
                <Typography variant="h7">
                    NACE Industry Code:{" "}
                    <Typography
                        variant="h7"
                        sx={{color: colors.gridWrapperPrimaryColor, fontWeight: "bold"}}
                    >
                        {data?.nace_code_level_three}
                    </Typography>
                </Typography>
                <Typography variant="h7">
                    NACE Industry Description:{" "}
                    <Typography
                        variant="h7"
                        sx={{color: colors.gridWrapperPrimaryColor, fontWeight: "bold"}}
                    >
                        {data?.nace_name_level_three_de}
                    </Typography>
                </Typography>
            </Stack>
            <Stack
                direction="row"
                divider={<Divider orientation="vertical" flexItem/>}
                spacing={3}
                sx={{
                    margin: matches ? "1rem 2rem" : "1rem 0rem 1rem 6rem",
                }}
            >
                <Typography variant="h7">
                    GICS Industry Code:{" "}
                    <Typography
                        variant="h7"
                        sx={{color: colors.gridWrapperPrimaryColor, fontWeight: "bold"}}
                    >
                        {data?.gics_code_level_three}
                    </Typography>
                </Typography>
                <Typography variant="h7">
                    GICS Industry Description:{" "}
                    <Typography
                        variant="h7"
                        sx={{color: colors.gridWrapperPrimaryColor, fontWeight: "bold"}}
                    >
                        {data?.gics_name_level_three_de}
                    </Typography>
                </Typography>
            </Stack>
        </Box>);
};

export default IndustryComponent;
