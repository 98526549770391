class LoanConditionUtilsProvider {
  constructor(loanConditionPositions = {}) {
    this.loanConditionPositions = loanConditionPositions;
  }
  covenantsRotationPosition = [
    {
      key: "quarterly",
      value: 1,
      description: "Quarterly",
    },
    {
      key: "half_yearly",
      value: 2,
      description: "Half Yearly",
    },
    {
      key: "yearly",
      value: 3,
      description: "Yearly",
    },
  ];
  getCategory = (selectedCategory) => {
    switch (selectedCategory) {
      case "LoanRepaymentEnum":
        return "LoanRepayment";
      case "CovenantsEnum":
        return "Covenants";
      case "InvestorProtectionEnum":
        return "InvestorProtection";
      case "UseOfLoanEnum":
        return "UseOfLoan";
      case "DisbursementRequirementsEnum":
        return "DisbursementRequirements";
      default:
        return "Repayment";
    }
  };
  getConditionColumnHeader = (category) => {
    switch (category) {
      case "Covenants":
        return "Covenant:";
      case "InvestorProtection":
        return "Investor protection:";
      case "UseOfLoan":
        return "Use of Loan";
      case "DisbursementRequirements":
        return "Disbursement requirements:";
      case "LoanRepayment":
        return "Die Rückzahlung/Das Darlehen…";
      default:
        return "Repayment";
    }
  };
};

export default LoanConditionUtilsProvider;
