import { Box, Typography } from "@mui/material";
import { riskStudioColors as colors } from "../../styles/colors";
import KpiComponent from "./KpiComponent";
import useMediaQuery from "@mui/material/useMediaQuery";
function KPIsWrapper(props) {
  const matches = useMediaQuery("(max-width:1500px)");
  return (
    <Box
      sx={{ alignItems: "center", display: "flex", flexDirection: "column" }}
    >
      <Box
        sx={{
          backgroundColor: colors.gridWrapperPrimaryColor,
          borderRadius: "5px 5px 0px 0px",
          padding: "0.3rem 1rem",
          textTransform: "uppercase",
          border: "none",
          width: matches ? "90vw" : "80vw",
        }}
      >
        <Typography variant="h6" color={colors.white} fontWeight="bold">
          Analytische Übersicht
        </Typography>
      </Box>
      <div>
        <KpiComponent
          groupId={props.groupId}
          refreshData={props.refreshData}
          borrowerGroupId={props.borrowerGroupId}
        />
      </div>
    </Box>
  );
}
export default KPIsWrapper;
