import { riskStudioColors as colors } from "../../styles/colors";
import { Button } from "@mui/material";
import { evaluate } from 'mathjs';


/**
 * Generate a string representing the month and year from a given date string.
 * @param {string} date - A date string.
 * @return {string} Month and year in 'MM-YYYY' format.
 */
export const getHeaderNameForMonthYear = (date) => {
  const newDate = new Date(date);
  const month = (newDate.getMonth() + 1).toString().padStart(2, "0");
  const year = newDate.getFullYear().toString();
  return `${month}-${year}`;
};

export function findDifferences(updatedRow, originalRow) {
  const keys = Object.keys(updatedRow);
  const differences = keys.filter(
    (key) => updatedRow[key] !== originalRow[key]
  );
  return differences;
}

export function evaluateFormula(formula) {
  try {
    // Use mathjs to evaluate the formula
    return evaluate(formula);
  } catch (e) {
    console.error("Failed to evaluate formula:", e);
    // Return the original formula on error, or you might choose to handle errors differently
    return formula;
  }
}

export const processTimeslicesToColumnHeaders = (
  data,
  setTimeSlices,
  showPositions,
  gridFormatterClient
) => {
  const transformedData = data.map((item) => ({
    field: item.id.toString(),
    headerAlign: "center",
    size: 50,
    flex: 1,
    editable: true,
    maxWidth: 150,
    align: "right",
    cellClassName: gridFormatterClient.generateCellClassNameForBalanceSum,
    renderCell: (params) => {
      // Check if the cell value is 0 (as a number or a string)
      if (params.value === 0 || params.value === "0") {
        // Return a custom cell with an empty string or any other placeholder you wish to display
        return <div>{""}</div>;
      }
      // Otherwise, return the original renderCellForValues method
      return gridFormatterClient.renderCellForValues(params);
    },
    renderHeader: (params) => {
      return (
        <strong style={{ color: colors.balanceSumTextColor }}>
          {getHeaderNameForMonthYear(item.year_month.toString())}
        </strong>
      );
    },
  }));
  const positionNameColumn = {
    field: "name",
    size: 300,
    editable: false,
    flex: 1,
    renderCell: gridFormatterClient.renderCellForPositionName,
    renderHeader: (params) => {
      return (
        <strong style={{ color: colors.balanceSumTextColor }}>
          Position Name
        </strong>
      );
    },
  };
  if (showPositions) {
    transformedData.unshift(positionNameColumn);
  }

  setTimeSlices(transformedData);
};

// This function returns the data in the format required for the react table in the dialog when selecting documents for timeslices
export const processDataForSelectFiles = (data) => {
  const columnsForTable = [
    {
      accessorKey: "id",
      header: "id",
      size: 50,
    },
    {
      accessorKey: "crm_link",
      header: "CRM Link",
      size: 50,
      Cell: ({ cell }) => (
        <Button
          variant="contained"
          color="primary"
          onClick={() =>
            window.open(cell.getValue(), "_blank", "noopener,noreferrer")
          }
          size="small"
        >
          View
        </Button>
      ),
    },

    {
      accessorKey: "name",
      header: "File Name",
      size: 50,
    },
    {
      accessorKey: "type",
      header: "File Type",
      size: 50,
    },
    {
      accessorKey: "reporting_month",
      header: "Reporting Month",
      size: 50,
    },
    {
      accessorKey: "reporting_year",
      header: "Reporting Year",
      size: 50,
    },
  ];
  const rowForTable = data.map((f) => ({
    id: f.id,
    name: f.file,
    type: f.document_type_id,
    reporting_year: f.referred_year,
    crm_link: `${process.env.REACT_APP_CSP_BASE_URL}/companies/${f.parent_id}/documents/${f.id}/download/${f.file}`,
    reporting_month: f.referred_month,
  }));

  return { columnsForTable, rowForTable };
};
// This function returns the combined data of already linked documents and all documents which is unique in value - Used in selecting documents for timeslices
export const getCombinedDocumentsData = (
  alreadylinkedDocuments,
  allDocuments
) => {
  const combinedArray = alreadylinkedDocuments.concat(allDocuments);
  const uniqueCombinedArray = Array.from(
    new Set(combinedArray.map((obj) => obj.id))
  ).map((id) => {
    return combinedArray.find((obj) => obj.id === id);
  });
  return uniqueCombinedArray;
};
