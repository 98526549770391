import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  DialogActions,
  Dialog,
  DialogContent,
  Button,
  DialogTitle,
  Stepper,
  StepLabel,
  Step,
} from "@mui/material";
import Box from "@mui/material/Box";
import { MaterialReactTable } from "material-react-table";
import * as api from "../../api";
import { proformaSteps } from "../CreateDialog/CreateDialogHelpers/options";
import {
  fetchProformaFromTimeslice,
  postProforma,
  putProforma,
} from "../../api";
import { useParams } from "react-router-dom";
import { AiOutlineAreaChart } from "react-icons/ai";
import ProformaGrids from "./ProformaGrids";
import { useDynamicCalculations } from "./ProformaHooks";
import ProformaStatusBox from "./ProformaStatusBox";

function getProformaStatus(data, id) {
  /**
   * Retrieves the 'proforma_exists' value for a given id from the data list.
   *
   * @param {Array} data - The list of objects containing 'id' and 'proforma_exists' values.
   * @param {number} id - The ID for which to retrieve the 'proforma_exists' value.
   *
   * @returns {boolean|null} - The 'proforma_exists' value for the given id. Returns null if the id is not found.
   */
  if (!data) {
    return null;
  }

  for (let item of data) {
    if (String(item.id) === String(id)) {
      return item.proforma_exists;
    }
  }
  return null;
}

const ProformaDialog = () => {
  const [open, setOpen] = useState(false);
  const [rowSelection, setRowSelection] = useState({});
  const [timeSliceList, setTimeSliceList] = useState([]);
  const [activeStep, setActiveStep] = useState(0);
  const [timeSliceId, setTimeSliceId] = useState(null);
  const [proformaExists, setProformaExists] = useState(null);
  const [proformaPositions, setProformaPositions] = useState({
    sources: [],
    uses: [],
  });
  const [initialValues, setInitialValues] = useState({});

  const { companyId } = useParams();

  const timeSliceColumns = [
    {
      accessorKey: "id",
      header: "id",
      size: 50,
    },
    {
      accessorKey: "year_month",
      header: "Year Month",
      size: 50,
    },
    {
      accessorKey: "proforma_exists",
      header: "Proforma",
      size: 50,
      Cell: ({ cell }) => <span>{cell.getValue().toLocaleString()}</span>,
    },
  ];
  timeSliceColumns.propTypes = {
    Cell: PropTypes.func,
  };
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleCreate = async () => {
    // Add async here
    try {
      const data = Object.entries(values).reduce((acc, [key, value]) => {
        acc[key] = value === "" ? 0 : value;
        return acc;
      }, {});
      if (proformaExists === true) {
        await putProforma(timeSliceId, data); // Add await here
      } else {
        await postProforma(timeSliceId, data); // Add await here
      }
      setOpen(false);
      setActiveStep(0);
      setRowSelection({});
      resetValues();
    } catch (error) {
      console.error(error);
    }
  };

  const handleClose = () => {
    setOpen(false);
    setRowSelection({});
  };

  useEffect(() => {
    const fetchTimeSlices = async () => {
      try {
        const response = await api.fetchTimeSlices(companyId);
        setTimeSliceList(response.data);
      } catch (error) {
        console.error(error);
      }
    };

    fetchTimeSlices();
  }, [open]);

  useEffect(() => {
    const res = getProformaStatus(timeSliceList, Object.keys(rowSelection)[0]);
    setProformaExists(res);
    setTimeSliceId(Object.keys(rowSelection)[0]);
  }, [rowSelection, timeSliceList]);

  const handleSelection = (newValue) => {
    setRowSelection(newValue);
  };

  const handleNext = async () => {
    // if proforma exists, query proforma for timeslice
    if (proformaExists) {
      // get data
      const response = await fetchProformaFromTimeslice(timeSliceId);
      updateValuesFromFetch(response.data);
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    resetValues();
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  useEffect(() => {
    const fetchProformaPositions = async () => {
      try {
        const response = await api.fetchProforma();
        setProformaPositions(response.data);
      } catch (error) {
        console.error(error);
      }
    };

    fetchProformaPositions();
  }, []);

  useEffect(() => {
    const values = [
      ...proformaPositions.sources,
      ...proformaPositions.uses,
    ].reduce(
      (acc, cur) => ({ ...acc, [cur.id]: cur.type === "input" ? "" : 0 }),
      {}
    );

    setInitialValues(values);
  }, [proformaPositions]);

  const [
    values,
    handleChange,
    isValid,
    sourcesSum,
    usesSum,
    changeOfActiva,
    changeOfPassiva,
    resetValues,
    updateValuesFromFetch,
  ] = useDynamicCalculations(initialValues);

  return (
    <Box>
      <Button
        size="small"
        variant="outlined"
        color="primary"
        onClick={handleClickOpen}
      >
        <AiOutlineAreaChart
          style={{ marginBottom: "3px", marginRight: "5px", fontSize: "1rem" }}
        />
        Proforma
      </Button>
      <Dialog open={open} onClose={handleClose} maxWidth={false}>
        <DialogTitle>Proforma</DialogTitle>
        <Stepper activeStep={activeStep} alternativeLabel>
          {proformaSteps.map((label) => (
            <Step key={label.id}>
              <StepLabel>{label.name}</StepLabel>
            </Step>
          ))}
        </Stepper>
        <DialogContent>
          {activeStep === 0 && (
            <MaterialReactTable
              columns={timeSliceColumns}
              data={timeSliceList}
              enableRowSelection
              onRowSelectionChange={handleSelection} //connect internal row selection state to your own
              state={{ rowSelection }} //pass our managed row selection state to the table to use
              getRowId={(originalRow) => originalRow.id}
              muiTableBodyRowProps={({ row }) => ({
                onClick: row.getToggleSelectedHandler(),
                sx: { cursor: "pointer" },
              })}
              enableMultiRowSelection={false}
            />
          )}
          {activeStep === 1 && (
            <Box
              display="flex"
              flexDirection={"column"}
              alignItems={"center"}
              justifyContent={"center"}
              padding={"1rem"}
            >
              <Box
                display="flex"
                alignItems={"flex-start"}
                justifyContent={"center"}
                gap={"3rem"}
              >
                <ProformaGrids
                  proformaPositions={proformaPositions}
                  values={values}
                  handleChange={handleChange}
                  renderType="sources"
                />
                <ProformaGrids
                  proformaPositions={proformaPositions}
                  values={values}
                  handleChange={handleChange}
                  renderType="uses"
                />
              </Box>
              <ProformaStatusBox
                isValid={isValid}
                sourcesSum={sourcesSum}
                usesSum={usesSum}
                changeOfActiva={changeOfActiva}
                changeOfPassiva={changeOfPassiva}
              />
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            color="primary"
            onClick={activeStep === 0 ? handleClose : handleBack}
          >
            {activeStep === 0 ? "Cancel" : "Back"}
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={
              activeStep === proformaSteps.length - 1
                ? handleCreate
                : handleNext
            }
            disabled={
              (activeStep === 0 && Object.keys(rowSelection).length === 0) ||
              (activeStep === 1 && !isValid)
            }
          >
            {activeStep === proformaSteps.length - 1
              ? proformaExists
                ? "Update"
                : "Create"
              : "Next"}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default ProformaDialog;
