import React from "react";
import { Box, Button, Typography } from "@mui/material";
import { updateCompanyData } from "../../api";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import PropTypes from "prop-types";

// COMPONENT TO SHOW CONFIRMATION TO UNLINK CREFO
const CrefoUnlinkPrompt = ({
  companyId,
  setRefreshCrefoComponent,
  setOpenCrefoModal,
}) => {
  const [unlinkMessage, setUnlinkMessage] = React.useState(null);
  const [loading, setLoading] = React.useState(false);

  // HELPER FUNCTION TO UNLINK CREFO
  const handleUnlink = () => {
    setLoading(true);
    updateCompanyData(companyId, { crefo_id: null })
      .then((_) => {
        setUnlinkMessage({
          message: `Unlinked Crefo Successfully!`,
          type: "success",
        });
        setLoading(false);
      })
      .catch((err) => {
        setUnlinkMessage({
          message: err.response?.data?.errors?.[0]?.title,
          type: "error",
        });
        setLoading(false);
      })
      .finally(() => {
        setTimeout(() => {
          setOpenCrefoModal(false);
          setUnlinkMessage(null);
          setRefreshCrefoComponent(true);
        }, 1000);
      });
  };
  // HANDLE CLOSE MODAL
  const handleClose = () => {
    setOpenCrefoModal(false);
  };
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        marginBottom: "3rem",
      }}
    >
      {/* HELPER TEXT TO SHOW CONFIRMATION */}
      {!unlinkMessage && (
        <Box>
          <Typography
            variant="h6"
            sx={{ marginBottom: "3rem", fontSize: "calc(0.9rem + 0.2vw)" }}
          >
            ⚠️ Are you sure you want to unlink crefo for this company?
          </Typography>
          <Box
            sx={{
              display: "flex",
              width: "60%",
              justifyContent: "space-between",
            }}
          >
            <Button onClick={handleClose} variant="outlined">
              cancel
            </Button>
            <Button onClick={handleUnlink} variant="contained">
              {loading ? "unlinking..." : "confirm"}
            </Button>
          </Box>{" "}
        </Box>
      )}
      {/* HELPER TEXT TO SHOW CREFO IS UNLINKED */}
      {unlinkMessage && (
        <Box sx={{ display: "flex" }}>
          <CheckCircleIcon
            sx={{ fontSize: "25px", marginLeft: "10px" }}
            color="success"
          />
          <Typography
            variant="h6"
            color={unlinkMessage.type}
            sx={{ marginLeft: "1rem" }}
          >
            {unlinkMessage.message}
          </Typography>
        </Box>
      )}
    </Box>
  );
};
CrefoUnlinkPrompt.propTypes = {
  companyId: PropTypes.string.isRequired,
  setRefreshCrefoComponent: PropTypes.func.isRequired,
  setOpenCrefoModal: PropTypes.func.isRequired,
};
export default CrefoUnlinkPrompt;
