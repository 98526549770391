import * as React from "react";
import PropTypes from "prop-types";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import { Box, Typography, Button } from "@mui/material";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import CustomToolTip from "../CustomToolTip";
import { riskStudioColors } from "../../styles/colors";
import CommentTextField from "./CommentTextField";


export const RowCommentDialog = ({
  onClose,
  selectedValue,
  open,
  timeSlices,
  comments: allComments,
  refetchComments,
  setRefetchComments,
  handleUpdateRow,
}) => {
  const [payloadForUpdateRow, setPayloadForUpdateRow] = React.useState({});
  // Handle the close button
  const handleClose = () => {
    // This updates the row with the new comment in UI only
    handleUpdateRow(payloadForUpdateRow);
    // Close the dialog 
    onClose();
  };

 // This function returns the time slice ids for the selected row
  const getTimeSliceIds = (row) => {
    const result = [];
    Object.keys(row).forEach((key) => {
      if (!["id", "name", "key", "comment"].includes(key)) {
        result.push(key);
      }
    });
    return result;
  };

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      disableEscapeKeyDown
      PaperProps={{
        sx: {
          padding: "1rem 2rem",
          justifyContent: "center",
          display: "flex",
          alignItems: "center",
          maxWidth: "700px",
        },
      }}
    >
      <DialogTitle
        sx={{
          fontSize: "calc(0.8rem + 0.2vw)",
          textAlign: "center",
          width: "500px",
          alignItems: "center",
          justifyContent: "center",
          display: "flex",
          marginTop: "1rem",
        }}
      >
        <Typography variant="h7">
          💬 Comment for "
          <CustomToolTip
            title={selectedValue?.name.length > 60 ? selectedValue?.name : ""}
          >
            <span style={{ color: riskStudioColors.primaryColor }}>
              {selectedValue?.name.length > 60
                ? selectedValue?.name.slice(0, 60) + "..."
                : selectedValue?.name || "row"}
            </span>
          </CustomToolTip>
          "
        </Typography>
      </DialogTitle>

      <List sx={{ padding: "0rem 2rem 1rem" }}>
        {selectedValue &&
          getTimeSliceIds(selectedValue)?.map((sliceId) => {
            return (
              <ListItem disableGutters key={`timeslice-#${sliceId}`}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: 2,
                    width: "100%",
                  }}
                >
                  <Typography variant="h7" color="primary">
                    {`${
                      timeSlices
                        .find((ts) => ts.id === Number(sliceId))
                        ?.year_month.split("-")[0]
                    } :`}
                  </Typography>
                  <CommentTextField
                    sliceId={sliceId}
                    selectedValue={selectedValue}
                    allComments={allComments}
                    setPayloadForUpdateRow={setPayloadForUpdateRow}
                    refetchComments={refetchComments}
                    setRefetchComments={setRefetchComments}
                  />
                </Box>
              </ListItem>
            );
          })}
      </List>

      <Button
        variant="outlined"
        size="small"
        sx={{ width: "100px", marginBottom: "1rem", alignSelf: "center" }}
        onClick={handleClose}
      >
        Close
      </Button>
    </Dialog>
  );
};

// PROP TYPES
RowCommentDialog.propTypes = {
  selectedValue:
    PropTypes.shape({
      key: PropTypes.string,
    }) || null,
  timeSlices: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      year_month: PropTypes.string,
    })
  ).isRequired,
  comments: PropTypes.object.isRequired,
  refetchComments: PropTypes.bool.isRequired,
  setRefetchComments: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  handleUpdateRow: PropTypes.func.isRequired,
};
