/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid";
import { Box, Switch, FormControlLabel } from "@mui/material";
import * as api from "../../api";
import {
  getConvertedRowForAU,
  getTimeSliceColumns,
  getTimeSliceColumnsGroups,
  gridStyleForAU,
} from "../ViewDataGrid/ViewDataGridUtils";
import { useParams } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";
import { riskStudioColors as colors } from "../../styles/colors";

function CustomToolbar({ toggleMode, isMode2 }) {
  return (
    <GridToolbarContainer>
      <GridToolbarDensitySelector />
      <GridToolbarQuickFilter />
      <FormControlLabel
        control={
          <Switch
            checked={isMode2}
            onChange={toggleMode}
            name="modeSwitch"
            color="primary"
          />
        }
        label="Show All"
      />
    </GridToolbarContainer>
  );
}

const KpiComponent = ({ groupId, refreshData, borrowerGroupId }) => {
  const [timeSlices, setTimeSlices] = useState([]);
  const [overviewColumnGroups, setOverviewColumnGroups] = useState([]);
  const [kpiData, setKpiData] = useState([]);
  const [isMode2, setIsMode2] = useState(true);
  const matches = useMediaQuery("(max-width:1500px)");
  const { companyId } = useParams();

  const kpiSuffixes = ["HGB", "Analytics", "in%"];

  const getTimeSlices = () => {
    const suffixes = isMode2 ? kpiSuffixes : ["Analytics"];
    if (groupId === null) {
      return api
        .fetchTimeSlices(companyId)
        .then((response) => {
          setTimeSlices(getTimeSliceColumns(response.data, matches, suffixes));
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      return api
        .fetchTimeSlicesFromGroup(groupId, false)
        .then((response) => {
          setTimeSlices(getTimeSliceColumns(response.data, matches, suffixes));
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  const getKpis = (groupId) => {
    if (groupId !== null) {
      return api
        .fetchKpisFromGroup(groupId, borrowerGroupId)
        .then((response) => {
          // @getConvertedRowForAU - Here it's converting the response with additional empty rows and row names only
          const convertedRow = getConvertedRowForAU(response.data);
          setKpiData(convertedRow);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  useEffect(() => {
    if (groupId !== null) {
      getTimeSlices();
      getKpis(groupId);
    } else {
      setKpiData([]);
      setTimeSlices([]);
    }
  }, [groupId, isMode2, refreshData]);

  useEffect(() => {
    if (timeSlices.length > 0) {
      api
        .fetchTimeSlicesFromGroup(groupId, false)
        .then((response) => {
          const suffixes = isMode2 ? kpiSuffixes : ["Analytics"];
          setOverviewColumnGroups(
            getTimeSliceColumnsGroups(response.data, suffixes)
          );
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [timeSlices]);

  return (
    <Box display="flex" justifyContent="center" flexDirection="column">
      <Box
        sx={{
          width: matches ? "90vw" : "80vw",
          height: "75vh",
          "& .MuiDataGrid-columnHeaders": {
            border: `2px solid #b0bec5`,
            borderRadius: "0px",
          },
          "& .view-kpi_gap": {
            backgroundColor: colors.gridInsideColorLight,
          },
          "& .view-kpi_gap.view-kpi_gap--active": {
            backgroundColor: colors.transparent,
          },
          "& .MuiDataGrid-row .in_percent.in_percent--active": {
            backgroundColor: colors.gridGroupHeadingColor,
          },
          "& .MuiDataGrid-row .in_percent.in_percent--false": {
            backgroundColor: colors.transparent,
          },
        }}
      >
        <DataGrid
          columns={timeSlices}
          rows={kpiData}
          hideFooter={true}
          slots={{
            toolbar: CustomToolbar,
          }}
          slotProps={{
            toolbar: {
              toggleMode: () => setIsMode2((prev) => !prev),
              isMode2,
            },
          }}
          density="compact"
          columnGroupingModel={overviewColumnGroups}
          experimentalFeatures={{ columnGrouping: true }}
          getRowHeight={() => "auto"}
          getRowClassName={(params) => {
            if (params.row.subHeading && !params.row.kpiSubHeader) {
              return "super-app--sub__analytics-headers";
            }
            if (params.row.subHeading && params.row.kpiSubHeader) {
              return "super-app--sub__analytics-kpi-headers";
            }
          }}
          sx={gridStyleForAU}
          disableRowSelectionOnClick
        />
      </Box>
    </Box>
  );
};

export default KpiComponent;
