const commonNonScorePositionProps = { fontWeight: "normal", level: 0 };

export const AllNonScorePositions = [
  {  key: "revenues", ...commonNonScorePositionProps },
  {
    key: "changes_in_un_finished_goods",
    ...commonNonScorePositionProps,
  },
  {
    key: "material_input",
    ...commonNonScorePositionProps,
  },
  {
    key: "total_operating_revenues",
    fontWeight: "bold",
    level: 1,
  },
  {
    key: "depreciation_amortization",
    ...commonNonScorePositionProps,
  },
  {
    key: "extraordinary_income_expenses",
    ...commonNonScorePositionProps,
  },
  {
    key: "reported_operating_profit_ebit",
    ...commonNonScorePositionProps,
  },
  {
    key: "reported_net_income",
    ...commonNonScorePositionProps,
  },
  {
    key: "adjusted_operating_profit",
    fontWeight: "bold",
    level: 1,
  },
  {
    key: "interest_expense",
    ...commonNonScorePositionProps,
  },
  {
    key: "interest_expense_creditshelf_loan",
    ...commonNonScorePositionProps,
  },
  {
    key: "leasing_expense",
    ...commonNonScorePositionProps,
  },
  { key: "tax_expense", ...commonNonScorePositionProps },
  {
    key: "funds_from_operations",
    fontWeight: "bold",
    level: 1,
  },
  {
    key: "total_assets",
    ...commonNonScorePositionProps,
  },
  {
    key: "intangible_assets",
    ...commonNonScorePositionProps,
  },
  {
    key: "intragroup_shareholder_receivables",
    ...commonNonScorePositionProps,
  },
  {
    key: "equity_like_instruments",
    ...commonNonScorePositionProps,
  },
  {
    key: "adjusted_total_assets",
    fontWeight: "bold",
    level: 1,
  },
  {
    key: "total_equity",
    ...commonNonScorePositionProps,
  },
  {
    key: "adjusted_total_equity",
    fontWeight: "bold",
    level: 1,
  },
  {
    key: "long_term_financial_debt",
    ...commonNonScorePositionProps,
  },
  {
    key: "short_term_financial_debt",
    ...commonNonScorePositionProps,
  },
  {
    key: "adjusted_cash_and_equivalents",
    ...commonNonScorePositionProps,
  },
  {
    key: "accounts_payable_and_other_short_term_obligations",
    ...commonNonScorePositionProps,
  },
  {
    key: "from_trade_payables",
    ...commonNonScorePositionProps,
  },
  {
    key: "accounts_receivable_and_other_short_term_assets",
    ...commonNonScorePositionProps,
  },
  {
    key: "from_inventory",
    ...commonNonScorePositionProps,
  },
  {
    key: "from_sales_receivables",
    ...commonNonScorePositionProps,
  },
];