import { DataGrid } from "@mui/x-data-grid";
import React from "react";
import { useMutation } from "./ForecastPositionDataGridHooks";
import { useQueryClient } from "@tanstack/react-query";
import { toast } from "sonner";
import { Box } from "@mui/system";
import PropTypes from "prop-types";
import LinearProgress from "@mui/material/LinearProgress";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

// This is the Forecast Grid Component
const ForecastPositionsDataGrid = ({
  forecastScenarioId,
  columns,
  rows,
  isLoading,
  isError,
  gridFormatterClient,
}) => {
  const [updateLoading, setUpdateLoading] = React.useState(false);
  const matches = useMediaQuery("(max-width:1500px)");
  const queryClient = useQueryClient();
  const mutateRow = useMutation();
  const theme = useTheme();

  // This function is called when a row is updated
  const processRowUpdate = React.useCallback(
    async (updatedRow, originalRow) => {
      setUpdateLoading(true);
      // Make the HTTP request to save in the backend
      const response = await mutateRow(updatedRow, originalRow).finally(() => {
        setUpdateLoading(false);
      });
      queryClient.invalidateQueries({
        queryKey: ["forecastScenarioPositions", forecastScenarioId],
      });
      queryClient.invalidateQueries({
        queryKey: ["forecastScenarioLiquidity", forecastScenarioId],
      });
      return response;
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [mutateRow]
  );
  // This function is called when there is an error in updating the row
  const handleProcessRowUpdateError = React.useCallback((error) => {
    toast.error("Error in processRowUpdate:");
  }, []);
  // Error Notify in UI
  if(isError){
    toast.error("Error in fetching data!");
  }
  return (
    // This is the Primary Grid Container
    <Box
      sx={{
        ...gridFormatterClient.getDataGridPrimaryContainerStyles(
          matches,
          theme,
          rows.length
        ),
        // Custom styles for the delta and percentage columns
        "& .forcast-grid-delta-perc": {
          backgroundColor: "#f5f5f5",
        },
        "& .forcast-grid-delta-perc.base": {
          backgroundColor: "#fff",
        },
      }}
    >
      {/* Linear animation to show loading */}
      {isLoading || updateLoading ? (
        <LinearProgress color="success" />
      ) : (
        <div />
      )}
      {/* Main grid component at lowest level */}
      <DataGrid
        columns={columns}
        rows={rows}
        isCellEditable={gridFormatterClient?.getIsCellEditable || false}
        processRowUpdate={(updatedRow, originalRow) =>
          processRowUpdate(updatedRow, originalRow)
        }
        slots={{
          toolbar: gridFormatterClient?.getGridCustomToolbar || null,
        }}
        hideFooter={true}
        density="compact"
        onProcessRowUpdateError={handleProcessRowUpdateError}
        getRowHeight={() => "auto"}
        sx={gridFormatterClient?.getDataGridPrimaryStyles || {}}
        getRowClassName={(params) => {
          if (
            (params.row.subHeading && !params.id.includes("gap")) ||
            params.row.id === "liq_cash_ffo"
          ) {
            return "forecast-grid__sub-heading";
          }
        }}
      />
    </Box>
  );
};

export default ForecastPositionsDataGrid;

ForecastPositionsDataGrid.propTypes = {
  forecastScenarioId: PropTypes.string.isRequired,
  columns: PropTypes.array.isRequired,
  rows: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isError: PropTypes.bool,
  gridFormatterClient: PropTypes.shape({
    getIsCellEditable: PropTypes.func,
    getDataGridPrimaryStyles: PropTypes.func,
    getDataGridPrimaryContainerStyles: PropTypes.func,
    getGridCustomToolbar: PropTypes.func,
  }),
};
