import React, { useState } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button } from '@mui/material';
import SelectFiles from './SelectFiles';
import PropTypes from "prop-types"; // Your SelectFiles component

const FileSelectionDialog = ({   onSelection,
                                 selectMultiple = true,
                                 queryType = null, }) => {
    const [open, setOpen] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setSelectedFile(null);
    };

    const handleConfirm = () => {
        onSelection(selectedFile);
        handleClose();
    };

    const handleFileSelection = (file) => {
        setSelectedFile(file);
    };

    return (
        <div>
            <Button color="primary" variant="outlined" onClick={handleOpen}>
                Select File
            </Button>
            <Dialog open={open} onClose={handleClose} maxWidth="lg" fullWidth>
                <DialogTitle>Select a File</DialogTitle>
                <DialogContent>
                    <SelectFiles onSelection={handleFileSelection} selectMultiple={selectMultiple} queryType={queryType} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={handleConfirm} disabled={!selectedFile}             variant="contained"
                            color="primary">
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default FileSelectionDialog;

FileSelectionDialog.propTypes = {
    onSelection: PropTypes.func.isRequired,
    selectMultiple: PropTypes.bool,
    queryType: PropTypes.string,
}
