import React, { useState, useEffect } from "react";
import TimeSliceGroupDialogForm from "./TimeSliceGroupDialogForm";
import * as api from "../../api";

export const DialogManager = ({ open, onClose, handleCreate, companyId }) => {
    const [rowSelection, setRowSelection] = useState({});
    const [timeSliceList, setTimeSliceList] = useState([]);
    const [errorMessage, setErrorMessage] = useState("");

    const handleClickOpen = () => {
        // Fetch the time slices when the button is clicked
        const fetchTimeSlices = async () => {
            try {
                const response = await api.fetchTimeSliceGroups(companyId);
                setTimeSliceList(response.data);
            } catch (error) {
                console.error(error);
                setErrorMessage(error?.response?.data?.detail); // Handle fetch errors
            }
        };

        fetchTimeSlices();
    };

    useEffect(() => {
        if (open) {
            handleClickOpen();
        }
    }, [open]);

    const handleSelection = (newValue) => {
        setRowSelection(newValue);
    };

    const timeSliceColumns = [
        {
            accessorKey: "id",
            header: "id",
            size: 50,
        },
        {
            accessorKey: "name",
            header: "Years",
            size: 50,
        },
    ];

    return (
        <TimeSliceGroupDialogForm
            open={open}
            onClose={onClose}
            columns={timeSliceColumns}
            data={timeSliceList}
            rowSelection={rowSelection}
            onRowSelectionChange={handleSelection}
            errorMessage={errorMessage}
            onCreate={() => handleCreate(rowSelection, timeSliceList)} // Pass rowSelection to the parent's handleCreate
        />
    );
};
