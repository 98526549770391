import { Box, IconButton, Tooltip } from "@mui/material";
import "react-quill/dist/quill.snow.css";
import "react-quill/dist/quill.bubble.css";
import { useState } from "react";
import RemoveRedEyeRoundedIcon from "@mui/icons-material/RemoveRedEyeRounded";
import VisibilityOffRoundedIcon from "@mui/icons-material/VisibilityOffRounded";
import QuillWithResize from "./QuillWithResize";

export const DemoMessageComponent = ({ keyData, keyName, design }) => {
  const [value, setValue] = useState(keyData?.content || "");
  const [hidden, setHidden] = useState(keyData?.hidden);

  let toolbar = [];

  const getDescription = () => {
    if (keyData.description === null || keyData.description === "") {
      return "";
    }
    return keyData.description.split("/n").map((part) => {
      return <Box>{part}</Box>;
    });
  };

  const updateDataPoint = () => {};

  const updateDataPointVisibility = () => {};

  const renderButtonBar = () => {
    return (
      <Box sx={{ position: "absolute", top: "0px", right: "2px" }}>
        <IconButton
          sx={{
            zIndex: "1",
          }}
          onClick={() => {
            updateDataPointVisibility();
            setHidden((prev) => !prev);
          }}
          data-testid={keyName + "-hide-button"}
        >
          {!hidden ? (
            <RemoveRedEyeRoundedIcon
              color="inherit"
              sx={{ color: "rgba(0,0,0,.15)" }}
            />
          ) : (
            <VisibilityOffRoundedIcon
              color="inherit"
              sx={{ color: "rgba(0,0,0,.15)" }}
            />
          )}
        </IconButton>
      </Box>
    );
  };

  if (!keyData) {
    return <Box>No Key Data</Box>;
  }

  if (hidden) {
    return (
      <Box
        sx={{
          paddingBottom: "10px",
          padding: "3px",
          width: "100%",
          position: "relative",
          height: "40px",
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <Box
          sx={{
            marginRight: "40px",
            color: "#C9C9C9",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
          data-testid={keyName + "-Title"}
        >
          {keyData.name}
        </Box>
        {renderButtonBar()}
      </Box>
    );
  }

  if (keyData.type === "SWOT-field") {
    toolbar = [
      ["bold", "italic", "underline"],
      [{ color: [] }],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link", "image"],
    ];
    return (
      <Box sx={{ width: "100%" }}>
        <Box sx={{ width: "100%", textAlign: "center", fontWeight: "bold" }}>
          {keyData.name}
        </Box>
        <Box sx={{ width: "100%" }}>
          <Box data-testid={keyName}>
            <QuillWithResize
              placeholder={""}
              setValue={setValue}
              updateDataPoint={updateDataPoint}
              toolbar={toolbar}
              theme={design}
              value={value}
            />
          </Box>
        </Box>
      </Box>
    );
  }

  if (keyData.type === "noname-message" || keyData.type === "image-message") {
    if (keyData.type === "noname-message") {
      toolbar = [
        ["bold", "italic", "underline"],
        [{ color: [] }],
        ["link", "image"],
      ];
    } else if (keyData.type === "image-message") {
      toolbar = [["image"], ["clean"]];
    }
    return (
      <Box
        sx={{
          paddingBottom: "18px",
          padding: "3px",
          width: "100%",
          position: "relative",
        }}
      >
        {renderButtonBar()}
        <Box sx={{ width: "100%" }}>
          <Box data-testid={keyName}>
            <QuillWithResize
              placeholder={
                keyData.type === "image-message"
                  ? "Add images and screenshots here..."
                  : ""
              }
              setValue={setValue}
              updateDataPoint={updateDataPoint}
              toolbar={toolbar}
              theme={design}
              value={value}
            />
          </Box>
        </Box>
      </Box>
    );
  }

  if (keyData.type === "message" || keyData.type === "conditional-message") {
    toolbar = [
      ["bold", "italic", "underline"],
      [{ color: [] }],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link", "image"],
    ];
    return (
      <Box
        sx={{
          paddingBottom: "18px",
          display: "flex",
          gap: "1rem",
          padding: "3px",
          position: "relative",
        }}
      >
        {renderButtonBar()}
        <Box
          sx={{
            minWidth: "250px",
            maxWidth: "250px",
            textAlign: "right",
            fontWeight: "bold",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            color: keyData.type === "message" ? "black" : "gray",
            position: "relative",
            lineHeight: "1.1",
            fontSize: ".94rem",
          }}
        >
          <Box
            sx={{
              position: "absolute",
              width: "5px",
              height: "5px",
              borderRadius: "50%",
              backgroundColor:
                keyData.description !== null && keyData.description !== ""
                  ? "#009688"
                  : "",
              top: "50%",
              right: "-7px",
              transform: "translateY(-50%)",
            }}
          />
          <Tooltip placement="right" arrow title={getDescription()}>
            <Box>{keyData.name}</Box>
          </Tooltip>
        </Box>

        <Box sx={{ flexGrow: "1" }}>
          <Box data-testid={keyName}>
            <QuillWithResize
              placeholder={""}
              setValue={setValue}
              updateDataPoint={updateDataPoint}
              toolbar={toolbar}
              theme={design}
              value={value}
            />
          </Box>
        </Box>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        paddingBottom: "18px",
        display: "flex",
        gap: "1rem",
        padding: "3px",
      }}
    >
      TYPE NOT VALID
    </Box>
  );
};
