import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Layout } from "../components/Layout/Layout";
import RatingBadgeComponent from "../components/rating/RatingBadgeComponent";
import SelectTimeSliceGroupDialog from "../components/TimeSliceGroupDialog/SelectTimeSliceGroupDialog";

import * as api from "../api";

import ExportExcelButton from "../components/ExportExcelButton";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";
import { Box } from "@mui/material";
import FlagsWrapper from "../components/Flags/FlagsWrapper";
import KPIsWrapper from "../components/KPIs/KPIsWrapper";
import CrefoComponent from "../components/CrefoComponent/CrefoComponent";
import IndustryComponent from "../components/IndustryComponent/IndustryComponent";
import { toast } from "sonner";
import { searchCompanies, fetchTimeSliceGroups } from "../api";

const FinancialView = () => {
  const [groupId, setGroupId] = useState(null);
  const [borrowerGroupId, setBorrowerGroupId] = useState(null);
  const [companyId, setCompanyId] = useState(null);
  let location = useLocation();
  const [refreshData, setRefreshData] = useState(false);
  const navigate = useNavigate();
  const getTimeSliceGroupId = () => {
    const params = new URLSearchParams(window.location.search);
    setGroupId(params.get("time_slice_group_id"));

    const borrowerGroupIdParam = params.get("borrower_group_id");
    if (borrowerGroupIdParam !== "null" && borrowerGroupIdParam !== null) {
      const parsedBorrowerGroupId = parseInt(borrowerGroupIdParam);
      if (Number.isInteger(parsedBorrowerGroupId)) {
        setBorrowerGroupId(parsedBorrowerGroupId);
      }
    }
  };

  const [companyDetails, setCompanyDetails] = useState(null);

  useEffect(() => {
    // Extract companyId from the pathname
    const match = /\/company\/(\d+)/.exec(location.pathname);

    if (match && match[1]) {
      const extractedCompanyId = parseInt(match[1], 10);
      setCompanyId(extractedCompanyId);
    }

    getTimeSliceGroupId();
    setRefreshData((prevState) => !prevState);
  }, [location]);

  useEffect(() => {
    const fetchLatestGroupId = async () => {
      if (groupId !== null || companyId === null) return;

      try {
        const response = await fetchTimeSliceGroups(companyId);
        const latestGroupId = response.data[response.data.length - 1].id; // Adjust based on your API response structure
        navigate({
          ...location,
          search: `time_slice_group_id=${latestGroupId}`,
        });
      } catch (error) {
        console.error("Error fetching the latest group id:", error);
        if (error.response && error.response.status === 404) {
          toast.error(
            `Company ${companyId} does not have any Time Slice Group yet.`
          );
        }
      }
    };

    fetchLatestGroupId();
  }, [companyId, groupId, location, navigate]);

  useEffect(() => {
    const fetchCompanyDetails = async () => {
      try {
        const response = await searchCompanies(companyId);
        const { companies } = response.data;
        const companyDetails =
          companies && companies.length > 0 ? companies[0] : null;
        setCompanyDetails(companyDetails);
      } catch (error) {
        console.error("An error occurred while fetching company:", error);
        setCompanyDetails(null);
      }
    };

    if (companyId) {
      fetchCompanyDetails();
    }
  }, [companyId]);

  const getCompanyName = (companyDetails) => {
    return companyDetails?.name || "No company name found";
  };

  return (
    <Layout>
      <Stack
        direction="row"
        divider={<Divider orientation="vertical" flexItem />}
        spacing={5}
        sx={{ marginLeft: "2rem" }}
      >
        <Box>
          <SelectTimeSliceGroupDialog></SelectTimeSliceGroupDialog>
        </Box>
        <Box>
          <ExportExcelButton
            groupId={groupId || ""}
            borrowerGroupId={borrowerGroupId || ""}
            companyName={getCompanyName(companyDetails)}
          ></ExportExcelButton>
        </Box>
      </Stack>
      <RatingBadgeComponent
        groupId={groupId}
        refreshData={refreshData}
        borrowerGroupId={borrowerGroupId}
      />
      {/* CREFO COMPONENT */}
      <CrefoComponent refreshData={refreshData} />
      {/* INDUSTRY COMPONENT */}
      <IndustryComponent refreshData={refreshData} />
      {/* FLAG COMPONENT */}
      <FlagsWrapper
        groupId={groupId}
        refreshData={refreshData}
        borrowerGroupId={borrowerGroupId}
      />
      {/* KPI COMPONENT */}
      <KPIsWrapper
        groupId={groupId}
        refreshData={refreshData}
        borrowerGroupId={borrowerGroupId}
      />
    </Layout>
  );
};

export default FinancialView;
