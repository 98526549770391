import React from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import { Toaster } from "sonner";
import FinancialView from "./pages/FinancialView";
import FinancialInput from "./pages/FinancialInput";
import RatingDetails from "./pages/RatingDetails";
import DDCallPage from "./pages/DDCallPage";
import { MsalAuthenticationTemplate, MsalProvider } from "@azure/msal-react";
import { CustomNavigationClient } from "./utils/NavigationClient";
import { InteractionType } from "@azure/msal-browser";
import { loginRequest } from "./authConfig";
import Home from "./pages/Home";
import FinalOpinion from "./pages/FinalOpinion";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import Forecast from "./pages/Forecast";
import { AppBar } from "@mui/material";
import NavBar from "./components/Layout/NavBar";
import ForecastDetailView from "./components/Forecast/ForecastDetailView";
import { StateContext } from "./context/StateContext";

const queryClient = new QueryClient();

function App({ pca }) {
  // The next 3 lines are optional. This is how you configure MSAL to take advantage of the router's navigate functions when MSAL redirects between pages in your app
  const navigate = useNavigate();
  const [ddid, setddid] = React.useState(null);
  const navigationClient = new CustomNavigationClient(navigate);
  pca.setNavigationClient(navigationClient);
  const authRequest = {
    ...loginRequest,
  };

  return (
    <StateContext.Provider value={{ ddid, setddid }}>
      <QueryClientProvider client={queryClient}>
        <MsalProvider instance={pca}>
          <MsalAuthenticationTemplate
            interactionType={InteractionType.Popup}
            authenticationRequest={authRequest}
          >
            <AppBar position="relative">
              <NavBar />
            </AppBar>
            <Pages />
            <Toaster position="bottom-right" richColors />
          </MsalAuthenticationTemplate>
        </MsalProvider>
      </QueryClientProvider>
    </StateContext.Provider>
  );
}

function Pages() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/company/:companyId">
        <Route path="view" element={<FinancialView />} />
        <Route path="input" element={<FinancialInput />} />
        <Route path="rating" element={<RatingDetails />} />
        <Route path="dd-call" element={<DDCallPage />} />
        <Route path="final-opinion" element={<FinalOpinion />} />
        <Route path="forecast" element={<Forecast />} />
        <Route path="forecast-scenario/:forecastScenarioId">
          <Route path="input" element={<ForecastDetailView />} />
        </Route>
      </Route>
    </Routes>
  );
}

export default App;
