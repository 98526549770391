/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import useMediaQuery from "@mui/material/useMediaQuery";
import * as api from "../../api";
import { useParams } from "react-router-dom";
import {
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import {
  getFlagColumns,
  countFlagPropertyValues,
  commonGridStyle,
} from "./FlagComponentUtils";
import { riskStudioColors as colors } from "../../styles/colors";
import FlagValuesCountComponent from "./FlagValuesCountComponent";
import { updateFlags } from "../../api";
import { toast } from "sonner";
import FullyFeaturedCrudGrid from "../../utils/FullFeaturedCrudGrid/FullFeaturedCrudGrid";

// CUSTOM TOOLBAR COMPONENT ===========>
function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarDensitySelector />
      <GridToolbarFilterButton />
    </GridToolbarContainer>
  );
}
// MAIN COMPONENT ===========>
const FlagComponent = ({ groupId, refreshData, borrowerGroupId }) => {
  // STATE VARIABLES ===========>
  // filterModel is used to filter the data based on the value of showRelevantOnly
  const [filterModel, setFilterModel] = useState({
    items: [
      {
        field: "value",
        operator: "isAnyOf",
        value: ["1", "2", "3"],
      },
    ],
  });
  // showRelevantOnly is true by default, it is used to filter only for relevant flags using the filterModel
  const [showRelevantOnly, setShowRelevantOnly] = useState(true);
  // filteredFlagsCount is used to store the count of relevant flags
  const [filteredFlagsCount, setFilteredFlagsCount] = useState({});
  // cachedSelectedValue is used to store the selected value of the flag for different rows
  const [cachedSelectedValue, setCachedSelectedValue] = useState({});
  // cachedComment is used to store the comment of the flag for different rows
  const [cachedComment, setCachedComment] = useState({});
  // data is used to store the flags data - Use in the grid as rows
  const [data, setData] = useState([]);
  // Get the company id from the URL
  const { companyId } = useParams();
  // Check if the screen size is less than 1500px
  const matches = useMediaQuery("(max-width:1500px)");

  // SIDE EFFECTS ===========>

  // SIDE EFFECTS:: Fetch the flags data from the company and group id
  useEffect(() => {
    const fetchData = async (groupId) => {
      try {
        const [result1, result2] = await Promise.all([
          api.fetchFlagsFromCompany(companyId, groupId, 1, borrowerGroupId),
          api.fetchFlagsFromCompany(companyId, groupId, 2, borrowerGroupId),
        ]);
        const combinedFlags = [...result1.data.flags, ...result2.data.flags];
        setData(combinedFlags);
        return combinedFlags;
      } catch (err) {
        console.error(err);
      }
    };
    fetchData(groupId)
      .then((res) => {
        if (res.length > 0) {
          changeFlagCountHandler(filterModel, res);
        }
      })
      .catch((err) => console.error(err));
  }, [groupId, refreshData]);

  // SIDE EFFECTS:: Update the filteredFlagsCount when the showRelevantOnly is changed
  useEffect(() => {
    if (showRelevantOnly) {
      changeFlagCountHandler(filterModel, data);
    }
    if (!showRelevantOnly) {
      setFilteredFlagsCount(countFlagPropertyValues(data));
    }
  }, [showRelevantOnly]);

  // HANDLER FUNCTIONS ===========>
  /**
   * This function is used to update the flag value and comment in the database
   * @param {String} _ is updated row id but we don't need it
   * @param {Array} updatedRow is the updated row data
   */
  const onUpdateRow = async (_, updatedRow) => {
    try {
      const updatedFlag = await updateFlags(companyId, updatedRow.id, {
        value: cachedSelectedValue[updatedRow.id],
        comment: cachedComment[updatedRow.id],
      });
      // Check if the update was successful
      if (updatedFlag.status === 200) {
        // Update the data only if the response was successful
        const newData = updateValueById(
          data,
          updatedFlag?.data?.id,
          updatedFlag?.data?.value,
          updatedFlag?.data?.comment
        );
        setData(newData);
        toast.success("Flag updated successfully!");
      }
    } catch (error) {
      // Handle any errors that occur during the request
      console.error("An error occurred while updating the flag:", error);
      toast.error("Failed to update the flag!");
    }
  };
  /**
   * This function is used to delete the flag in the database
   * @param {String} _ is updated row id but we don't need it
   * @param {Array} updatedRow is the updated row data
   */
  const onDeleteRow = async (_, updatedRow) => {
    toast.warning("Can't be deleted in database! Not yet implemented!");
  };

  /**
   * This function is used to update the value of the flag in the list of flags
   * @param {Array} list - This is the list of flags previous one
   * @param {String} id - This is the id of the flag to be updated
   * @param {String} newValue - This is the new value of the flag
   * @param {String} newComment - This is the new comment of the flag
   * @returns {Array} - This returns the updated list of flags
   */
  const updateValueById = (list, id, newValue, newComment) => {
    return list.map((item) => {
      if (item.id === id) {
        // If original_value is null, set it to the current value
        if (item.original_value === null || item.original_value === undefined) {
          item.original_value = item.value;
        }
        // Update the value
        return { ...item, value: newValue, comment: newComment };
      }
      return item;
    });
  };

  // HANDLER FUNCTIONS FOR FILTERING FLAGS ===========>

  // Toggle the state of showRelevantOnly and update the filterModel
  const toggleFilter = () => {
    setShowRelevantOnly(!showRelevantOnly);

    if (showRelevantOnly) {
      setFilterModel({ items: [] }); // Remove filter
    } else {
      setFilterModel({
        items: [
          {
            field: "value",
            operator: "isAnyOf",
            value: ["1", "2", "3"],
          },
        ],
      });
    }
  };
  // New function to update the filteredFlagsCount when the filterModel is changed
  const changeFlagCountHandler = (model, reqData) => {
    const relevants = reqData.filter((data) =>
      model.items[0].value.includes(String(data.value))
    );
    setFilteredFlagsCount(countFlagPropertyValues(relevants));
  };

  // New function to update filter model when changed manually
  const handleFilterChange = (newFilterModel) => {
    setFilterModel(newFilterModel);
    if (newFilterModel.items[0].value.length > 0) {
      changeFlagCountHandler(newFilterModel, data);
    } else {
      setFilteredFlagsCount(countFlagPropertyValues(data));
    }
  };

  // RETURN ===========>
  return (
    <Box
      sx={{
        "& .MuiDataGrid-columnHeaders": {
          border: `2px solid #b0bec5`,
          borderRadius: "0px",
        },
        "& .MuiDataGrid-cell.MuiDataGrid-cell--editing:focus": {
          outline: `solid ${colors.gridBorderColor} 1px !important`,
        },
        "& .MuiDataGrid-cell.MuiDataGrid-cell--editing:focus-within": {
          outline: `solid ${colors.gridBorderColor} 1px !important`,
        },
      }}
    >
      <Box
        sx={{
          borderLeft: `1px solid ${colors.gridBorderColor}`,
          borderRight: `1px solid ${colors.gridBorderColor}`,
          paddingLeft: "1rem",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <FormControlLabel
          control={
            <Switch checked={showRelevantOnly} onChange={toggleFilter} />
          }
          label="Show Relevant only"
        />
        <FlagValuesCountComponent flagsCount={filteredFlagsCount} />
      </Box>
      {/* MAIN GRID */}
      <FullyFeaturedCrudGrid
        filterModel={filterModel}
        onFilterModelChange={(params) => handleFilterChange(params)}
        slots={{
          toolbar: CustomToolbar,
        }}
        rows={data}
        columns={getFlagColumns({
          matches,
          setCachedSelectedValue,
          setCachedComment,
        })}
        initialState={{
          sorting: {
            sortModel: [{ field: "value", sort: "desc" }],
          },
        }}
        density="compact"
        // disableRowSelectionOnClick
        getRowHeight={() => "auto"}
        sx={commonGridStyle}
        onUpdateRow={onUpdateRow}
        onDeleteRow={onDeleteRow}
        renderBoldActionHeader
        color={'teal'}
        disableDeleteAction
      />
    </Box>
  );
};

export default FlagComponent;
