import React, { useCallback, useState } from "react";
import TextField from "@mui/material/TextField";
import { Box } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import DriveFileRenameOutlineOutlinedIcon from "@mui/icons-material/DriveFileRenameOutlineOutlined";

function GroupNameTextField({ groupName, setGroupName }) {
  const [error, setError] = useState("");

  const handleTextChange = useCallback(
    (event) => {
      const input = event.target.value;

      // Check if the input contains symbols other than , . - /
      const hasWeirdSymbols = /[^\w\s,./-]/.test(input);

      if (input.length < 3) {
        setError("Group name should be at least 3 characters.");
      } else if (hasWeirdSymbols) {
        setError("Group name should not contain symbols except (, . - /)");
      } else {
        setError("");
      }

      setGroupName(input);
    },
    [setGroupName]
  );

  return (
    <Box sx={{ width: "100%", textAlign: "center" }}>
      <TextField
        id="filled-helperText"
        // label="Set Group Name"
        value={groupName}
        onChange={handleTextChange}
        // variant="filled"
        size="small"
        error={!!error}
        helperText={error}
        placeholder="Type your selection group name here..."
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <DriveFileRenameOutlineOutlinedIcon />
            </InputAdornment>
          ),
        }}
        sx={{
          width: "88%",
        }}
      />
    </Box>
  );
}

export default GroupNameTextField;
