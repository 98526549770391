import React from "react";
import PropTypes from "prop-types";
import { Box, TextField, IconButton, Typography } from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import { postCommentForPosition, updateCommentForPosition } from "../../api";

const CommentTextField = ({
  selectedValue,
  allComments,
  sliceId,
  setPayloadForUpdateRow,
  refetchComments,
  setRefetchComments,
}) => {
  const [loadingCreateComment, setLoadingCreateComment] = React.useState(false);
  const [value, setValue] = React.useState("");
  const [comments, setComments] = React.useState(allComments);

  // Handle the input change in comment text field
  const handleInputChange = (e) => {
    // Get the new comment from the event object
    const newComment = e.target.value;

    // Update the allComments state
    setComments((prevComments) => {
      // Copy the previous comments
      const updatedComments = { ...prevComments };

      // Update the comment for the current sliceId and key
      if (!updatedComments[sliceId]) {
        updatedComments[sliceId] = {};
      }
      if (!updatedComments[sliceId][selectedValue.key]) {
        updatedComments[sliceId][selectedValue.key] = {};
      }
      updatedComments[sliceId][selectedValue.key].comment_content = newComment;

      // Return the updated comments
      return updatedComments;
    });
    // Update the value state
    setValue(newComment);
  };

  // Handle the save button click
  const handleSave = (sliceId) => {
    setLoadingCreateComment(true);
    const payloadForCreate = {
      position_id: selectedValue.key,
      comment: value,
    };
    const payloadForUpdate = {
      comment: value,
    };
    const commentId = comments[sliceId]?.[selectedValue.key]?.comment_id;
    if (commentId) {
      // Update the comment
      updateCommentForPosition(commentId, payloadForUpdate)
        .then((_) => {
          setLoadingCreateComment(false);
          setRefetchComments(!refetchComments);
        })
        .catch((error) => {
          console.error(error);
          setLoadingCreateComment(false);
        });
    } else {
      // Create a new comment
      postCommentForPosition(sliceId, payloadForCreate)
        .then((_) => {
          setLoadingCreateComment(false);
          setRefetchComments(!refetchComments);
        })
        .catch((error) => {
          console.error(error);
          setLoadingCreateComment(false);
        });
    }
    // Update the payload for update row
    setPayloadForUpdateRow((prevPayloadForUpdateRow) => {
      let newPayloadForUpdateRow;
      if (prevPayloadForUpdateRow.key === selectedValue.key) {
        newPayloadForUpdateRow = {
          ...prevPayloadForUpdateRow,
          [sliceId]: value,
        };
      } else {
        newPayloadForUpdateRow = {
          key: selectedValue.key,
          [sliceId]: value,
        };
      }
      return newPayloadForUpdateRow;
    });
    // Reset the value state
    setValue("");
  };
  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      {loadingCreateComment && (
        <Typography
          variant="h7"
          color="HighlightText"
          sx={{ textAlign: "center", marginBottom: "8px" }}
        >
          {" "}
          🔘 Creating comment...
        </Typography>
      )}
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <TextField
          id="comment-for-cell__data-grid--position"
          label={`value: ${selectedValue[sliceId]}`}
          multiline
          maxRows={10}
          placeholder="Add comment"
          sx={{ width: "400px" }}
          onChange={(e) => handleInputChange(e)}
          value={comments[sliceId]?.[selectedValue.key]?.comment_content || ""}
        />

        <IconButton
          onClick={() => handleSave(sliceId)}
          color="primary"
          size="small"
          sx={{ marginLeft: "1rem" }}
          disabled={loadingCreateComment}
        >
          <SaveIcon />
        </IconButton>
      </Box>
    </Box>
  );
};

// PROP TYPES
CommentTextField.propTypes = {
  selectedValue: PropTypes.shape({
    key: PropTypes.string,
  }).isRequired,
  allComments: PropTypes.object.isRequired,
  sliceId: PropTypes.string.isRequired,
  setPayloadForUpdateRow: PropTypes.func.isRequired,
  refetchComments: PropTypes.bool.isRequired,
  setRefetchComments: PropTypes.func.isRequired,
};

export default CommentTextField;
