// @baseProfileForSubHeading : This takes custom id , name, key as argument to create a object same as modelObject with id, key and name to the args but other properties to empty string, This function is used to create row in datagrid where all the values are empty string but position name is the name you providing
const baseProfileForSubHeading = (id, name, key, modelObj) => {
  const transformedObj = {
    id,
    name,
    key,
  };
  for (const prop of Object.keys(modelObj)) {
    if (prop !== "id" && prop !== "name" && prop !== "key") {
      if (prop === "subHeading") {
        transformedObj[prop] = true;
      } else if (prop === "fontWeight") {
        transformedObj[prop] = "bold";
      } else {
        transformedObj[prop] = "";
      }
    }
  }

  return transformedObj;
};

const businessProfilePosCombined = [
  "business_profile-110",
  "Business Profile",
  "business_profile_kpi_combined",
];
const financialProfilePosCombined = [
  "financial_profile-111",
  "Financial Profile",
  "financial_profile_kpi_combined",
];

const liquidityProfilePosCombined = [
  "liquidity_profile-112",
  "Liquidity Profile",
  "liquidity_profile_kpi_combined",
];


// @mergeRows : Takes the id of proformaScores (ignores scoreKPI ids) and merge the other properties of same index in both arrays
const mergeRows = (scoreKpi, proformaScores) =>
  scoreKpi.map((item, i) => ({
    ...item,
    ...proformaScores[i],
    subHeading: false,
    fontWeight: "normal",
  }));
  
// @getCombinedRow : Returns combined row for scoreKpi and proformaScores with profile subheadings in different index inside the row 
export const getCombinedRow = (scoreKpi, proformaScores) => {
  const combinedRow = mergeRows(scoreKpi, proformaScores);
  const modelObj = combinedRow[0];
  if (modelObj) {
    return [
      baseProfileForSubHeading(...businessProfilePosCombined, modelObj),
      ...combinedRow.slice(0, 5),
      baseProfileForSubHeading(...financialProfilePosCombined, modelObj),
      ...combinedRow.slice(5, 9),
      baseProfileForSubHeading(...liquidityProfilePosCombined, modelObj),
      ...combinedRow.slice(9),
    ];
  }
  return combinedRow;
};

