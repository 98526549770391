export function getCurrentYearMonth() {
    try {
      return new Date().toISOString().slice(0, 7).replace('-', '_');
    }
    catch (error) {
      console.error('Error occurred while getting current year and month:', error);
    return '0000_00';
  }
}

const calculateYears = (projectYear, projectDuration) => {
  const startYear = projectYear;

  // Calculate the ending year of the project by adding the number of years
  const endYear = projectYear + Math.ceil(projectDuration / 12);

  // Generate an array of years from the start year to the end year, inclusive.
  const years = Array.from({ length: endYear - startYear + 1 }, (_, index) => startYear + index);

  return years;
};

export { calculateYears };
