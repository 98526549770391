import React, {useState} from 'react';
import {
    Box,
    Button,
    Card,
    CardContent,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    List,
    ListItem,
    TextField,
    Typography
} from '@mui/material';
import {useMutation, useQueryClient} from '@tanstack/react-query';
import {postDdCall} from '../../api';
import ListItemText from "@mui/material/ListItemText";
import {useFetchTemplates} from "./hooks";
import PropTypes from "prop-types";


const AddDdCallDialog = ({ open, onClose, companyId, onSuccessUpdate }) => {
    const queryClient = useQueryClient()
    const [name, setName] = useState(null);
    const [description, setDescription] = useState(null);
    const [selectedTemplateId, setSelectedTemplateId] = useState(null);

    const {data: templates, isLoading: isLoadingTemplates} = useFetchTemplates();
    const mutation = useMutation({
        mutationFn: postDdCall,
        onSuccess: (data) => {
            onSuccessUpdate(data);
            onClose();
            queryClient.invalidateQueries({ queryKey: ['ddCalls', companyId] })
            handleReset();
        }
    });

    const handleConfirm = () => {
        mutation.mutate({
            data: {
                name,
                description,
                company_id: parseInt(companyId, 10), // Ensures companyId is an integer
                template_id: parseInt(selectedTemplateId, 10),
            }
        });
    };

    const handleReset = () => {
        setName(null);
        setDescription(null);
        setSelectedTemplateId(null);
    }

    return (
        <Dialog open={open} onClose={onClose}     sx={{
            '& .MuiDialog-paper': {
                width: '50%', // Set the width of the Dialog
                maxWidth: 'none' // Override the default max-width
            }
        }}>
            <DialogTitle>Neuer DD Call</DialogTitle>
            <DialogContent>
                <TextField
                    label="Name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    fullWidth
                    margin="normal"
                />
                <TextField
                    label="Beschreibung"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    fullWidth
                    margin="normal"
                />
                {!isLoadingTemplates && templates && (
                    <Box sx={{
                        display: 'flex',
                        overflowX: 'auto', // Enables horizontal scrolling
                        padding: 2,
                        width: '100%', // Ensures the box takes full width of its container
                        gap: 1, // Optional: adds space between cards
                    }}>
                        {templates.map((template) => (
                            <Card
                                key={template.id}
                                sx={{
                                    flex: '0 0 auto', // Ensures cards don't shrink and have a fixed size
                                    margin: 1,
                                    cursor: 'pointer',
                                    minWidth: '400px', // Set a minimum width for each card
                                    maxWidth: '600px', // Set a maximum width for each card
                                    ...(selectedTemplateId === template.id
                                        ? { borderColor: 'primary.main', borderWidth: 2, boxShadow: 3, bgcolor: 'action.selected' }
                                        : {})
                                }}
                                onClick={() => setSelectedTemplateId(template.id)}
                            >
                                <CardContent sx={{ maxHeight: '400px', overflowY: 'auto' }}>
                                    <Typography variant="h6">{template.name}</Typography>
                                    <List sx={{
                                        '& .MuiListItem-root': {
                                            paddingTop: '1px',
                                            paddingBottom: '1px',
                                            '&:before': {
                                                content: "'•'",
                                                marginRight: '8px',
                                                color: 'text.secondary',
                                            }
                                        }
                                    }}>
                                        {template.content.map((content, index) => (
                                            <ListItem key={content.id}>
                                                <ListItemText primary={content.content} />
                                            </ListItem>
                                        ))}
                                    </List>
                                </CardContent>
                            </Card>
                        ))}
                    </Box>
                )}

            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} variant="outlined" color="primary">Cancel</Button>
                <Button onClick={handleConfirm} color="primary" variant="contained" disabled={!name || name.trim() === ''} >Confirm</Button>
            </DialogActions>
        </Dialog>
    );
};

export default AddDdCallDialog;

AddDdCallDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    companyId: PropTypes.string.isRequired,
    onSuccessUpdate: PropTypes.func.isRequired,
}
