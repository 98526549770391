import React from "react";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import SelectDocumentsDialog from "./SelectDocumentsDialog";
import { GridColumnMenu } from "@mui/x-data-grid";
import { getTimeSliceDocuments, fetchDocumentWithFileType } from "../../api";
import PlagiarismIcon from "@mui/icons-material/Plagiarism";
import CircularProgress from "@mui/material/CircularProgress";

// This is the custom menu item for the input data grid
function CustomGetDocumentsItem(props) {
  const {
    onClickHandler,
    itemName,
    columnProps,
    setOpenDialog,
    setAlreadylinkedDocuments,
    setAllDocuments,
    loader,
    setLoader,
    companyId,
  } = props;
  return (
    <MenuItem
      onClick={() =>
        onClickHandler(
          columnProps.field,
          setOpenDialog,
          setAlreadylinkedDocuments,
          setAllDocuments,
          setLoader,
          companyId
        )
      }
    >
      <ListItemIcon>
        {loader ? (
          <CircularProgress size={"20px"} />
        ) : (
          <PlagiarismIcon fontSize="small" />
        )}
      </ListItemIcon>
      <ListItemText>{itemName}</ListItemText>
    </MenuItem>
  );
}
// This is the handler for the custom menu item
const getDocumentsHandler = async (
  timeSliceId,
  setOpenDialog,
  setAlreadylinkedDocuments,
  setAllDocuments,
  setLoader,
  companyId
) => {
  setLoader(true);
  Promise.all([
    await fetchDocumentWithFileType(companyId, 0),
    await getTimeSliceDocuments(timeSliceId),
  ])
    .then((res) => {
      const [allDoucments, linkedDocuments] = res;
      setAlreadylinkedDocuments(linkedDocuments.documents);
      setAllDocuments(allDoucments.documents);
    })
    .then(() => {
      setOpenDialog(true);
      setLoader(false);
    })
    .catch((err) => {
      console.error(err);
      setLoader(false);
    });
};

export function CustomColumnMenuForInputDataGrid(props) {
  const [loader, setLoader] = React.useState(false);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [alreadylinkedDocuments, setAlreadylinkedDocuments] = React.useState(
    []
  );
  const [allDocuments, setAllDocuments] = React.useState([]);
  const { companyId } = useParams();
  return (
    <>
      {/* Dialog will open after fetch */}
      <SelectDocumentsDialog
        openDialog={openDialog}
        setOpenDialog={setOpenDialog}
        alreadylinkedDocuments={alreadylinkedDocuments}
        timeSliceId={props.colDef.field}
        allDocuments={allDocuments}
      />
      {/* Grid Menu Item from MUI */}
      <GridColumnMenu
        {...props}
        hidden={props.colDef.field === "name"}
        slots={{
          columnMenuGetDocuments: CustomGetDocumentsItem,
        }}
        slotProps={{
          // Give the props of the column menu item to the custom component
          columnMenuGetDocuments: {
            displayOrder: 15,
            itemName: "Get documents",
            onClickHandler: getDocumentsHandler,
            columnProps: props.colDef,
            setOpenDialog: setOpenDialog,
            setAlreadylinkedDocuments: setAlreadylinkedDocuments,
            setAllDocuments: setAllDocuments,
            loader: loader,
            setLoader: setLoader,
            companyId: companyId,
          },
        }}
      />
    </>
  );
}

// Prop types for CustomGetDocumentsItem component
CustomGetDocumentsItem.propTypes = {
  onClickHandler: PropTypes.func.isRequired,
  itemName: PropTypes.string.isRequired,
  columnProps: PropTypes.object.isRequired,
  setOpenDialog: PropTypes.func.isRequired,
  setAlreadylinkedDocuments: PropTypes.func.isRequired,
  setAllDocuments: PropTypes.func.isRequired,
  loader: PropTypes.bool.isRequired,
  setLoader: PropTypes.func.isRequired,
  companyId: PropTypes.string.isRequired,
};

// Prop types for CustomColumnMenuForInputDataGrid component
CustomColumnMenuForInputDataGrid.propTypes = {
  colDef: PropTypes.object.isRequired,
};
