import { useCallback } from "react";
import { findDifferences, evaluateFormula } from "./UtilsForEditDataGrids";
import * as api from '../../api';

export const useMutation = () => {
    return useCallback(
      (updatedRow, originalRow, positionAttributes) =>
        new Promise((resolve, reject) => {
          const diff = findDifferences(updatedRow, originalRow);
          const positionId =
            positionAttributes.positionAttributes[updatedRow.id].id;
          const columnId = diff[0];
          const updatedValue = evaluateFormula(updatedRow[diff[0]]);
          // TODO: Add updated value to updatedRow to not show NaN for a blick of a second.
  
          api
            .putPosition(columnId, positionId, updatedValue)
            .then(() => {
              resolve({ ...updatedRow });
            })
            .catch((error) => {
              reject(error);
            });
        }),
      []
    );
  };
  