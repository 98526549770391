// translate to javascript and custom it by Blueberry 03/02/2023
import * as React from "react";
import { useEffect, useMemo } from "react";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import {
  DataGrid,
  GridActionsCellItem,
  GridRowModes,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarFilterButton,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid";
import AddIcon from "@mui/icons-material/Add";
import { Button } from "@mui/material";
import PropTypes from "prop-types";
import CustomNoRowsOverlay from "../../components/Utils/CustomNoRowsOverlay";
import DeleteAlertDialog from "./DeleteAlertDialog";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { de as locale } from "date-fns/locale";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

function DefaultToolbar(props) {
  const { rows, setRows, setRowModesModel, columns, createRowData, color } =
    props;

  const handleClick = () => {
    const newData = createRowData(rows);
    newData.isNew = true;
    if (!newData.hasOwnProperty("id"))
      newData.newId = Math.max(...rows.map((r) => r.id * 1)) + 1;
    setRows((oldRows) => {
      return [...oldRows, newData];
    });
    setRowModesModel((oldModel) => {
      const firstEditable = columns.find((c) => c.editable && !c.hide);
      return {
        ...oldModel,
        [newData.id]: {
          mode: GridRowModes.Edit,
          fieldToFocus: firstEditable.field,
        },
      };
    });
  };

  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton sx={{ color }} />
      <GridToolbarFilterButton sx={{ color }} />
      <GridToolbarDensitySelector sx={{ color }} />
      <Button
        data-testid="button-full-featured-crud-grid-add-record"
        startIcon={<AddIcon />}
        onClick={handleClick}
        color="primary"
        sx={{
          color,
        }}
      >
        Add record
      </Button>
      <GridToolbarQuickFilter sx={{ color }} />
    </GridToolbarContainer>
  );
}

DefaultToolbar.propTypes = {
  rows: PropTypes.array.isRequired,
  setRows: PropTypes.func.isRequired,
  setRowModesModel: PropTypes.func.isRequired,
  columns: PropTypes.array.isRequired,
  createRowData: PropTypes.func,
  color: PropTypes.string,
};

DefaultToolbar.defaultProps = {
  createRowData: (rows) => {
    const newId = Math.max(...rows.map((r) => r.id * 1)) + 1;
    return { id: newId };
  },
};

function FullFeaturedCrudGrid({
  columns,
  rows,
  defaultPageSize,
  onSaveRow,
  onUpdateRow,
  onDeleteRow,
  createRowData,
  onProcessRowUpdateError,
  color,
  renderBoldActionHeader,
  disableDeleteAction= false,
  ...props
}) {
  // States
  const [internalRows, setInternalRows] = React.useState(rows);
  const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
  const [wantToDeleteRowId, setWantToDeleteRowId] = React.useState(null);
  const memoizedRows = useMemo(() => internalRows, [internalRows]);
  const [rowModesModel, setRowModesModel] = React.useState({});
  // Effects on rows change
  useEffect(() => {
    setInternalRows(rows);
  }, [rows]);
  // Handlers
  const handleRowEditStart = (params, event) => {
    event.defaultMuiPrevented = true;
  };

  const handleRowEditStop = (params, event) => {
    event.defaultMuiPrevented = true;
  };

  const handleEditClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleDeleteClick = (id) => {
    // Return a function that will be called when the delete icon is clicked
    return () => {
      setOpenDeleteDialog(true);
      setWantToDeleteRowId(id);
    };
  };

  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = internalRows.find((row) => row.id === id);
    if (editedRow.isNew) {
      setInternalRows(internalRows.filter((row) => row.id !== id));
    }
  };
 
  const processRowUpdate = (newRow) => {
    const updatedRow = { ...newRow };
    if (!updatedRow.isNew) updatedRow.isNew = false;
    const oldRow = internalRows.find((r) => r.id === updatedRow.id);
    setInternalRows(
      internalRows.map((row) => (row.id === newRow.id ? updatedRow : row))
    );
    if (updatedRow?.isNew) {
      onSaveRow(updatedRow.id, updatedRow, oldRow, internalRows);
    } else {
      onUpdateRow(updatedRow.id, updatedRow, oldRow, internalRows);
    }
    return updatedRow;
  };
  // Custom toolbar
  const customToolBar = (props) => <DefaultToolbar {...props} color={color} />;
  // Append actions columns
  const appendedColumns = [
    ...columns,
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 100,
      cellClassName: "actions",
      renderHeader: (params) => {
        return renderBoldActionHeader ? (
          <strong style={{color : color || 'inherit'}}> {params.colDef.headerName}</strong>
        ) : (
          params.colDef.headerName
        );
      },
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              key={"save-" + id}
              icon={<SaveIcon />}
              label="Save"
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              key={"cancel-" + id}
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }

        return [
          <GridActionsCellItem
            key={"edit-" + id}
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            key={"delete-" + id}
            data-testid="button-full-featured-crud-grid-delete-record"
            icon={<DeleteIcon />}
            label="Delete"
            onClick={handleDeleteClick(id)}
            color="inherit"
            disabled={disableDeleteAction}
          />,
        ];
      },
    },
  ];

  // Pagination
  const [pageSize, setPageSize] = React.useState(defaultPageSize);

  return (
    <React.Fragment>
      <LocalizationProvider dateAdapter={AdapterDateFns} locale={locale}>
        <DataGrid
          rows={memoizedRows}
          columns={appendedColumns}
          editMode="row"
          rowModesModel={rowModesModel}
          onRowModesModelChange={(newModel) => setRowModesModel(newModel)}
          onRowEditStart={handleRowEditStart}
          onRowEditStop={handleRowEditStop}
          processRowUpdate={processRowUpdate}
          onProcessRowUpdateError={onProcessRowUpdateError}
          slots={{
            toolbar: customToolBar,
            noRowsOverlay: CustomNoRowsOverlay,
          }}
          slotProps={{
            toolbar: {
              rows: internalRows,
              setRows: setInternalRows,
              setRowModesModel,
              createRowData,
              columns,
            },
          }}
          experimentalFeatures={{ newEditingApi: true }}
          //pagination
          pageSize={pageSize}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          {...props}
        />
      </LocalizationProvider>
      <DeleteAlertDialog
        openDeleteDialog={openDeleteDialog}
        setOpenDeleteDialog={setOpenDeleteDialog}
        internalRows={internalRows}
        setInternalRows={setInternalRows}
        onDeleteRow={onDeleteRow}
        wantToDeleteRowId={wantToDeleteRowId}
        setWantToDeleteRowId={setWantToDeleteRowId}
        color={color}
      />
    </React.Fragment>
  );
}

// Default props
FullFeaturedCrudGrid.defaultProps = {
  //action
  onSaveRow: (id, updatedRow /*, oldRow, rows*/) => {
  },
  onDeleteRow: (id, oldRow /*, rows*/) => {
  },

  onProcessRowUpdateError: (error) => {
    console.error(error);
  },

  initialState: {
    columns: {
      columnVisibilityModel: {
        id: false,
      },
    },
  },
  autoHeight: true,

  //pagination
  pagination: true,
  defaultPageSize: 25,
  rowsPerPageOptions: [5, 10, 25, 50, 100],
};
// Normal Props
FullFeaturedCrudGrid.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
  rows: PropTypes.arrayOf(PropTypes.object).isRequired,

  //action
  onSaveRow: PropTypes.func,
  onDeleteRow: PropTypes.func,
  onUpdateRow: PropTypes.func,
  createRowData: PropTypes.func,
  onProcessRowUpdateError: PropTypes.func,

  //initialState
  initialState: PropTypes.object,
  autoHeight: PropTypes.bool,
  //pagination
  pagination: PropTypes.bool,
  defaultPageSize: PropTypes.number,
  rowsPerPageOptions: PropTypes.arrayOf(PropTypes.number),
  // Optional Color
  color: PropTypes.string,
  // Optional renderBoldActionHeader - render bold action header
  renderBoldActionHeader: PropTypes.bool,
  // Optional disableDeleteAction - disable delete action
  disableDeleteAction: PropTypes.bool,
};

export default React.memo(FullFeaturedCrudGrid);
