import { Box, Typography } from "@mui/material";
import { riskStudioColors as colors } from "../../styles/colors";
import FlagComponent from "./FlagComponent";
import useMediaQuery from "@mui/material/useMediaQuery";
function FlagsWrapper(props) {
  const matches = useMediaQuery("(max-width:1500px)");
  return (
    <Box sx={{ margin: "3rem auto", width: matches ? "90vw" : "80vw" }}>
      <Box
        sx={{
          backgroundColor: colors.gridWrapperPrimaryColor,
          borderRadius: "5px 5px 0px 0px",
          padding: "0.3rem 1rem",
          textTransform: "uppercase",
          border: "none",
        }}
      >
        <Typography variant="h6" color={colors.white} fontWeight="bold">
          FLAGS
        </Typography>
      </Box>
      <div>
        <FlagComponent
          groupId={props.groupId}
          refreshData={props.refreshData}
          borrowerGroupId={props.borrowerGroupId}
        />
      </div>
    </Box>
  );
}
export default FlagsWrapper;
