import {Layout} from "../components/Layout/Layout";
import ForecastComponent from "../components/Forecast/ForecastComponent";

const Forecast = () => {
    return (
        <Layout>
            <ForecastComponent></ForecastComponent>
        </Layout>
    )
}

export default Forecast