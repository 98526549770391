import * as React from "react";
import PropTypes from "prop-types";
import { Button } from "@mui/material";

export const ActionButton = ({ stepAction, isMenuItem }) => {
  return (
    <Button
      id="demo-customized-button"
      variant="outlined"
      onClick={stepAction.onClick}
      href={stepAction.href}
      tabIndex={-2}
      size="small"
      sx={{
        margin: "auto",
        ...(isMenuItem && {
          width: "100%",
          borderColor: "white",
        }),
        ...(!isMenuItem && { width: "120px" }),
      }}
    >
      {stepAction.text}
    </Button>
  );
};

ActionButton.defaultProps = {
  isMenuItem: false,
};

ActionButton.propTypes = {
  stepAction: PropTypes.shape({
    onClick: PropTypes.func.isRequired,
    href: PropTypes.string,
    text: PropTypes.string.isRequired,
  }).isRequired,
  isMenuItem: PropTypes.bool,
};