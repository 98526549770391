import PropTypes from "prop-types";
import { Box } from "@mui/system";
import React, { useState } from "react";
import { riskStudioColors as colors } from "../../styles/colors";
import { GetIcon } from "./FlagComponentUtils";
import { Tooltip, Typography } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

const FlagValuesCountComponent = ({ flagsCount }) => {
  const [visibility, setVisibility] = useState(true);
  const generateIconsCount = (data) => {
    return Object.keys(data).map((item, i) => {
      return (
        <Box
          key={`flag-icons__count-${i + 1}`}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "50px",
            border: `1px solid ${colors.gridBorderColor}`,
            borderRadius: "5px",
            fontWeight: "bold",
            marginRight: "5px",
          }}
        >
          {GetIcon(Number(item), true)}
          <Typography
            variant="h7"
            sx={{ fontSize: "0.95rem", color: "grey", marginLeft: "3px" }}
          >
            {flagsCount[item] || 0}
          </Typography>
        </Box>
      );
    });
  };
  return (
    <Box sx={{ display: "flex", paddingRight: "10px" }}>
      <Box  data-testid="flag-values-count"
        sx={{
          display: visibility ? "flex" : "none",
          justifyContent: "flex-end",
          paddingRight: "1rem",
          transition: "0.4s all ease",
        }}
      >
        {flagsCount.length > 0
          ? generateIconsCount(flagsCount)
          : generateIconsCount({ 0: 0, 1: 0, 2: 0, 3: 0, null: 0 })}
      </Box>

      {visibility ? (
        <Tooltip arrow enterDelay={500} title={"Hide Counts"}>
          <VisibilityOffIcon
            sx={{
              color: colors.darkGray,
              cursor: "pointer",
              transition: "0.3s all ease",
              "&:hover": { transform: "scale(1.1)" },
            }}
            onClick={() => setVisibility(false)}
          />
        </Tooltip>
      ) : (
        <Tooltip arrow enterDelay={500} title={"Show Counts"}>
          <VisibilityIcon
            sx={{
              color: colors.darkGray,
              cursor: "pointer",
              transition: "0.3s all ease",
              "&:hover": { transform: "scale(1.1)" },
            }}
            onClick={() => setVisibility(true)}
            title={"Show Counts"}
          />
        </Tooltip>
      )}
    </Box>
  );
};
FlagValuesCountComponent.propTypes = {
  flagsCount: PropTypes.object.isRequired, // Require flagsCount to be an object
};

export default FlagValuesCountComponent;
