import { BsChevronRight } from "react-icons/bs";
import { riskStudioColors as colors } from "../../styles/colors";
import { positionsForKpi, kpiKeys } from "./ViewKpiPositions";
import CustomToolTip from "../CustomToolTip";
import clsx from "clsx";
import { getHeaderNameForMonthYear } from "../EditDataGrid/UtilsForEditDataGrids";

// @getConvertedRowForAU - This is the main function for Analytische Ubersicht data grid row construct (it takes the raw array data for kpis [The response of `/timeslicegroups/${groupId}/kpis ] and add the heading and headings)
export const getConvertedRowForAU = (rowData) => {
  const model = rowData[0];
  const getSubHeading = (
    id,
    name,
    key,
    modelObj,
    subHeading = true,
    kpiSubHeader = false
  ) => {
    const transformedObj = {
      id,
      name,
      key,
      subHeading,
      kpiSubHeader,
    };
    for (const prop of Object.keys(modelObj)) {
      if (prop !== "id" && prop !== "name" && prop !== "key") {
        if (prop === "fontWeight") {
          transformedObj[prop] = "bold";
        } else {
          transformedObj[prop] = "";
        }
      }
    }

    return transformedObj;
  };
  const emptyGap1 = getSubHeading(
    "gap-view-1",
    "EMPTY",
    "empty-gap-view-1",
    model,
    false,
    false
  );
  const emptyGap2 = getSubHeading(
    "gap-view-2",
    "EMPTY",
    "empty-gap-view-2",
    model,
    false,
    false
  );

  const aktivaTitle = getSubHeading(
    "gap_1-view",
    "AKTIVA",
    "gap_key-activa",
    model
  );
  const passivaTitle = getSubHeading(
    "gap_2-view",
    "PASSIVA",
    "gap_key-passiva",
    model
  );

  const guVTitle = getSubHeading("gap_3-view", "GuV", "gap_key-guv", model);
  const kpisTitle = getSubHeading("gap_4-view", "KPIs", "gap_key-kpis", model);
  const kpisSubTitleLiquid = getSubHeading(
    "gap-5-view",
    "Liquiditätskennzahlen",
    "gap-key-kpisub1",
    model,
    true,
    true
  );
  const kpisSubTitleEffizienz = getSubHeading(
    "gap-6-view",
    "Effizienz-Kennzahlen",
    "gap-key-kpisub2",
    model,
    true,
    true
  );
  const kpisSubTitleVerschuld = getSubHeading(
    "gap-7-view",
    "Verschuldungskennzahlen",
    "gap-key-kpisub3",
    model,
    true,
    true
  );
  const kpisSubTitleRental = getSubHeading(
    "gap-8-view",
    "Rentabilitätskennzahlen",
    "gap-key-kpisub4",
    model,
    true,
    true
  );
  const aktivaRows = rowData.slice(0, 12);
  const blSumAktiva = aktivaRows.shift();
  aktivaRows.push(blSumAktiva);
  const passivaRows = rowData.slice(12, 26);
  const blSumPassiva = passivaRows.shift();
  passivaRows.push(blSumPassiva);
  const guvRows = rowData.slice(26, 46);
  const kpisRows = rowData.slice(46);
  return [
    aktivaTitle,
    ...aktivaRows,
    passivaTitle,
    ...passivaRows,
    emptyGap1,
    guVTitle,
    ...guvRows,
    emptyGap2,
    kpisTitle,
    kpisSubTitleLiquid,
    ...kpisRows.slice(0, 3),
    kpisSubTitleEffizienz,
    ...kpisRows.slice(3, 7),
    kpisSubTitleVerschuld,
    ...kpisRows.slice(7, 9),
    kpisSubTitleRental,
    ...kpisRows.slice(9),
  ];
};

// @gridStyleForAU - Main styling for Analytische Ubersicht Data grid
export const gridStyleForAU = {
  "&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell": {
    py: "5px",
  },
  "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": {
    py: "10px",
  },
  "&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell": {
    py: "12px",
  },
  "& .super-app--sub__analytics-headers": {
    backgroundColor: "rgba(200, 230, 201, 0.5)",
    "&:hover": {
      backgroundColor: "rgba(200, 230, 201, 1)",
    },
  },
  "& .super-app--sub__analytics-kpi-headers": {
    backgroundColor: "rgba(210, 210, 210, 0.3)",
    "&:hover": {
      backgroundColor: "rgba(210, 210, 205, 0.7)",
    },
  },
  borderRadius: "0px 0px 5px 5px",
};

// @getStyleForLevels - Returns diffrent css style object based on different levels in position data for kpis
export const getStyleForLevels = (rowMetaData) => {
  const paddingScale = 1;
  const fixedStyle = {
    color: rowMetaData.color || "",
    fontWeight: rowMetaData.fontWeight,
    fontSize: rowMetaData.fontSize || "0.95rem",
  };
  if (rowMetaData.level === 1) {
    return {
      ...fixedStyle,
      paddingLeft: `${paddingScale * 0.5}rem`,
    };
  }
  if (rowMetaData.level === 0) {
    return {
      ...fixedStyle,
      paddingLeft: `${paddingScale * 1}rem`,
    };
  }
  if (rowMetaData.level === 2) {
    return {
      ...fixedStyle,
      paddingLeft: `${paddingScale * 3}rem`,
    };
  }
  if (rowMetaData.level === 3) {
    return {
      ...fixedStyle,
      paddingLeft: `${paddingScale * 4}rem`,
    };
  }
  return fixedStyle;
};

// @getFormattedValue - Get formatted value for kpi values
export const getFormattedValue = (value, metaData, isInPercent = false) => {
  const forRoundedPercentage = ["eqr", "isgm", "ebitdamargin", "ebitmargin"];
  const forRounded = ["dos", "doi", "dop", "ccc"];
  if (forRounded.includes(metaData.key)) {
    const roundedValue = Math.round(value);
    return new Intl.NumberFormat("de-DE").format(roundedValue);
  } else if (forRoundedPercentage.includes(metaData.key)) {
    const zeroDecVal = Math.round(value * 100);
    return `${new Intl.NumberFormat("de-DE").format(zeroDecVal)}%`;
  } else if (metaData.key === "dtoe") {
    const oneDecValue = value.toFixed(1);
    return new Intl.NumberFormat("de-DE").format(oneDecValue);
  } else if (metaData.key === "pl_9_overview") {
    return new Intl.NumberFormat("de-DE").format(value.toFixed(0));
  } else if (isInPercent) {
    const inPercentFormat = value * 100;
    return new Intl.NumberFormat("de-DE").format(inPercentFormat.toFixed(1));
  }
  return new Intl.NumberFormat("de-DE").format(value);
};

// @getTimeSliceColumnsGroups - Creates the group which contains other subgroups. In this case HGB, Analytics, in%
export const getTimeSliceColumnsGroups = (data, suffixes) => {
  if (suffixes.length === 0) {
    return [];
  }
  const response = data.reduce((acc, item) => {
    const children = suffixes.map((suffix) => ({
      field: `${item.id}_${suffix.toLowerCase()}`,
    }));
    acc.push({
      groupId: getHeaderNameForMonthYear(item.year_month.toString()),
      children: children,
      headerAlign: "center",
    });
    return acc;
  }, []);
  return response;
};

// @getTimeSliceColumns - Creates the data grid column structures for HGB, Analytics, In%
export const getTimeSliceColumns = (
  data,
  matches,
  suffixes = ["HGB", "Analytics", "in%"]
) => {
  const transformedData = data.reduce((acc, item) => {
    const baseField = item.id.toString();
    suffixes.forEach((suffix) => {
      const isInPercent = suffix === "in%";
      acc.push({
        field: baseField + `_${suffix.toLowerCase()}`,
        width: isInPercent ? 50 : 100,
        editable: false,
        type: "number",
        flex: isInPercent ? 0.5 : 1,
        cellClassName: (params) => {
          // noinspection JSAnnotator
          return clsx("in_percent", {
            "in_percent--active": isInPercent,
            "in_percent--false": isInPercent && params.row.key.includes("gap"),
          });
        },
        renderHeader: (params) => {
          return (
            <strong style={{ color: colors.balanceSumTextColor }}>
              {suffix}
            </strong>
          );
        },
        renderCell: (params) => {
          const metaDataForKpiPositions = positionsForKpi.find(
            (pos) => pos.key === params.row.key
          );
          if (metaDataForKpiPositions) {
            const isKpi = kpiKeys.includes(metaDataForKpiPositions.key);
            const cellStyle = {
              padding: 0,
              margin: 0,
              fontWeight: metaDataForKpiPositions.fontWeight,
              fontSize: "0.95rem",
              color: metaDataForKpiPositions.color,
            };
            return (
              <p style={cellStyle}>
                {params.row.subHeading || (isKpi && isInPercent)
                  ? ""
                  : getFormattedValue(
                      params.value,
                      metaDataForKpiPositions,
                      isInPercent
                    )}
              </p>
            );
          }
          return params.value;
        },
      });
    });
    return acc;
  }, []);

  const positionColumn = {
    field: "name",
    width: matches ? 400 : 450,
    editable: false,
    renderHeader: (params) => {
      return (
        <strong style={{ color: colors.balanceSumTextColor }}>
          Position Name
        </strong>
      );
    },
    renderCell: (params) => {
      const metaDataForKpiPositions = positionsForKpi.find(
        (pos) => pos.key === params.row.key
      );
      if (metaDataForKpiPositions) {
        return (
          <div style={getStyleForLevels(metaDataForKpiPositions)}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {metaDataForKpiPositions.level === 0 ? (
                <span
                  style={{
                    marginRight: "0.5rem",
                  }}
                >
                  <BsChevronRight size="10px" />
                  {"  "}
                </span>
              ) : (
                ""
              )}
              <CustomToolTip arrow title={metaDataForKpiPositions.title || ""}>
                <span>{metaDataForKpiPositions.name || params.value}</span>
              </CustomToolTip>
            </div>
          </div>
        );
      }
      return params.value;
    },
    cellClassName: (params) => {
      // noinspection JSAnnotator
      return clsx("view-kpi_gap", {
        "view-kpi_gap--active": params.row.key.includes("gap"),
      });
    },
  };

  transformedData.unshift(positionColumn);
  return transformedData;
};
