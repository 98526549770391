import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { Select, MenuItem } from "@mui/material";
import PropTypes from "prop-types";
import { StateContext } from "../../../context/StateContext";

const DDCallSelector = ({
  selectedDdCallId,
  handleSelectChange,
  ddCalls,
  setSelectedDdCallId,
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { ddid, setddid } = useContext(StateContext);
  const [initialized, setInitialized] = useState(false);
  const updateUrlWithDdCallId = (ddCallId) => {
    setddid(ddCallId);
    const newSearchParams = new URLSearchParams(location.search);

    newSearchParams.set("dd_call_id", ddCallId);
    navigate({ search: `?${newSearchParams.toString()}` }, { replace: true });
  };
  useEffect(() => {
    setddid(selectedDdCallId);
    const searchParams = new URLSearchParams(location.search);
    const ddCallIdParam = searchParams.get("dd_call_id");
    if (ddCallIdParam === "null" && ddCalls.length > 0) {
      const latestDdCall = ddCalls[ddCalls.length - 1];
      console.log("latestDdCall", latestDdCall);

      handleSelectChange({ target: { value: latestDdCall.id } });
      updateUrlWithDdCallId(latestDdCall.id);
    }
    if (ddCallIdParam) {
      setSelectedDdCallId(ddCallIdParam);
    } else {
      updateUrlWithDdCallId(ddid);
    }

    setInitialized(true);
  }, [
    initialized,
    location.search,
    ddCalls,
    handleSelectChange,
    selectedDdCallId,
  ]);

  const handleSelect = (event) => {
    const selectedValue = event.target.value;
    handleSelectChange(event);
    const searchParams = new URLSearchParams(location.search);
    const ddCallIdParam = searchParams.get("dd_call_id");
    if (ddCallIdParam === "null") {
      updateUrlWithDdCallId(selectedValue);
    }
  };
  return (
    <Select
      value={selectedDdCallId}
      onChange={handleSelect}
      displayEmpty
      inputProps={{ "aria-label": "Without label" }}
      sx={{ mr: 2 }} // Add right margin for spacing
    >
      <MenuItem value="" disabled label={"choose DD Call"}>
        DD Call auswählen
      </MenuItem>
      {ddCalls.map((ddCall) => (
        <MenuItem key={ddCall.id} value={ddCall.id}>
          {ddCall.name}
        </MenuItem>
      ))}
      <MenuItem value="new" style={{ fontWeight: "bold", color: "green" }}>
        + Neuer DD Call
      </MenuItem>
    </Select>
  );
};

export default DDCallSelector;

DDCallSelector.propTypes = {
  selectedDdCallId: PropTypes.string.isRequired,
  handleSelectChange: PropTypes.func.isRequired,
  ddCalls: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
};
