import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Button, Box, Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { crefoAPI } from "../../crefoServiceAPI.js";
import { riskStudioColors as colors } from "../../styles/colors";
import ErrorIcon from "@mui/icons-material/Error";
import InfoIcon from "@mui/icons-material/Info";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import * as api from "../../api";
import CustomNoRowsOverlay from "../Utils/CustomNoRowsOverlay.js";

// THIS IS THE CREFO SEARCH COMPONENT
const SearchCrefo = ({ companyData, setRefreshCrefoComponent }) => {
  // DESTRUCTURING COMPANY DATA
  const {
    id: companyId,
    name: companyname,
    hq_zip_code: postcode,
    hq_city: city,
    hq_street: street,
    hq_country: country,
  } = companyData;
  const [crefoSearchResults, setCrefoSearchResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [crefoLinkMessage, setCrefoLinkMessage] = useState(null);
  const [crefoLinkLoading, setCrefoLinkLoading] = useState(false);
  // HELPER FUNCTION TO GET CREFO SEARCH RESULTS
  const getCrefoSearch = () => {
    setLoading(true);
    crefoAPI
      .searchCrefo({
        companyname,
        postcode,
        city,
        country: country || "DE",
        street,
      })
      .then((data) => {
        if (data.success) {
          if (data.search.hitlist.length > 0) {
            const results = data.search.hitlist.map((hit) => {
              return {
                id: hit.identificationnumber,
                companyName: hit.companyname,
                address: `${hit.street || ""} ${hit.housenumber || ""} ${
                  hit.housenumberaffix || ""
                }`,
                postcode: hit.postcode,
                city: hit.city,
              };
            });
            setCrefoSearchResults(results);
          }
        }
        setLoading(false);
      })
      .catch((error) => {
        setErrorMsg(
          error.response?.data?.errors[0]?.title ||
            "An error occurred while fetching crefo search results!"
        );
        setLoading(false);
        console.error(error);
      });
  };
  // HELPER FUNCTION TO GET MESSAGES ON SUCCESS OR ERROR
  const getMessages = (type, message) => {
    return (
      <Box
        sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}
      >
        <Box
          sx={{
            display: "flex",
            border: `${type === "info" ? "0" : "1"}px solid ${
              type === "error" ? "red" : "green"
            }`,
            borderRadius: "5px",
            padding: "0.5rem 1rem",
            justifyContent: "center",
            alignItems: "center",
            marginBottom: "0.5rem",
          }}
        >
          {type === "error" && (
            <ErrorIcon color={type} sx={{ marginRight: "5px" }} />
          )}
          {type === "success" && (
            <CheckCircleIcon color={type} sx={{ marginRight: "5px" }} />
          )}
          {type === "info" && (
            <InfoIcon color={type} sx={{ marginRight: "5px" }} />
          )}
          <Typography variant="h7" color={type}>
            {message}
          </Typography>
        </Box>
      </Box>
    );
  };
  // SIDE EFFECTS - FETCHING CREFO SEARCH RESULTS
  useEffect(() => {
    getCrefoSearch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // LOADING STATE MANAGEMENT
  if (loading) {
    return (
      <Stack spacing={2}>
        <Skeleton variant="rectangular" width={"100%"} height={35} />
        <Skeleton variant="rectangular" width={"100%"} height={35} />
        <Skeleton variant="rectangular" width={"100%"} height={35} />
      </Stack>
    );
  }
  // ERROR STATE MANAGEMENT
  if (errorMsg) {
    return getMessages("error", errorMsg);
  }

  // SUCCESS STATE MANAGEMENT
  if (crefoSearchResults || loading) {
    // GRID COLUMNS FOR CREFO SEARCH RESULTS
    const crefoSearchColumns = [
      {
        field: "companyName",
        minWidth: 250,
        flex: 0.5,
        renderCell: ({ value }) => {
          return <span style={{ overflowX: "scroll" }}>{value}</span>;
        },
        renderHeader: (_) => {
          return (
            <strong style={{ color: colors.balanceSumTextColor }}>
              Company Name
            </strong>
          );
        },
      },
      {
        field: "address",
        flex: 0.3,
        renderCell: ({ value }) => {
          return <span style={{ overflowX: "scroll" }}>{value}</span>;
        },
        renderHeader: (_) => {
          return (
            <strong style={{ color: colors.balanceSumTextColor }}>
              Address
            </strong>
          );
        },
      },
      {
        field: "postcode",
        flex: 0.2,
        renderCell: ({ value }) => {
          return <span>{value}</span>;
        },
        renderHeader: (_) => {
          return (
            <strong style={{ color: colors.balanceSumTextColor }}>
              Postcode
            </strong>
          );
        },
      },
      {
        field: "city",
        flex: 0.2,
        renderCell: ({ value }) => {
          return <span>{value}</span>;
        },
        renderHeader: (_) => {
          return (
            <strong style={{ color: colors.balanceSumTextColor }}>City</strong>
          );
        },
      },
      {
        field: "id",
        width: 130,
        renderCell: ({ value }) => {
          return <span>{value}</span>;
        },
        renderHeader: (_) => {
          return (
            <strong style={{ color: colors.balanceSumTextColor }}>
              Crefo ID
            </strong>
          );
        },
      },
      // ACTION COLUMN FOR LINKING CREFO
      {
        field: "actions",
        width: 100,
        disableColumnMenu: true,
        sortable: false,
        editable: false,
        renderCell: ({ row }) => {
          const { id: crefoId } = row;
          return (
            <Button
              onClick={() => {
                setCrefoLinkLoading(true);
                api
                  .updateCompanyData(companyId, {
                    crefo_id: crefoId,
                  })
                  .then((_) => {
                    setCrefoLinkMessage({
                      type: "success",
                      message: `Linked crefo successfully with Crefo ID: ${crefoId}!`,
                    });
                    setCrefoLinkLoading(false);
                  })
                  .catch((error) => {
                    setCrefoLinkMessage({
                      type: "error",
                      message: error.response?.data?.errors?.[0]?.title,
                    });
                    setCrefoLinkLoading(false);
                  })
                  .finally(() => {
                    setTimeout(() => {
                      setRefreshCrefoComponent(true);
                      setCrefoLinkMessage(null);
                    }, 1000);
                  });
              }}
              disabled={crefoLinkLoading}
            >
              {crefoLinkLoading ? "Linking..." : "Link"}
            </Button>
          );
        },
        renderHeader: (_) => {
          return (
            <strong style={{ color: colors.balanceSumTextColor }}>
              Crefo Link
            </strong>
          );
        },
      },
    ];

    // GRID ROWS FOR CREFO SEARCH RESULTS
    const crefoSearchRows = crefoSearchResults || [];

    return (
      <Box>
        {crefoLinkMessage &&
          getMessages(crefoLinkMessage?.type, crefoLinkMessage?.message)}

        <Box sx={{ height: 400, width: "100%" }}>
          <DataGrid
            rows={crefoSearchRows}
            columns={crefoSearchColumns}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 5,
                },
              },
            }}
            slots={{
              noRowsOverlay: CustomNoRowsOverlay,
            }}
            pageSizeOptions={[5]}
            disableRowSelectionOnClick
          />
        </Box>
      </Box>
    );
  }

  return <Box />;
};

SearchCrefo.propTypes = {
  companyData: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    hq_zip_code: PropTypes.string,
    hq_city: PropTypes.string,
    hq_street: PropTypes.string,
    hq_country: PropTypes.string,
  }).isRequired,
  setRefreshCrefoComponent: PropTypes.func.isRequired,
};

export default SearchCrefo;
