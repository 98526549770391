import React, { useState } from 'react';
import {Button, TextField, Grid} from '@mui/material';
import PropTypes from "prop-types";

const SpeakerItem = ({ speaker, onSpeakerUpdate, isLoading }) => {
    const [newSpeaker, setNewSpeaker] = useState(null);
    const [employeeId, setEmployeeId] = useState(null);
    const [peopleId, setPeopleId] = useState(null);

    const handleUpdate = () => {
        onSpeakerUpdate(speaker.speaker, newSpeaker, employeeId, peopleId);
    };

    return (
        <Grid container spacing={2} alignItems="center" my={2}>
            <Grid item xs={12} sm={3}>
                <p>{speaker.speaker}</p>
            </Grid>
            <Grid item xs={12} sm={3}>
                <TextField
                    label="Neuer Teilnehmer Name"
                    value={newSpeaker}
                    onChange={(e) => setNewSpeaker(e.target.value)}
                    fullWidth
                    margin="dense"
                />
            </Grid>
            <Grid item xs={12} sm={2}>
                <TextField
                    label="CSP Employee ID"
                    value={employeeId}
                    onChange={(e) => setEmployeeId(e.target.value)}
                    fullWidth
                    margin="dense"
                />
            </Grid>
            <Grid item xs={12} sm={2}>
                <TextField
                    label="CSP Person ID"
                    value={peopleId}
                    onChange={(e) => setPeopleId(e.target.value)}
                    fullWidth
                    margin="dense"
                />
            </Grid>
            <Grid item xs={12} sm={2}>
                <Button
                    onClick={handleUpdate}
                    color="primary"
                    variant="contained"
                    fullWidth
                    disabled={isLoading}
                >
                    Update
                </Button>
            </Grid>
        </Grid>
    );
};

export default SpeakerItem;

SpeakerItem.propTypes = {
    speaker: PropTypes.shape({
        id: PropTypes.number.isRequired,
        speaker: PropTypes.string.isRequired,
    }).isRequired,
    onSpeakerUpdate: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
}
