import React from "react";
import { useTheme } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import Brightness4Icon from "@mui/icons-material/Brightness4";
import Brightness7Icon from "@mui/icons-material/Brightness7";
import { ThemeModeContext } from "../../context/ThemeModeContext";
import ToolTip from "@mui/material/Tooltip";

// MAIN COMPONENT
const ToggleThemeMode = () => {
  const theme = useTheme();
  const colorMode = React.useContext(ThemeModeContext);

  return (
    <ToolTip
      title={theme.palette.mode === "dark" ? "Light Mode" : "Dark Mode"}
      placement="right"
      arrow
    >
      <IconButton
        sx={{
          ml: 1.5,
          transition: "0.3s all cubic-bezier(.25,.8,.25,1)",
          "&:hover": {
            color: theme.palette.mode === "dark" ? "#fff394" : "#3f51b5",
            scale: "1.2",
          },
          "&:active": {
            color: theme.palette.mode === "dark" ? "#fff176" : "blue",
            scale: "0.9",
          },
        }}
        color="inherit"
        onClick={colorMode.toggleColorMode}
        // disableRipple
      >
        {theme.palette.mode === "dark" ? (
          <Brightness7Icon />
        ) : (
          <Brightness4Icon />
        )}
      </IconButton>
    </ToolTip>
  );
};

export default ToggleThemeMode;
