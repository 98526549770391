import React, { useState } from "react";
import { Button, CircularProgress } from "@mui/material";
import { fetchRiskModelExcelFromGroup } from "../api";
import { TiExport } from "react-icons/ti";
import PropTypes from "prop-types";
const ExportExcelButton = ({ groupId, borrowerGroupId, companyName }) => {
  const [loading, setLoading] = useState(false);
  const onClickGetExcel = async (groupId) => {
    if (groupId) {
      setLoading(true);
      await fetchRiskModelExcelFromGroup(groupId, borrowerGroupId, companyName);
      setLoading(false);
    }
  };

  return (
    <div>
      <Button
        variant="outlined"
        color="primary"
        onClick={() => onClickGetExcel(groupId)}
        startIcon={
          loading ? <CircularProgress size={24} color="inherit" /> : null
        }
        disabled={!groupId || loading}
        size="small"
      >
        <TiExport
          style={{ marginBottom: "3px", marginRight: "5px", fontSize: "1rem" }}
        />
        Export
      </Button>
    </div>
  );
};

export default ExportExcelButton;

ExportExcelButton.propTypes = {
  companyName: PropTypes.string.isRequired,
  borrowerGroupId: PropTypes.string.isRequired,
  groupId: PropTypes.string.isRequired
};
