import React, { useState } from "react";
import PropTypes from "prop-types";
import { Box } from "@mui/material";
import LoanConditionTableComponent from "./LoanConditionTableComponent";
import LoanConditionUtilsProvider from "./LoanConditionUtils";

const LoanConditionsWrapper = ({ loanConditionPositions }) => {
  const loanConditionUtilsProvider = new LoanConditionUtilsProvider(
    loanConditionPositions
  );
  const [hiddenSections, setHiddenSections] = useState({
    loanConditions: false,
    disbursementRequirements: false,
    covenants: false,
    investorProtection: false,
  });

  return (
    <Box data-testId="loan-condition-FO-wrapper">
      <LoanConditionTableComponent
        title={"VIII. LOAN CONDITIONS"}
        selectedCategory={"LoanRepaymentEnum"}
        loanConditionPositions={loanConditionPositions}
        loanConditionUtilsProvider={loanConditionUtilsProvider}
        isVisible={!hiddenSections.loanConditions}
        toggleVisibility={() =>
          setHiddenSections({
            ...hiddenSections,
            loanConditions: !hiddenSections.loanConditions,
          })
        }
      />

      <LoanConditionTableComponent
        title={"IX. DISBURSEMENT REQUIREMENTS"}
        selectedCategory={"DisbursementRequirementsEnum"}
        loanConditionPositions={loanConditionPositions}
        loanConditionUtilsProvider={loanConditionUtilsProvider}
        isVisible={!hiddenSections.disbursementRequirements}
        toggleVisibility={() =>
          setHiddenSections({
            ...hiddenSections,
            disbursementRequirements: !hiddenSections.disbursementRequirements,
          })
        }
      />

      <LoanConditionTableComponent
        title={"X. COVENANTS"}
        selectedCategory={"CovenantsEnum"}
        loanConditionPositions={loanConditionPositions}
        loanConditionUtilsProvider={loanConditionUtilsProvider}
        isVisible={!hiddenSections.covenants}
        toggleVisibility={() =>
          setHiddenSections({
            ...hiddenSections,
            covenants: !hiddenSections.covenants,
          })
        }
      />

      <LoanConditionTableComponent
        title={"XI. INVESTOR PROTECTION"}
        selectedCategory={"InvestorProtectionEnum"}
        loanConditionPositions={loanConditionPositions}
        loanConditionUtilsProvider={loanConditionUtilsProvider}
        isVisible={!hiddenSections.investorProtection}
        toggleVisibility={() =>
          setHiddenSections({
            ...hiddenSections,
            investorProtection: !hiddenSections.investorProtection,
          })
        }
      />
    </Box>
  );
};

LoanConditionsWrapper.propTypes = {
  loanConditionPositions: PropTypes.object.isRequired,
};

export default LoanConditionsWrapper;
