import PropTypes from "prop-types";
import * as React from "react";
import { CrefoDropdown } from "./CrefoDropdownUtils/CrefoDropdown";
import CrefoDialog from "./CrefoDialog";
import CrefoRequestReport from "./CrefoRequestReport";
import CrefoAllReports from "./CrefoAllReports";
import SearchCrefo from "./SearchCrefo";
import { Box } from "@mui/system";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import CrefoUnlinkPrompt from "./CrefoUnlinkPrompt";

// HELPER FUNCTION TO GET DROPDOWN LIST ACTIONS
function getCrefoDropDownLists(
  companyData,
  isLoading,
  changeCrefoSearchModalState,
  changeCrefoReportsModalState,
  changeCrefoRequestModalState,
  changeCrefoUnlinkModalState
) {
  const isActive =
    !isLoading &&
    companyData.crefoId !== null &&
    companyData.crefoId !== undefined;
  const actions = [];
  const linkAction = {
    text: "link",
    onClick: changeCrefoSearchModalState,
  };

  if (isActive) {
    actions.push({
      text: "unlink",
      onClick: changeCrefoUnlinkModalState,
    });
    actions.push({
      text: "Request Report",
      onClick: changeCrefoRequestModalState,
    });
    actions.push({
      text: "All Reports",
      onClick: changeCrefoReportsModalState,
    });
  }

  return {
    action: isActive ? actions : linkAction,
  };
}

// MAIN COMPONENT
export const CrefoDashboard = ({ companyData, setRefreshCrefoComponent }) => {
  const [openCrefoModal, setOpenCrefoModal] = React.useState(false);
  const [crefoFunctionSelected, setCrefoFunctionSelected] =
    React.useState("search");
  let crefoId = null;
  let companyId = null;

  let isLoading = false;
  if (companyData === null) {
    isLoading = true;
  }

  if (!isLoading) {
    crefoId = companyData.crefo_id;
    companyId = companyData.id;
  }
  // HELPER FUNCTIONS TO CHANGE MODAL STATE
  const changeCrefoSearchModalState = () => {
    setOpenCrefoModal(true);
    setCrefoFunctionSelected("search");
  };
  const changeCrefoRequestModalState = () => {
    setOpenCrefoModal(true);
    setCrefoFunctionSelected("request");
  };
  const changeCrefoReportsModalState = () => {
    setOpenCrefoModal(true);
    setCrefoFunctionSelected("reports");
  };
  const changeCrefoUnlinkModalState = () => {
    setOpenCrefoModal(true);
    setCrefoFunctionSelected("unlink");
  };
  // HELPER FUNCTION TO GET DROPDOWN LIST ACTIONS
  const dropDownListActions = getCrefoDropDownLists(
    { companyId, crefoId },
    isLoading,
    changeCrefoSearchModalState,
    changeCrefoReportsModalState,
    changeCrefoRequestModalState,
    changeCrefoUnlinkModalState
  ).action;
  // HELPER BLOCKS TO GET MODAL TITLE
  let modalTitle = "";
  if (crefoFunctionSelected === "search") {
    modalTitle = "Search Crefo";
  } else if (crefoFunctionSelected === "reports") {
    modalTitle = "Crefo Reports";
  } else if (crefoFunctionSelected === "request") {
    modalTitle = "Crefo Report Request";
  } else if (crefoFunctionSelected === "unlink") {
    modalTitle = "Unlink Crefo";
  }

  return (
    <Box
      sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
    >
      <CrefoDropdown
        dropDownListActions={dropDownListActions}
        dropdownText={"Options"}
      />
      {companyData?.crefo_id ? (
        <CheckCircleIcon
          sx={{ fontSize: "25px", marginLeft: "10px" }}
          color="success"
        />
      ) : (
        <CancelIcon
          sx={{ fontSize: "25px", marginLeft: "10px" }}
          color="error"
        />
      )}
      {/* MAIN MODAL FOR ALL CREFO ACTIONS */}
      {openCrefoModal && (
        <CrefoDialog
          openCrefoModal={openCrefoModal}
          setOpenCrefoModal={setOpenCrefoModal}
          modalTitle={modalTitle}
          crefoFunctionSelected={crefoFunctionSelected}
        >
          {crefoFunctionSelected === "search" && (
            <SearchCrefo
              companyData={companyData}
              setRefreshCrefoComponent={setRefreshCrefoComponent}
            />
          )}
          {crefoFunctionSelected === "request" && (
            <CrefoRequestReport
              companyData={companyData}
              closeOpenCrefoModal={() => setOpenCrefoModal(false)}
            />
          )}
          {crefoFunctionSelected === "reports" && (
            <CrefoAllReports
              companyId={companyId}
              crefoId={crefoId}
              closeOpenCrefoModal={() => setCrefoFunctionSelected(false)}
            />

          )}
          {crefoFunctionSelected === "unlink" && (
            <CrefoUnlinkPrompt
              companyId={companyId}
              setRefreshCrefoComponent={setRefreshCrefoComponent}
              setOpenCrefoModal={setOpenCrefoModal}
            />
          )}
        </CrefoDialog>
      )}
    </Box>
  );
};
CrefoDashboard.propTypes = {
  companyData: PropTypes.object || null,
  setRefreshCrefoComponent: PropTypes.func.isRequired,
};
export default CrefoDashboard;
