import React from "react";
import { Box, IconButton, Tooltip, TextField } from "@mui/material";
import ContentCopyRoundedIcon from "@mui/icons-material/ContentCopyRounded";
import { toast } from "sonner";
import PropTypes from "prop-types";
import EditIcon from "@mui/icons-material/Edit";
import CloseIcon from "@mui/icons-material/Close";
import SaveIcon from "@mui/icons-material/Save";
import { updateTranscriptForDDCall } from "../../api";
import Skeleton from "@mui/material/Skeleton";

// UTILITY FUNCTIONS =============>
export const formatDate = (customTimeStamp, timeStamp) => {
  if (customTimeStamp) {
    return customTimeStamp;
  }
  if (timeStamp) {
    const normalisedDate = new Date(timeStamp);
    const userTimezoneOffset = normalisedDate.getTimezoneOffset() * 60000;
    const localDate = new Date(normalisedDate.getTime() - userTimezoneOffset);
    return localDate.toLocaleString("en-US", {
      timeZone: "UTC", // Specify the time zone
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });
  }
  return "";
};

export const formatContent = (content) => {
  // Check if content is null or undefined
  if (content == null) {
    return <Box />;
  }

  return content.split("\n").map((paragraph, idx) => (
    <Box
      key={`paragraph-${idx + 1}`}
      sx={{
        marginBottom: idx === content.split("\n").length - 1 ? "" : "10px",
      }}
    >
      {paragraph}
    </Box>
  ));
};

// MAIN COMPONENT =============>
export const ChatBubble = ({
  transcriptId,
  setRefetchTranscript,
  content,
  isRight,
  name,
  timeStamp,
  ChatIcon,
  sources,
  isCopyable,
  customTimeStamp, // Optional prop for custom displayed timestamp
}) => {
  // STATE VARIABLES =============>
  // Text content = the content of the chat bubble at first , this is what
  const [textContent, setTextContent] = React.useState(content);
  // Edit content = true if the user is editing the content
  const [editContent, setEditContent] = React.useState(false);
  // Edited content = the content that the user is editing
  const [editedContent, setEditedContent] = React.useState(content);
  // Saving edited content = true if the user is saving the edited content
  const [savingEditedContent, setSavingEditedContent] = React.useState(false);
  // Styling variables
  const citationColor = "#115b8f";
  const citationBgColor = "#bbe1fc";
  // Displayed timestamp
  const displayedTimeStamp = formatDate(customTimeStamp, timeStamp);

  // RENDER FUNCTIONS =============>
  // RENDER ACTIONS CONTAINER FUNCTION: Render the actions container above each chat bubble - edit, save, copy, cancel
  const renderActionsContainer = () => {
    return (
      <Box>
        {/* Save Button */}
        {editContent && (
          <Tooltip title={"Save"} arrow>
            <IconButton
              sx={{ width: "21px", height: "21px", margin: "0 5px 0 0" }}
              onClick={() => {
                setSavingEditedContent(true);
                if (editedContent === content) {
                  setSavingEditedContent(false);
                  setEditContent(!editContent);
                  toast.info("No changes made! Please add changes to save.");
                }
                // Update the content
                else if (editedContent !== content) {
                  // Call the update transcript API
                  updateTranscriptForDDCall(transcriptId, {
                    content: editedContent,
                  })
                    .then(() => {
                      // Setting the new content as the text content
                      setTextContent(editedContent);
                      // Refetch the transcript
                      setRefetchTranscript((prev) => !prev);
                      // Set the edit content to false to exit edit mode
                      setEditContent(!editContent);
                      // Notify UI the success
                      toast.success("Transcript aktualisiert!");
                    })
                    .catch((_) => {
                      // Notify UI the error
                      toast.error(
                        "ERROR:: Transcript konnte nicht aktualisiert werden!"
                      );
                    })
                    .finally(() => {
                      // Set the saving loading to false
                      setSavingEditedContent(false);
                    });
                }
              }}
            >
              <SaveIcon
                color="inherit"
                sx={{
                  width: "18px",
                  color: isRight ? "teal" : "gray",
                  margin: !isRight ? "0 0 0 5px" : "0 5px 0 0",
                }}
              />
            </IconButton>
          </Tooltip>
        )}
        {/* Cancel & Edit Button */}
        <Tooltip title={editContent ? "Cancel Edit" : "Edit answer"} arrow>
          <IconButton
            sx={{ width: "21px", height: "21px", margin: "0 5px 0 0" }}
            onClick={() => {
              setEditContent(!editContent);
              setEditedContent(content);
            }}
          >
            {editContent ? (
              <CloseIcon
                color="inherit"
                sx={{
                  width: "18px",
                  color: "#ba000d",
                  margin: !isRight ? "0 0 0 5px" : "0 5px 0 0",
                }}
              />
            ) : (
              <EditIcon
                color="inherit"
                sx={{
                  width: "18px",
                  color: isRight ? "teal" : "gray",
                  margin: !isRight ? "0 0 0 5px" : "0 5px 0 0",
                }}
              />
            )}
          </IconButton>
        </Tooltip>
        {/* Copy Button */}
        <Tooltip title={"Copy answer"} arrow>
          <IconButton
            sx={{ width: "21px", height: "21px" }}
            onClick={() => {
              toast.success("Copied");
              navigator.clipboard.writeText(content);
            }}
          >
            <ContentCopyRoundedIcon
              color="inherit"
              sx={{
                width: "18px",
                color: isRight ? "gray" : "teal",
                margin: !isRight ? "0 0 0 5px" : "0 5px 0 0",
              }}
            />
          </IconButton>
        </Tooltip>
      </Box>
    );
  };

  // RENDER CONTENT FUNCTION: Render the main contents inside bubble
  const renderContent = () => {
    // Loader
    if (savingEditedContent) {
      return <Skeleton variant="rounded" width={550} height={30} />;
    } else {
      // render content based on edit mode
      return editContent ? (
        <Box
          sx={{
            marginBottom: sources && sources.length !== 0 ? "7px" : "",
            minWidth: "550px",
            "& .MuiInputBase-input": {
              fontSize: "14px",
            },
          }}
        >
          <TextField
            id="chat-bubble-edit"
            label="Edit Answer"
            multiline
            maxRows={8}
            value={editedContent}
            onChange={(e) => setEditedContent(e.target.value)}
            fullWidth
          />
        </Box>
      ) : (
        <Box
          sx={{
            marginBottom: sources && sources.length !== 0 ? "7px" : "",
          }}
        >
          {formatContent(textContent)}
        </Box>
      );
    }
  };
  // RETURN STATEMENT =============>
  return (
    <>
      <style>
        {`
        .chat-bubble-left {
          animation: bubble-appear-left 0.5s ease;
        }
        .chat-bubble-right {
          animation: bubble-appear-right 0.5s ease;
        }

        @keyframes bubble-appear-left {
          0% {
            opacity: 0;
            transform: translateY(5px) scale(0.95) translateX(-2.5%);
          }
          20% {
            opacity: 0.9;
          }
          100% {
            opacity: 1;
            transform: translateY(0px) scale(1) translateX(0px);
          }
        }
        @keyframes bubble-appear-right {
          0% {
            opacity: 0;
            transform: translateY(5px) scale(0.95) translateX(2.5%);
          }
          20% {
            opacity: 0.9;
          }
          100% {
            opacity: 1;
            transform: translateY(0px) scale(1) translateX(0px);
          }
        }
      `}
      </style>
      <Box
        className={`chat-bubble-${isRight ? "right" : "left"}`}
        sx={{
          display: "flex",
          justifyContent: isRight ? "flex-end" : "flex-start",
          paddingBottom: "10px",
        }}
      >
        <Box sx={{ maxWidth: "800px" }}>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: !isCopyable ? "flex-end" : "space-between",
              fontWeight: "bold",
              color: isRight ? "teal" : "gray",
              fontSize: "14px",
              paddingBottom: "3px",
            }}
          >
            {isRight && isCopyable && <>{renderActionsContainer()}</>}
            <Box sx={{ display: "flex", gap: "3px" }}>
              {!isRight && ChatIcon && (
                <ChatIcon
                  sx={{ height: "21px", width: "21px" }}
                  data-testid="chat-icon"
                />
              )}
              {isRight && (timeStamp || customTimeStamp) && (
                <Box
                  sx={{
                    color: "gray",
                    fontSize: "12px",
                    fontWeight: "normal",
                    marginTop: "auto",
                    marginRight: "5px",
                  }}
                  data-testid="chat-timestamp"
                >
                  {displayedTimeStamp}
                </Box>
              )}
              {name}
              {!isRight && (timeStamp || customTimeStamp) && (
                <Box
                  sx={{
                    color: "gray",
                    fontSize: "12px",
                    fontWeight: "normal",
                    marginTop: "auto",
                    marginLeft: "5px",
                  }}
                  data-testid="chat-timestamp"
                >
                  {displayedTimeStamp}
                </Box>
              )}
              {isRight && ChatIcon && (
                <ChatIcon
                  color="inherit"
                  sx={{ color: "teal", height: "21px", width: "21px" }}
                  data-testid="chat-icon"
                />
              )}
            </Box>
            {!isRight && isCopyable && <>{renderActionsContainer()}</>}
          </Box>
          <Box
            sx={{
              marginBottom: "10px",
              fontSize: "14px",
              padding: "10px 12px",
              backgroundColor: isRight ? "rgb(193, 242, 237)" : "#f5f5f5",
              marginLeft: isRight ? "auto" : "",
              borderRadius: "10px",
              position: "relative",
            }}
          >
            {/* Render main contents inside bubble */}
            {renderContent()}
            {sources && sources.length !== 0 && (
              <Box
                sx={{
                  display: "flex",
                  gap: "4px",
                  overflowX: "scroll",
                }}
              >
                {sources.map((source) => {
                  return (
                    <Box
                      sx={{
                        color: citationColor,
                        backgroundColor: citationBgColor,
                        borderRadius: "5px",
                        padding: "2px 5px",
                        width: "fit-content",
                        display: "flex",
                      }}
                      key={"source-" + source.foot_note}
                    >
                      <Tooltip
                        title={source.citation}
                        arrow
                        data-testid="chat-source-tooltip"
                      >
                        <Box sx={{ marginRight: "4px", whiteSpace: "nowrap" }}>
                          {source.foot_note} {source.source}
                        </Box>
                      </Tooltip>

                      <Tooltip title={"Copy citation"} arrow>
                        <IconButton
                          sx={{ width: "21px", height: "21px" }}
                          onClick={() => {
                            toast.success("Copied");
                            navigator.clipboard.writeText(
                              source.citation_string
                            );
                          }}
                        >
                          <ContentCopyRoundedIcon
                            color="inherit"
                            sx={{ width: "18px", color: citationColor }}
                          />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  );
                })}
              </Box>
            )}
            {/** Bubble Design */}
            {isRight ? (
              <Box
                sx={{
                  position: "absolute",
                  bottom: "-10px",
                  right: "0",
                  width: "20px",
                  height: "20px",
                  backgroundColor: "rgb(151, 219, 212)",
                  zIndex: "-1",
                  overflow: "hidden",
                }}
              >
                <Box
                  sx={{
                    width: "40px",
                    height: "20px",
                    backgroundColor: "white",
                    transform: "translate(-20%, 70%) rotate(24deg)",
                  }}
                />
              </Box>
            ) : (
              <Box
                sx={{
                  position: "absolute",
                  bottom: "-10px",
                  left: "0",
                  width: "20px",
                  height: "20px",
                  backgroundColor: "#e7e7e7",
                  zIndex: "-1",
                  overflow: "hidden",
                }}
              >
                <Box
                  sx={{
                    width: "40px",
                    height: "20px",
                    backgroundColor: "white",
                    transform: "translate(-20%, 70%) rotate(-24deg)",
                  }}
                />
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </>
  );
};

ChatBubble.propTypes = {
  transcriptId: PropTypes.number.isRequired,
  setRefetchTranscript: PropTypes.func.isRequired,
  content: PropTypes.string.isRequired,
  isRight: PropTypes.bool,
  name: PropTypes.string.isRequired,
  timeStamp: PropTypes.string,
  ChatIcon: PropTypes.elementType,
  sources: PropTypes.array,
  isCopyable: PropTypes.bool,
  customTimeStamp: PropTypes.string,
};
