import { Box, IconButton, Tooltip } from "@mui/material";
import "react-quill/dist/quill.snow.css";
import "react-quill/dist/quill.bubble.css";
import { useState } from "react";
import RemoveRedEyeRoundedIcon from "@mui/icons-material/RemoveRedEyeRounded";
import VisibilityOffRoundedIcon from "@mui/icons-material/VisibilityOffRounded";
import HistoryEduRoundedIcon from "@mui/icons-material/HistoryEduRounded";
import QuillWithResize from "./QuillWithResize";
import { putFinalOpinionDataPoint } from "../../api";
import { toast } from "sonner";
import { MessageTextgenerationModal } from "./MessageTextgenerationModal";
import PropTypes from "prop-types";

export const MessageComponent = ({
  keyData,
  keyName,
  companyId,
  creditProjectId,
  currentlyFocusedKey,
  setCurrentlyFocusedKey,
}) => {
  const keyDataReferenceOfLastSave = keyData; // reference object of last saved state to avoid unnecessary execution of updateDataPoint
  const [value, setValue] = useState(keyData?.content || "");
  const [hidden, setHidden] = useState(keyData?.hidden);
  const [showTextGenerationModal, setShowTextGenerationModal] = useState(false);

  // Quill toolbar - Initialised as empty array to avoid undefined error
  let toolbar = [];

  const getDescription = () => {
    if (keyData.description === null || keyData.description === "") {
      return "";
    }
    return keyData.description.split("/n").map((part) => {
      return <Box>{part}</Box>;
    });
  };

  const updateDataPoint = () => {
    if (
      keyDataReferenceOfLastSave.content !== value &&
      !(keyDataReferenceOfLastSave.content === null && value === "") // avoids unnecessary execution of updateDataPoint after initial creation (fields are defaulted at null)
    ) {
      putFinalOpinionDataPoint(creditProjectId, keyName, {
        content: value,
        number: null,
        hidden: hidden,
      }).then((res) => {
        if (typeof res === "string") {
          toast.error("Save Failed: " + res);
        } else {
          toast.success("Changes Saved");
          keyDataReferenceOfLastSave.content = res.content;
          keyDataReferenceOfLastSave.hidden = res.hidden;
          keyDataReferenceOfLastSave.number = res.number;
        }
      });
    }
  };

  const updateDataPointVisibility = () => {
    putFinalOpinionDataPoint(creditProjectId, keyName, {
      content: value,
      number: null,
      hidden: !hidden,
    }).then((res) => {
      if (typeof res === "string") {
        toast.error("Save Failed: " + res);
      } else {
        toast.success("Visibility Saved");
        keyDataReferenceOfLastSave.hidden = res.hidden;
      }
    });
  };

  const renderButtonBar = () => {
    const buttonStyle = {
      zIndex: "1",
      width: "28px",
      height: "28px",
    };
    return (
      <Box
        sx={{
          position: "absolute",
          top: "0px",
          right: "2px",
          display: "flex",
          gap: "2px",
          padding: "7px",
        }}
      >
        <MessageTextgenerationModal
          keyName={keyName}
          keyData={keyData}
          open={showTextGenerationModal}
          onClose={() => setShowTextGenerationModal(false)}
          companyId={companyId}
          creditProjectId={creditProjectId}
        />
        {keyData.default_prompt != null && (
          <Tooltip
            arrow
            title="AI generated answer based on the the company's uploaded Industry Report"
          >
            <IconButton
              sx={buttonStyle}
              onClick={() => {
                setShowTextGenerationModal(true);
              }}
              data-testid={keyName + "-hide-button"}
            >
              <HistoryEduRoundedIcon
                color="inherit"
                sx={{ color: "rgba(0,0,0,.15)" }}
              />
            </IconButton>
          </Tooltip>
        )}
        <IconButton
          sx={buttonStyle}
          onClick={() => {
            updateDataPointVisibility();
            setHidden((prev) => !prev);
          }}
          data-testid={keyName + "-hide-button"}
        >
          {!hidden ? (
            <RemoveRedEyeRoundedIcon
              color="inherit"
              sx={{ color: "rgba(0,0,0,.15)" }}
            />
          ) : (
            <VisibilityOffRoundedIcon
              color="inherit"
              sx={{ color: "rgba(0,0,0,.15)" }}
            />
          )}
        </IconButton>
      </Box>
    );
  };

  if (!keyData) {
    return <Box>No Key Data</Box>;
  }

  if (hidden) {
    return (
      <Box
        sx={{
          paddingBottom: "10px",
          padding: "3px",
          width: "100%",
          position: "relative",
          height: "40px",
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <Box
          sx={{
            marginRight: "70px",
            color: "#C9C9C9",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
          data-testid={keyName + "-Title"}
        >
          {keyData.name}
        </Box>
        {renderButtonBar()}
      </Box>
    );
  }

  if (keyData.type === "SWOT-field") {
    toolbar = [
      ["bold", "italic", "underline"],
      [{ color: [] }],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link", "image"],
    ];
    return (
      <Box sx={{ width: "100%" }}>
        <Box sx={{ width: "100%", textAlign: "center", fontWeight: "bold" }}>
          {keyData.name}
        </Box>
        <Box sx={{ width: "100%" }}>
          <Box
            data-testid={keyName}
            className={`${keyName}-class`}
            onClick={() => setCurrentlyFocusedKey(keyName)}
          >
            <style>
              {`
                .${keyName}-class .ql-snow.ql-toolbar {
                  display: ${
                    currentlyFocusedKey === keyName ? "static" : "none"
                  };
                }
              `}
            </style>
            <QuillWithResize
              key={keyName + creditProjectId}
              placeholder={""}
              setValue={setValue}
              updateDataPoint={updateDataPoint}
              toolbar={toolbar}
              theme="snow"
              value={value}
            />
          </Box>
        </Box>
      </Box>
    );
  }

  if (keyData.type === "noname-message" || keyData.type === "image-message") {
    if (keyData.type === "noname-message") {
      toolbar = [
        ["bold", "italic", "underline"],
        [{ color: [] }],
        ["link", "image"],
      ];
    } else if (keyData.type === "image-message") {
      toolbar = [["image"], ["clean"]];
    }
    return (
      <Box
        sx={{
          marginBottom: "4px",
          padding: "3px",
          width: "100%",
          position: "relative",
        }}
      >
        {renderButtonBar()}
        <Box sx={{ width: "100%" }}>
          <Box
            data-testid={keyName}
            className={`${keyName}-class`}
            onClick={() => setCurrentlyFocusedKey(keyName)}
          >
            <style>
              {`
                .${keyName}-class .ql-snow.ql-toolbar {
                  display: ${
                    currentlyFocusedKey === keyName ? "static" : "none"
                  };
                }
              `}
            </style>
            <QuillWithResize
              key={keyName + creditProjectId}
              placeholder={
                keyData.type === "image-message"
                  ? "Add images and screenshots here..."
                  : ""
              }
              setValue={setValue}
              updateDataPoint={updateDataPoint}
              toolbar={toolbar}
              theme="snow"
              value={value}
            />
          </Box>
        </Box>
      </Box>
    );
  }

  if (keyData.type === "message" || keyData.type === "conditional-message") {
    toolbar = [
      ["bold", "italic", "underline"],
      [{ color: [] }],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link", "image"],
    ];
    return (
      <Box
        sx={{
          marginBottom: "4px",
          position: "relative",
        }}
      >
        {renderButtonBar()}
        <Box
          sx={{
            display: "flex",
            gap: "1rem",
            padding: "3px",
          }}
        >
          <Box
            sx={{
              minWidth: "250px",
              maxWidth: "250px",
              textAlign: "right",
              fontWeight: "bold",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              color: keyData.type === "message" ? "black" : "gray",
              position: "relative",
              lineHeight: "1.1",
              fontSize: ".94rem",
            }}
          >
            <Box
              sx={{
                position: "absolute",
                width: "5px",
                height: "5px",
                borderRadius: "50%",
                backgroundColor:
                  keyData.description !== null && keyData.description !== ""
                    ? "#009688"
                    : "",
                top: "50%",
                right: "-7px",
                transform: "translateY(-50%)",
              }}
            />
            <Tooltip placement="right" arrow title={getDescription()}>
              <Box>{keyData.name}</Box>
            </Tooltip>
          </Box>

          <Box sx={{ flexGrow: "1" }}>
            <Box
              data-testid={keyName}
              className={`${keyName}-class`}
              onClick={() => setCurrentlyFocusedKey(keyName)}
            >
              <style>
                {`
                  .${keyName}-class .ql-snow.ql-toolbar {
                    display: ${
                      currentlyFocusedKey === keyName ? "static" : "none"
                    };
                  }
                `}
              </style>
              <QuillWithResize
                key={keyName + creditProjectId}
                placeholder={""}
                setValue={setValue}
                updateDataPoint={updateDataPoint}
                toolbar={toolbar}
                theme="snow"
                value={value}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        paddingBottom: "18px",
        display: "flex",
        gap: "1rem",
        padding: "3px",
      }}
    >
      TYPE NOT VALID
    </Box>
  );
};

MessageComponent.propTypes = {
  keyData: PropTypes.object,
  keyName: PropTypes.string,
  companyId: PropTypes.number,
  creditProjectId: PropTypes.string,
  currentlyFocusedKey: PropTypes.string,
  setCurrentlyFocusedKey: PropTypes.func,
};
