// Details : https://mui.com/x/react-data-grid/custom-columns/
import * as React from "react";
import PropTypes from "prop-types";
import {
  useGridApiContext,
  GRID_DATE_COL_DEF,
  GRID_DATETIME_COL_DEF,
} from "@mui/x-data-grid";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import InputBase from "@mui/material/InputBase";
import { de as locale } from "date-fns/locale";
import { styled } from "@mui/material/styles";

/**
 * Build a date filter function for the given filter item and compare function.
 * @param {Object} filterItem 
 * @param {Function} compareFn 
 * @param {Boolean} showTime 
 * @returns 
 */
function buildApplyDateFilterFn(filterItem, compareFn, showTime = false) {
  if (!filterItem.value) {
    return null;
  }

  // Make a copy of the date to not reset the hours in the original object
  const filterValueCopy = new Date(filterItem.value);
  filterValueCopy.setHours(0, 0, 0, 0);

  const filterValueMs = filterValueCopy.getTime();

  return ({ value }) => {
    if (!value) {
      return false;
    }

    // Make a copy of the date to not reset the hours in the original object
    const dateCopy = new Date(value);
    dateCopy.setHours(
      showTime ? value.getHours() : 0,
      showTime ? value.getMinutes() : 0,
      0,
      0
    );
    const cellValueMs = dateCopy.getTime();

    return compareFn(cellValueMs, filterValueMs);
  };
}


/** 
 * Get the date filter operators.
 * @param {Boolean} showTime 
 * @returns 
 */
function getDateFilterOperators(showTime = false) {
  return [
    {
      value: "is",
      getApplyFilterFn: (filterItem) => {
        return buildApplyDateFilterFn(
          filterItem,
          (value1, value2) => value1 === value2,
          showTime
        );
      },
      InputComponent: GridFilterDateInput,
      InputComponentProps: { showTime },
    },
    {
      value: "not",
      getApplyFilterFn: (filterItem) => {
        return buildApplyDateFilterFn(
          filterItem,
          (value1, value2) => value1 !== value2,
          showTime
        );
      },
      InputComponent: GridFilterDateInput,
      InputComponentProps: { showTime },
    },
    {
      value: "after",
      getApplyFilterFn: (filterItem) => {
        return buildApplyDateFilterFn(
          filterItem,
          (value1, value2) => value1 > value2,
          showTime
        );
      },
      InputComponent: GridFilterDateInput,
      InputComponentProps: { showTime },
    },
    {
      value: "onOrAfter",
      getApplyFilterFn: (filterItem) => {
        return buildApplyDateFilterFn(
          filterItem,
          (value1, value2) => value1 >= value2,
          showTime
        );
      },
      InputComponent: GridFilterDateInput,
      InputComponentProps: { showTime },
    },
    {
      value: "before",
      getApplyFilterFn: (filterItem) => {
        return buildApplyDateFilterFn(
          filterItem,
          (value1, value2) => value1 < value2,
          showTime
        );
      },
      InputComponent: GridFilterDateInput,
      InputComponentProps: { showTime },
    },
    {
      value: "onOrBefore",
      getApplyFilterFn: (filterItem) => {
        return buildApplyDateFilterFn(
          filterItem,
          (value1, value2) => value1 <= value2,
          showTime
        );
      },
      InputComponent: GridFilterDateInput,
      InputComponentProps: { showTime },
    },
    {
      value: "isEmpty",
      getApplyFilterFn: () => {
        return ({ value }) => {
          return value == null;
        };
      },
      requiresFilterValue: false,
    },
    {
      value: "isNotEmpty",
      getApplyFilterFn: () => {
        return ({ value }) => {
          return value != null;
        };
      },
      requiresFilterValue: false,
    },
  ];
}

/**
 * Date adapter
 */
const dateAdapter = new AdapterDateFns({ locale });

/**
 * `date` column
 */
export const dateColumnType = {
  ...GRID_DATE_COL_DEF,
  resizable: false,
  renderEditCell: (params) => {
    return <GridEditDateCell {...params} />;
  },
  filterOperators: getDateFilterOperators(),
  valueFormatter: (params) => {
    if (typeof params.value === "string") {
      return params.value;
    }
    if (params.value) {
      return new Date(params.value).toISOString().slice(0, 10);
    }
    return "";
  },
};

const GridEditDateInput = styled(InputBase)({
  fontSize: "inherit",
  padding: "0 9px",
});

function WrappedGridEditDateInput(props) {
  const { InputProps, focused, ...other } = props;
  return <GridEditDateInput fullWidth {...InputProps} {...other} />;
}
WrappedGridEditDateInput.propTypes = {
  focused: PropTypes.bool.isRequired,
  InputProps: PropTypes.object.isRequired,
};

function GridEditDateCell({ id, field, value, colDef }) {
  const apiRef = useGridApiContext();

  const Component = colDef.type === "dateTime" ? DateTimePicker : DatePicker;

  const handleChange = (newValue) => {
    apiRef.current.setEditCellValue({ id, field, value: newValue });
  };

  return (
    <Component
      value={value}
      autoFocus
      onChange={handleChange}
      slots={{ textField: WrappedGridEditDateInput }}
    />
  );
};
GridEditDateCell.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  field: PropTypes.string.isRequired,
  value: PropTypes.any,
  colDef: PropTypes.object.isRequired,
};

function GridFilterDateInput(props) {
  const { item, showTime, applyValue, apiRef } = props;

  const Component = showTime ? DateTimePicker : DatePicker;

  const handleFilterChange = (newValue) => {
    applyValue({ ...item, value: newValue });
  };

  return (
    <Component
      value={item.value || null}
      autoFocus
      label={apiRef.current.getLocaleText("filterPanelInputLabel")}
      slotProps={{
        textField: {
          variant: "standard",
        },
        inputAdornment: {
          sx: {
            "& .MuiButtonBase-root": {
              marginRight: -1,
            },
          },
        },
      }}
      onChange={handleFilterChange}
    />
  );
}

GridFilterDateInput.propTypes = {
  item: PropTypes.object.isRequired,
  showTime: PropTypes.bool.isRequired,
  applyValue: PropTypes.func.isRequired,
  apiRef: PropTypes.shape({
    current: PropTypes.shape({
      getLocaleText: PropTypes.func.isRequired,
    }),
  }).isRequired,
};

/**
 * `dateTime` column
 */

export const dateTimeColumnType = {
  ...GRID_DATETIME_COL_DEF,
  resizable: false,
  renderEditCell: (params) => {
    return <GridEditDateCell {...params} />;
  },
  filterOperators: getDateFilterOperators(true),
  valueFormatter: (params) => {
    if (typeof params.value === "string") {
      return params.value;
    }
    if (params.value) {
      return dateAdapter.format(params.value, "keyboardDateTime");
    }
    return "";
  },
};
