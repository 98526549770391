export const riskStudioColors = {
  primaryColor: "#009688",
  balanceSumBgColor: "#f5f5f5",
  editableCellBgColor: "rgba(217, 243, 190, 0.25)",
  editableCellBgColorDarkMode: "rgba(217, 243, 190, 0.35)",
  balanceSumTextColor: "#00796b",
  adjacentTextColor: "#FF9600",
  gridWrapperPrimaryColor: "#4ba79b",
  white: "white",
  black: "black",
  darkGray: "#616161",
  lightGray: "#eeeeee",
  gridBorderColor: "rgba(224, 224, 224, 1)",
  transparent: "transparent",
  gridGroupHeadingColor: "rgba(200, 230, 201, 0.5)",
  gridInsideColorLight: "rgba(232, 245, 233, 0.3)",
};
