const commonEmptyPosProps = {
  fontWeight: "normal",
  level: "",
  color: "transparent",
  fontSize: "1rem",
};
export const positionsForKpi = [
  {
    key: "empty-gap-view-1",
    ...commonEmptyPosProps,
  },
  {
    key: "empty-gap-view-2",
    ...commonEmptyPosProps,
  },
  {
    fontWeight: "bold",
    level: "",
    key: "gap_key-activa",
    color: "black",
    fontSize: "1rem",
  },
  {
    fontWeight: "bold",
    level: "",
    key: "gap_key-passiva",
    color: "black",
    fontSize: "1rem",
  },
  {
    fontWeight: "bold",
    level: "",
    key: "gap_key-guv",
    color: "black",
    fontSize: "1rem",
  },
  {
    fontWeight: "bold",
    level: "",
    key: "gap_key-kpis",
    color: "black",
    fontSize: "1rem",
  },
  {
    fontWeight: "bold",
    level: 1,
    key: "a_overview",
    color: "teal",
    name: "Billanzsumme (Aktiva)",
  },
  {
    fontWeight: "bold",
    level: 1,
    key: "gap-key-kpisub1",
    color: "black",
  },
  {
    fontWeight: "bold",
    level: 1,
    key: "gap-key-kpisub2",
    color: "black",
  },
  {
    fontWeight: "bold",
    level: 1,
    key: "gap-key-kpisub3",
    color: "black",
  },
  {
    fontWeight: "bold",
    level: 1,
    key: "gap-key-kpisub4",
    color: "black",
  },
  {
    fontWeight: "bold",
    level: 1,
    key: "a_a_overview",
  },
  {
    fontWeight: "normal",
    level: 0,
    key: "a_a_i_overview",
  },
  {
    fontWeight: "normal",
    level: 0,
    key: "a_a_ii_overview",
  },
  {
    fontWeight: "normal",
    level: 0,
    key: "a_a_iii_overview",
  },
  {
    fontWeight: "bold",
    level: 1,
    key: "a_b_overview",
  },
  {
    fontWeight: "normal",
    level: 0,
    key: "a_b_i_overview",
  },
  {
    fontWeight: "normal",
    level: 0,
    key: "a_b_ii_overview",
  },
  {
    fontWeight: "normal",
    level: 0,
    key: "a_b_iii_overview",
  },
  {
    fontWeight: "normal",
    level: 0,
    key: "a_b_iii_a_overview",
  },
  {
    fontWeight: "normal",
    level: 0,
    key: "a_b_iv_overview",
  },
  {
    fontWeight: "normal",
    level: 0,
    key: "a_b_v_overview",
  },
  {
    fontWeight: "bold",
    level: 1,
    key: "p_overview",
    color: "teal",
    name: "Billanzsumme (Passiva)",
  },
  {
    fontWeight: "bold",
    level: 1,
    key: "p_a_overview",
  },
  { fontWeight: "bold", level: 0, key: "p_a_i_overview" },
  {
    fontWeight: "normal",
    level: 2,
    key: "p_a_i_1_overview",
  },
  {
    fontWeight: "normal",
    level: 2,
    key: "p_a_i_2_overview",
  },
  {
    fontWeight: "normal",
    level: 2,
    key: "p_a_i_3_overview",
  },
  {
    fontWeight: "normal",
    level: 0,
    key: "p_a_ii_overview",
  },
  {
    fontWeight: "normal",
    level: 0,
    key: "p_a_iii_overview",
  },
  {
    fontWeight: "bold",
    level: 1,
    key: "p_b_overview",
  },
  {
    fontWeight: "normal",
    level: 0,
    key: "p_b_i_overview",
  },
  {
    fontWeight: "normal",
    level: 0,
    key: "p_b_ii_overview",
  },
  {
    fontWeight: "normal",
    level: 0,
    key: "p_b_iii_overview",
  },
  {
    fontWeight: "normal",
    level: 0,
    key: "p_b_iv_overview",
  },
  {
    fontWeight: "normal",
    level: 3,
    title: "davon gegenüber verbundener Unternehmen",
    name: "davon gegenüber verbundener Unternehmen",

    key: "p_b_iv_1_overview",
  },
  {
    fontWeight: "bold",
    level: 1,
    key: "pl_2_overview",
  },
  {
    fontWeight: "normal",
    level: 0,
    key: "pl_2_a_overview",
  },
  {
    fontWeight: "normal",
    level: 0,
    key: "pl_1_overview",
  },
  {
    fontWeight: "normal",
    level: 0,
    key: "pl_5_overview",
  },
  { fontWeight: "bold", level: 1, key: "pl_3_overview" },
  {
    fontWeight: "normal",
    level: 0,
    key: "pl_3_a_overview",
  },
  {
    fontWeight: "normal",
    level: 0,
    key: "pl_3_b_overview",
  },
  {
    fontWeight: "normal",
    level: 2,
    key: "pl_3_b_i_overview",
  },
  {
    fontWeight: "normal",
    level: 2,
    key: "pl_3_b_ii_overview",
  },
  {
    fontWeight: "normal",
    level: 3,
    title: "davon operative Leasingaufwendungen",
    name: "davon operative Leasingaufwendungen",
    key: "pl_3_b_iii_overview",
  },
  {
    fontWeight: "normal",
    level: 3,
    title: "davon Werbe- und Reisekosten (Marketing)",
    name: "davon Werbe- und Reisekosten (Marketing)",
    key: "pl_3_b_iv_overview",
  },
  {
    fontWeight: "normal",
    level: 0,
    key: "pl_3_c_overview",
  },
  {
    fontWeight: "bold",
    level: 1,
    key: "pl_4_overview",
  },
  {
    fontWeight: "normal",
    level: 0,
    key: "pl_4_a_overview",
  },
  {
    fontWeight: "normal",
    level: 0,
    key: "pl_4_b_overview",
  },
  { fontWeight: "normal", level: 0, key: "pl_4_c_overview" },
  {
    fontWeight: "bold",
    level: 1,
    key: "pl_6_overview",
  },
  { fontWeight: "bold", level: 1, key: "pl_7_overview" },
  {
    fontWeight: "normal",
    level: 0,
    color: "#757575",
    key: "pl_8_overview",
  },
  {
    fontWeight: "normal",
    level: 0,
    color: "#757575",
    key: "pl_9_overview",
  },
  {
    fontWeight: "normal",
    level: 0,
    key: "cur",
    title: "Verhältnis kurzfristiges Vermögen/Kurzfristige Verbindlichkeiten",
  },
  {
    fontWeight: "normal",
    level: 0,
    key: "netwc",
  },
  {
    fontWeight: "normal",
    level: 0,
    key: "netwcop",
  },
  {
    fontWeight: "normal",
    level: 0,
    key: "dos",
  },
  {
    fontWeight: "normal",
    level: 0,
    key: "dop",
  },
  {
    fontWeight: "normal",
    level: 0,
    key: "doi",
  },
  { fontWeight: "normal", level: 0, key: "ccc" },
  { fontWeight: "normal", level: 0, key: "eqr" },
  { fontWeight: "normal", level: 0, key: "dtoe" },
  {
    fontWeight: "normal",
    level: 0,
    key: "isgm",
  },
  { fontWeight: "normal", level: 0, key: "ebitdamargin" },
  { fontWeight: "normal", level: 0, key: "ebitmargin" },
];

export const kpiKeys = [
  "cur",
  "ebitmargin",
  "ebitdamargin",
  "isgm",
  "dtoe",
  "eqr",
  "ccc",
  "doi",
  "dop",
  "dos",
  "netwc",
  "netwcop",
  "cur",
];
