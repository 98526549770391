import React from "react";
import { GetIcon } from "./FlagComponentUtils";
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
  Box,
} from "@mui/material";
import PropTypes from "prop-types";

// MAIN COMPONENT ===========>
const CommentEditBox = ({
  params,
  setCachedSelectedValue,
  setCachedComment,
}) => {
  // STATE VARIABLES ===========>
  // Value is used to store the selected value of the flag for different rows
  const [value, setValue] = React.useState(params.row.value);
  // TextValue is used to store the comment of the flag for different rows
  const [textValue, setTextValue] = React.useState(params.row.comment);
  // SIDE EFFECTS ===========>
  // Update the cachedSelectedValue and cachedComment when the value or textValue changes
  React.useEffect(() => {
    setCachedSelectedValue((prevValue) => {
      return {
        ...prevValue,
        [params.row.id]: value,
      };
    });
    setCachedComment((prevValue) => {
      return {
        ...prevValue,
        [params.row.id]: textValue,
      };
    });
  }, [params.row.id, setCachedComment, setCachedSelectedValue, textValue, value]);

  // RETURN STATEMENT ===========>
  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <FormControl component="fieldset">
        {/* <FormLabel component="legend">Select Value</FormLabel> */}
        <RadioGroup
          row={true} // Makes the radio buttons appear in a row
          aria-label="choose a value"
          name="customized-radios"
          value={value}
          onChange={(e) => {
            setValue(e.target.value);
          }}
        >
          {params.row?.valid_values.map((item, index) => (
            <FormControlLabel
              sx={{
                borderRadius: "5px",
                paddingRight: "5px",
                marginRight: "1.5rem",
                backgroundColor: "rgba(232, 245, 233, 0.4)"
              }}
              data-testid={`radio-${item}`}
              key={`radio-flag-${index + 1}`}
              value={item}
              control={<Radio size="small" />}
              label={
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  {GetIcon(item)}
                </Box>
              }
            />
          ))}
        </RadioGroup>
      </FormControl>
      <TextField
        sx={{
          width: "90%",
        }}
        multiline={true}
        maxRows={4}
        margin="normal"
        label="Begründung"
        value={textValue}
        onChange={(e) => {
          setTextValue(e.target.value);
        }}
      />
    </Box>
  );
};

CommentEditBox.propTypes = {
  params: PropTypes.object,
  setCachedSelectedValue: PropTypes.func,
  setCachedComment: PropTypes.func,
};

export default CommentEditBox;
