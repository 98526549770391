import React, { useState } from "react";

import {
  DialogActions,
  Dialog,
  Radio,
  FormControlLabel,
  RadioGroup,
  Button,
  DialogTitle,
} from "@mui/material";
import * as api from "../../api";
import { useParams } from "react-router-dom";
import { deleteTimeSlice } from "../../api";
import { MdDeleteForever } from "react-icons/md";
import { toast } from "sonner";

const DeleteDialog = ({ onDelete, onCancel }) => {
  const [open, setOpen] = useState(false);
  const [radioValue, setRadioValue] = useState("");
  const [options, setOptions] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [loading, setLoading] = useState(true);

  const { companyId } = useParams();

  const handleClickOpen = () => {
    api
      .fetchTimeSlices(companyId)
      .then((response) => {
        setOptions(
          response.data.map((item) => ({
            id: item.id,
            name: item.year_month,
          }))
        );
        setLoading(false);
      })
      .then(() => {
        setOpen(true);
      })
      .catch((error) => {
        console.error("Error:", error);
        // handle the error, maybe set an error state here
      });
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = () => {
    deleteTimeSlice(radioValue)
      .then(() => {
        setOpen(false);
        toast.success(
          "Time slice " + radioValue + " deleted successfully",
          "success"
        );
        if (onDelete) onDelete();
      })
      .catch((error) => {
        toast.error("Error deleting time slice " + radioValue, "error");
        console.error("Error:", error);
      });
  };

  return (
    <div>
      <Button
        size="small"
        variant="outlined"
        color="primary"
        data-testid="delete-slice-dialog-opener-btn"
        onClick={handleClickOpen}
      >
        <MdDeleteForever
          style={{ marginBottom: "3px", marginRight: "5px", fontSize: "1rem" }}
        />
        Delete
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Delete</DialogTitle>
        <RadioGroup
          value={radioValue}
          onChange={(e) => setRadioValue(e.target.value)}
        >
          {options.map((option) => (
            <FormControlLabel
              key={option.id}
              value={option.id}
              control={<Radio />}
              label={option.name}
            />
          ))}
        </RadioGroup>
        <DialogActions>
          <Button variant="outlined" color="primary" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            data-testid="delete-slice-action-btn"
            onClick={handleDelete}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default DeleteDialog;
