/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Box } from "@mui/material";
import { MaterialReactTable } from "material-react-table";

const SelectFilesForDocuments = ({
  columns,
  rows,
  setSelectedFiles,
  allreadySelectedFiles,
}) => {
  const [tableColumns, setTableColumns] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [rowSelection, setRowSelection] = useState({});
  useEffect(() => {
    // Handle the case when the dialog is opened for the first time and there are already selected files
    if (allreadySelectedFiles) {
      const rowSelection = {};
      allreadySelectedFiles.forEach((file) => {
        rowSelection[file.id] = true;
      });
      setRowSelection(rowSelection);
    }
  }, [allreadySelectedFiles]);
  // SIDE EFFECT: set rows and columns for the react table
  useEffect(() => {
    if (columns && rows) {
      setTableColumns(columns);
      setTableData(rows);
    }
  }, [columns, rows]);

  const handleSelection = (newValue) => {
    setRowSelection(newValue);
  };
  // SIDE EFFECT: set selected files when the row selection changes
  useEffect(() => {
    if (rowSelection) {
      const selectedFiles = Object.keys(rowSelection)
        .filter((key) => rowSelection[key])
        .map((id) => Number(id));
      setSelectedFiles(selectedFiles);
    }
  }, [rowSelection]);

  return (
    <Box>
      <MaterialReactTable
        columns={tableColumns}
        data={tableData}
        enableRowSelection
        onRowSelectionChange={handleSelection}
        state={{ rowSelection }}
        getRowId={(originalRow) => originalRow.id}
        enableMultiRowSelection
      />
    </Box>
  );
};
// propTypes declaration
SelectFilesForDocuments.propTypes = {
  columns: PropTypes.array.isRequired,
  rows: PropTypes.array.isRequired,
  setSelectedFiles: PropTypes.func.isRequired,
  allreadySelectedFiles: PropTypes.array,
};

export default SelectFilesForDocuments;
