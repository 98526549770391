import {MenuItem, Select} from "@mui/material";
import PropTypes from "prop-types";

export const SelectBaseTimeSlice = ({ timeSlices, selectedTimeSlice, onTimeSliceSelect }) => {

    return (
        <Select
            value={selectedTimeSlice}
            onChange={onTimeSliceSelect}
            displayEmpty
        >
            {timeSlices?.map((timeSlice) => (
                <MenuItem key={timeSlice.id} value={timeSlice.id}>
                    {timeSlice.year_month}
                </MenuItem>
            ))}
        </Select>
    );
};

SelectBaseTimeSlice.propTypes = {
    timeSlices: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number.isRequired,
        year_month: PropTypes.string.isRequired,
    })).isRequired,
    selectedTimeSlice: PropTypes.string.isRequired,
    onTimeSliceSelect: PropTypes.func.isRequired,
}
