import React from "react";
import PropTypes from "prop-types";
import ForecastPositionsDataGrid from "./ForecastPositionsDataGrid";
import { Box, Typography } from "@mui/material";
import { useFetchForecastScenarioPositions } from "../../hooks";
import { getConvertedRowForForcastGrid } from "./ForcastDataGridHelpers";

// This is the wrapper for the ForecastPositionsDataGrid
const ForcastDataGridWrapper = ({
  forecastScenarioId,
  positionType,
  columns,
  gridFormatterClient,
}) => {
  // Check if the position type is balanze summen or liquidity
  const isApSum = positionType === "ap_sum";
  // Fetch rows for the forecast scenario id and position type
  const {
    data: rows,
    isLoading,
    isError,
  } = useFetchForecastScenarioPositions(forecastScenarioId, positionType);

  return (
    <Box sx={{ width: "1180px" }}>
      <Box
        sx={
          gridFormatterClient?.getInputGridWrapperStyles(isApSum)
            .containerStyle || {}
        }
      >
        <Typography
          variant="h6"
          sx={
            gridFormatterClient?.getInputGridWrapperStyles(isApSum)
              .typographyStyle || {}
          }
        >
          {gridFormatterClient?.getGridName(positionType) || positionType}
        </Typography>
      </Box>
      <ForecastPositionsDataGrid
        forecastScenarioId={forecastScenarioId}
        columns={columns || []}
        rows={getConvertedRowForForcastGrid(rows , positionType) || []}
        isLoading={isLoading}
        isError={isError}
        gridFormatterClient={gridFormatterClient}
      />
    </Box>
  );
};

// PROPS DECLARATION
ForcastDataGridWrapper.propTypes = {
  forecastScenarioId: PropTypes.string.isRequired,
  positionType: PropTypes.oneOf([
    "ap_sum",
    "liquidity",
    "activa",
    "passiva",
    "other",
    "pl",
  ]).isRequired,
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
  gridFormatterClient: PropTypes.shape({
    getInputGridWrapperStyles: PropTypes.func,
    getGridName: PropTypes.func,
  }).isRequired,
};
export default ForcastDataGridWrapper;
