import React, { useState, useImperativeHandle, forwardRef } from 'react';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@mui/material';
import PropTypes from 'prop-types';

const ConfirmDialog = forwardRef(({ onAction ,title}, ref) => {
    const [open, setOpen] = useState(false);
    const [actionId, setActionId] = useState(null);

    const show = (id) => {
        setActionId(id);
        setOpen(true);
    };

    useImperativeHandle(ref, () => ({
        show,
    }));

    const handleConfirmDelete = () => {
        if (onAction && actionId !== null) {
            onAction(actionId);
        }
        setOpen(false);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Sind sie sicher? Die Aktion kann nicht rückgängig gemacht werden.
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">Abbrechen</Button>
                <Button onClick={handleConfirmDelete} variant={'contained'} color="primary">Bestätigen</Button>
            </DialogActions>
        </Dialog>
    );
});

ConfirmDialog.propTypes = {
    onAction: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
};

export default ConfirmDialog;
