import React from "react";
import { Box } from "@mui/material";
import CallTranscript from "../CallTranscript";
import PropTypes from "prop-types";

const TranscriptView = ({ viewMode, selectedDdCallId }) => {
  return (
    ["transcript", "both"].includes(viewMode) && (
      <Box
        sx={{
          width: viewMode === "both" ? "50%" : "100%",
          margin: "4px",
          height: "100vh",
          overflow: "auto",
        }}
      >
        <CallTranscript ddCallId={selectedDdCallId} />
      </Box>
    )
  );
};

export default TranscriptView;

TranscriptView.propTypes = {
  viewMode: PropTypes.string.isRequired,
  selectedDdCallId: PropTypes.string.isRequired,
};
