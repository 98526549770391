// api.js

import axios from "axios";
import { msalInstance } from "./index";
import { toast } from "sonner";
import { getCurrentYearMonth } from "./utils/dateUtils";

axios.defaults.baseURL = process.env.REACT_APP_API_HOST;
const axiosAuthMiddlewareFunc = async (config) => {
  try {
    const account = msalInstance.getAllAccounts()[0];
    if (account) {
      const request = {
        scopes: [process.env.REACT_APP_AZURE_SCOPES], // Replace with your API's scope
        account: account,
      };
      try {
        const tokenResponse = await msalInstance.acquireTokenSilent(request);
        config.headers["Authorization"] = "Bearer " + tokenResponse.accessToken;
      } catch (error) {
        try {
          const tokenResponse = await msalInstance.acquireTokenPopup(request);
          config.headers["Authorization"] =
            "Bearer " + tokenResponse.accessToken;
        } catch (innerError) {
          toast.error("Error acquiring token:", innerError);
          console.error(innerError);
        }
      }
    }
    return config;
  } catch (error) {
    // Handle token acquisition errors
    return Promise.reject(error);
  }
};
axios.interceptors.request.use(axiosAuthMiddlewareFunc, (error) => {
  return Promise.reject(error);
});

// GET ALL

export const fetchTimeSlices = (companyId, timeSliceType) => {
  // get all available timeslices
  const baseUrl = `/timeslices?company_id=${companyId}`;
  const url = timeSliceType ? `${baseUrl}&time_slice_type=${timeSliceType}` : baseUrl;
  return axios.get(url);
};

export const fetchTimeSliceGroups = (companyId) => {
  // get all available timeslices
  return axios.get(`/timeslicegroups?company_id=${companyId}`);
};

export const fetchPositions = (position_type) => {
  // get all available positions
  const url = position_type
    ? `/positions?position_type=${position_type}`
    : "/positions";
  return axios.get(url);
};

export const fetchProforma = () => {
  // get all available positions
  return axios.get("/proforma");
};

export const fetchPositionsForTimeSlices = () => {
  // get all positions for all timeslices
  return axios.get("/timeslices/positions");
};

// GET LATEST GROUP

export const fetchLatestTimeSliceGroup = () => {
  // get the id of the latest timeslice group
  return axios.get("/timeslicegroups/latest");
};

// GET SPECIFIC GROUP

export const fetchTimeSlicesFromGroup = (groupId, reverse = true) => {
  // get all available timeslices for this specific timeslice group
  return axios.get(`/timeslicegroups/${groupId}/timeslices?reverse=${reverse}`);
};

export const fetchPositionsFromGroup = (
  groupId,
  position_type,
  borrowerGroupId
) => {
  const url = `/timeslicegroups/${groupId}/positions`;

  // Initialize the params object
  const params = {};

  // Add the optional position_type if it's not null or undefined
  if (position_type != null) {
    params.position_type = position_type;
  }

  // Add the optional borrower_group_id if it's not null or undefined
  if (borrowerGroupId != null) {
    params.borrower_group_id = borrowerGroupId;
  }

  return axios.get(url, { params });
};

export const fetchKpisFromGroup = (groupId, borrowerGroupId) => {
  // get all available positions for this specific timeslice group

  // Initialize the params object
  const params = {};

  // Add the optional borrower_group_id if it's not null or undefined
  if (borrowerGroupId != null) {
    params.borrower_group_id = borrowerGroupId;
  }

  return axios.get(`/timeslicegroups/${groupId}/kpis`, { params });
};

export const fetchRatingsFromGroup = (groupId, borrowerGroupId) => {
  // get all available positions for this specific timeslice group

  // Initialize the params object
  const params = {};

  // Add the optional borrower_group_id if it's not null or undefined
  if (borrowerGroupId != null) {
    params.borrower_group_id = borrowerGroupId;
  }

  return axios.get(`/timeslicegroups/${groupId}/rating`, { params });
};

export const fetchFlagsFromCompany = async (
  companyId,
  groupId,
  stage,
  borrowerGroupId
) => {
  // get all available positions for this specific timeslice group

  // Initialize the params object
  const params = { time_slice_group_id: groupId, stage: stage };

  // Add the optional borrower_group_id if it's not null or undefined
  if (borrowerGroupId != null) {
    params.borrower_group_id = borrowerGroupId;
  }

  try {
    return await axios.get(`/companies/${companyId}/flags`, { params });
  } catch (error) {
    console.error(error);
    throw error;
  }
};

// GET PROFORMA FOR TIMESLICE

export const fetchProformaFromTimeslice = (timeSliceId) => {
  // get all available positions for this specific timeslice group
  return axios.get(`/timeslice/${timeSliceId}/proforma`);
};

// GET EXCEL FOR TIMESLICEGROUP

export const fetchRiskModelExcelFromGroup = async (
  groupId,
  borrowerGroupId,
  companyName
) => {
  try {
    // get all available positions for this specific timeslice group
    const params = {};

    // Add the optional borrower_group_id if it's not null or undefined
    if (borrowerGroupId != null) {
      params.borrower_group_id = borrowerGroupId;
    }
    const response = await axios.get(`/export/risk-model/${groupId}/excel`, {
      responseType: "blob",
      headers: {
        Accept: "application/vnd.ms-excel.sheet.macroEnabled.12",
      },
      params: params,
    });

    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");

    const fileName = `${companyName}_RM_FinalOpinion_${getCurrentYearMonth()}.xlsm`;
    link.href = url;
    link.setAttribute("download", fileName);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
  } catch (error) {
    console.error("Error downloading the file:", error);
  }
};

export const fetchCrefoData = (companyId) => {
  return axios.get(`/companies/${companyId}/crefo`);
};

export const fetchIndustryData = (companyId) => {
  return axios.get(`/companies/${companyId}/industry`);
};
// Company Fetch
export const fetchCompany = async (companyId) => {
  try {
    const res = await axios.get(`/companies/${companyId}`);
    return res.data;
  } catch (err) {
    if (err.response.status === 404) {
      return null;
    }
    throw err;
  }
};
export const updateCompanyData = async (companyId, data) => {
  try {
    const res = await axios.patch(`/companies/${companyId}`, data);
    return res.data;
  } catch (err) {
    console.error(err);
    throw err;
  }
};
// Borrower Group

export const fetchBorrowerGroup = async (borrowerGroupId) => {
  try {
    return await axios.get(`/borrower-group/${borrowerGroupId}`);
  } catch (error) {
    return null;
  }
};

export const fetchBorrowerGroupsPerCompany = async (companyId) => {
  try {
    return await axios.get(`/companies/${companyId}/borrower-group`);
  } catch (error) {
    return null;
  }
};

export const addCompanyToBorrowerGroup = (borrowerGroupId, companyId) => {
  return axios.post(`/borrower-group/${borrowerGroupId}/company/${companyId}`);
};

export const createBorrowerGroup = (
  companyId,
  timeSliceGroupId,
  borrowerGroupName
) => {
  return axios.post("/borrower-group", {
    name: borrowerGroupName,
    head_company_id: companyId,
    time_slice_group_id: timeSliceGroupId,
  });
};
// UPDATE

export const putPosition = (column_id, row_id, value) => {
  // update a column / row with a  value.
  return axios.put(`/timeslice/${column_id}/positions`, {
    id: row_id, //cell.row.index + 1,
    value: value,
  });
};

export const putProforma = (timeSliceId, proformaData) => {
  return axios.put(`/timeslice/${timeSliceId}/proforma`, proformaData);
};

// CREATE

export const postProforma = (timeSliceId, proformaData) => {
  return axios.post(`/timeslice/${timeSliceId}/proforma`, proformaData);
};

export const postTimeSliceGroup = async (data) => {
  return axios.post(`/timeslicegroups`, data);
};

export const postTimeSlice = (data) => {
  return axios.post(`/timeslice`, data);
};

export const postTimeSliceCSV = async (data) => {
  try {
    return await axios.post("/timeslice/manual_annual_report_csv_file", data);
  } catch (err) {
    console.error(err);
    throw err;
  }
};

export const updateFlags = (companyId, flag_id, data) => {
  return axios.put(`/companies/${companyId}/flags/${flag_id}`, data);
};

// FETCH DOCUMENTS
export const fetchDocumentWithFileType = async (companyId, fileType) => {
  try {
    const res = await axios.get(
      `/documents/${companyId}?query_type=${fileType}`
    );
    return res.data;
  } catch (err) {
    console.error(err);
    return err.response.data.detail;
  }
};

export const searchCompanies = (query, limit = 10) => {
  return axios.get(`/companies/search`, {
    params: { query: query, limit: limit },
  });
};

// DELETE

export const deleteTimeSlice = async (companyId) => {
  try {
    return await axios.delete(`/timeslice/${companyId}`);
  } catch (err) {
    console.log(err);
    throw err;
  }
};

// FINAL OPINION ENDPOINTS

export const fetchFinalOpinion = async (creditProjectId) => {
  try {
    const res = await axios.get(`/finalopinion/${creditProjectId}`);
    return res.data;
  } catch (err) {
    return err.response.data.detail;
  }
};

export const putFinalOpinionDataPoint = async (
  creditProjectId,
  key,
  keyObject
) => {
  try {
    const res = await axios.put(
      `/finalopinion/${creditProjectId}/data_point/${key}`,
      {
        content: keyObject.content,
        number: keyObject.number,
        hidden: keyObject.hidden,
      }
    );
    return res.data;
  } catch (err) {
    return err.response.data.detail;
  }
};
// CREDIT PROJECT ENDPOINTS
export const fetchLatestCreditProject = async (companyId) => {
  try {
    const res = await axios.get(
      `/companies/${companyId}/latest_credit_project`
    );
    return res.data;
  } catch (err) {
    return err.response.data.detail;
  }
};

export const fetchAllCreditProjects = async (companyId) => {
  const res = await axios.get(`/companies/${companyId}/credit_projects`);
  return res.data;
};

export const fetchLoadConditionPositions = async () => {
  const res = await axios.get(`/loan_conditions`);
  return res.data;
};

export const fetchLoanConditionForCP = async (creditProjectId) => {
  const res = await axios.get(
    `/credit_projects/${creditProjectId}/loan_conditions`
  );
  return res.data;
};

export const postLoanConditionForCP = async (creditProjectId, data) => {
  const res = await axios.post(
    `/credit_projects/${creditProjectId}/loan_conditions`,
    data
  );
  return res.data;
};

export const deleteLoanConditionForCP = (loanConditionId) => {
  return axios.delete(`/loan_conditions/${loanConditionId}`);
};

export const updateLoadConditionForCP = (loanConditionId, data) => {
  return axios.put(`/loan_conditions/${loanConditionId}`, data);
};

// TIMESLICE DOCUMENTS

export const getTimeSliceDocuments = async (timeSliceId) => {
  try {
    const res = await axios.get(`/timeslice/${timeSliceId}/documents`);
    return res.data;
  } catch (err) {
    console.error(err);
    return err.response.data.detail;
  }
};
export const updateTimeSliceDocuments = async (timeSliceId, payload) => {
  try {
    const res = await axios.put(`/timeslice/${timeSliceId}/documents`, {
      document_ids: payload,
    });
    return {
      data: res.data,
      status: res.status,
    };
  } catch (err) {
    console.error(err);
    throw err.response.data.detail[0].msg;
  }
};

export const fetchNewerTimeSlices = async (companyId) => {
  try {
    const res = await axios.get(
      `/companies/${companyId}/get_newer_time_slices`
    );
    if (res.data) {
      return res.data;
    }
  } catch (err) {
    console.error(err);
    return err.response.data.detail;
  }
};

export const getDDCalls = async (companyId) => {
  try {
    const res = await axios.get(`/companies/${companyId}/dd_calls`);
    return res.data;
  } catch (err) {
    console.error(err);
    return err.response.data.detail;
  }
};

export const postQaForDdCall = async ({ ddCallId, data }) => {
  try {
    const res = await axios.post(`/dd_calls/${ddCallId}/question_answer`, data);
    toast.success("Frage erstellt");
    return res.data;
  } catch (err) {
    toast.error("Fehler beim Erstellen der Frage", err.response.data.detail);
    return err.response.data.detail;
  }
};

export const getQaForDdCall = async (ddCallId) => {
  if (!ddCallId) {
    return [];
  }
  try {
    const res = await axios.get(`/dd_calls/${ddCallId}/question_answer`);
    return res.data;
  } catch (err) {
    toast.error("Fehler beim Laden der Fragen", err.response.data.detail);
    return err.response.data.detail;
  }
};

export const patchQuestionAnswer = async ({ qaId, data }) => {
  try {
    const res = await axios.patch(`/question_answer/${qaId}`, data);
    toast.success("Frage aktualisiert");
    return res.data;
  } catch (err) {
    toast.error(
      "Fehler beim Aktualisieren der Frage",
      err.response.data.detail
    );
    return err.response.data.detail;
  }
};

export const postDdCall = async ({ data }) => {
  try {
    const res = await axios.post(`/dd_calls`, data);
    toast.success("DD Call erstellt");
    return res.data;
  } catch (err) {
    toast.error("Fehler beim Erstellen des DD Calls", err.response.data.detail);
    return err.response.data.detail;
  }
};

export const fetchDDCallTemplates = async () => {
  try {
    const res = await axios.get(`/dd_call_templates`);
    return res.data;
  } catch (err) {
    toast.error(
      "Fehler beim Laden der DD Call Templates",
      err.response.data.detail
    );
    return err.response.data.detail;
  }
};

export const deleteQA = async ({ qaId }) => {
  try {
    const res = await axios.delete(`/question_answer/${qaId}`);
    toast.success("Frage gelöscht");
    return res.data;
  } catch (err) {
    toast.error("Fehler beim Löschen der Frage", err.response.data.detail);
    return err.response.data.detail;
  }
};

export const patchAnswerQuestionsWithLLM = async ({ ddCallId, data }) => {
  const res = await axios.patch(
    `/dd_calls/${ddCallId}/question_answer/answer_with_llm`,
    data
  );
  return res.data;
};

export const fetchDDCallTranscript = async (ddCallId, documentId) => {
  if (!ddCallId) {
    return [];
  }
  try {
    const res = await axios.get(`/dd_calls/${ddCallId}/transcript`);
    return res.data;
  } catch (err) {
    toast.error(
      "Fehler beim Laden des DD Call Transcripts",
      err.response.data.detail
    );
    return err.response.data.detail;
  }
};

export const addTranscriptToDDCall = async ({ ddCallId, documentId }) => {
  try {
    const res = await axios.post(
      `/dd_calls/${ddCallId}/transcript/${documentId}`
    );
    toast.success("Transcript hinzugefügt");
    return res.data;
  } catch (err) {
    toast.error("Fehler beim Hinzufügen des Transcripts", {
      description: err.response.data.detail,
    });
    return err.response.data.detail;
  }
};

export const fetchTranscriptSpeaker = async (ddCallId) => {
  if (!ddCallId) {
    return [];
  }
  try {
    const res = await axios.get(`/dd_calls/${ddCallId}/transcript/speaker`);
    return res.data;
  } catch (err) {
    toast.error(
      "Fehler beim Laden der DD Call Transcript Speakers",
      err.response.data.detail
    );
    return err.response.data.detail;
  }
};

export const patchTranscriptSpeaker = async ({ ddCallId, data }) => {
  try {
    const res = await axios.patch(
      `/dd_calls/${ddCallId}/transcript/speaker`,
      data
    );
    toast.success("Speaker aktualisiert");
    return res.data;
  } catch (err) {
    toast.error("Fehler beim Aktualisieren des Speakers");
    return err.response.data.detail;
  }
};
export const updateTranscriptForDDCall = async (transcriptId, data) => {
  // Update single transcript for a DD Call
  try {
    const res = await axios.patch(`/transcript/${transcriptId}`, data);
    return res.data;
  } catch (err) {
    console.error(err);
    throw err;
  }
};
// Gen ai endpoints
export const fetchAiMessageThread = async (
  companyId,
  creditProjectId,
  context
) => {
  const res = await axios.get(`/agent/messages`, {
    params: {
      company_id: companyId,
      credit_project_id: creditProjectId,
      context: context,
    },
  });
  return res.data;
};

export const postAiQuery = async (
  companyId,
  creditProjectId,
  context,
  question
) => {
  const res = await axios.post(
    `/agent/invoke`,
    {
      input: {
        input: question,
      },
    },

    {
      params: {
        company_id: companyId,
        credit_project_id: creditProjectId,
        context: context,
      },
    }

, {params:{company_id: companyId, credit_project_id: creditProjectId, context: context}}
)
return res.data;
}


export const fetchQuery = async (assistantId, threadId, runId) => {
  const res = await axios.get(`/query`, {
    params: {
      assistant_id: assistantId,
      thread_id: threadId,
      run_id: runId,
    },
  });
  return res;
};

export const fetchForecastScenarios = async (timeSliceId) => {
  if (!timeSliceId || timeSliceId === "undefined" || timeSliceId === "null") {
    return [];
  }
  const res = await axios.get(`/timeslice/${timeSliceId}/forecast_scenarios`);
  return res.data;
};

export const postForecastScenario = async (data) => {
  const res = await axios.post(`/forecast_scenario`, data);
  return res.data;
};

export const updateForecastScenario = async (forecastScenarioId, data) => {
  const res = await axios.patch(
    `/forecast_scenario/${forecastScenarioId}`,
    data
  );
  return res.data;
};

export const deleteForecastScenario = async (forecastScenarioId) => {
  const res = await axios.delete(`/forecast_scenario/${forecastScenarioId}`);
  return res.data;
};

export const fetchForecastScenarioPositions = async (
  forecastScenarioId,
  positionType = ""
) => {
  if (!forecastScenarioId) {
    return [];
  }
  const url = positionType
    ? `/forecast_scenario/${forecastScenarioId}/positions?position_type=${positionType}`
    : `/forecast_scenario/${forecastScenarioId}/positions`;
  const res = await axios.get(url);
  return res.data;
};

export const fetchForecastScenarioForecastTimeSlices = async (
  forecastScenarioId
) => {
  const res = await axios.get(
    `/forecast_scenario/${forecastScenarioId}/forecast_time_slices`
  );
  return res.data;
};

export const putForecastTimeSlicePositions = async (
  forecastTimeSliceId,
  data
) => {
  const res = await axios.put(
    `/forecast_time_slice/${forecastTimeSliceId}/positions`,
    data
  );
  return res.data;
};

export const fetchTimeSliceGroup = async (groupId) => {
  const res = await axios.get(`timeslicegroups/${groupId}`);
  return res.data;
};
export const putTimeSliceGroupsName = async (groupId, data) => {
  return await axios.put(`/timeslicegroups/${groupId}`, data);
};

export const createLiabilities = async (companyId, data) => {
  const res = await axios.post(`/companies/${companyId}/liabilities`, data);
  return res.data;
};

export const fetchLiabilities = async (companyId) => {
  const res = await axios.get(`/companies/${companyId}/liabilities`);
  return res.data;
};

export const deleteLiabilities = async (companyId, liabilitiesId) => {
  const res = await axios.delete(
    `/companies/${companyId}/liabilities/${liabilitiesId}`
  );
  return res.data;
};

export const updateLiabilities = async (companyId, liabilitiesId, data) => {
  const res = await axios.put(
    `/companies/${companyId}/liabilities/${liabilitiesId}`,
    data
  );
  return res.data;
};

export const fetchCrefoDataFromReport = async (reportId) => {
  const res = await axios.get(`/crefo/${reportId}/data`);
  return res.data;
};

// COMMENTS
export const postCommentForPosition = async (timeSliceId, data) => {
  const res = await axios.post(`/timeslice/${timeSliceId}/comments`, data);
  return res.data;
};
export const updateCommentForPosition = async (commentId, data) => {
  const res = await axios.put(`/comment/${commentId}`, data);
  return res.data;
};

export const fetchAllCommentsByGroup = async (groupId) => {
  if (!groupId) {
    return [];
  }
  const res = await axios.get(`/timeslicegroups/${groupId}/comments`);
  return res.data;
};
export const fetchAllCommentsWithTimeSlice = async (timeSliceId) => {
  const res = await axios.get(`/timeslice/${timeSliceId}/comments`);
  return {
    time_slice_id: timeSliceId,
    comments: res.data,
  };
};

export const validateExcelFile = async(companyId, documentId) => {
  if (!companyId || !documentId) {
        return [];
  }
    const res = await axios.post(`/companies/${companyId}/validate_excel_file?document_id=${documentId}`);
    return res.data;
}

export const importFromExcelFile = async(companyId, documentId, validationResponse) => {
    if (!companyId || !documentId) {
            return [];
    }
        const res = await axios.post(`/companies/${companyId}/risk_excel_file?document_id=${documentId}`, validationResponse);
        return res.data;
}

export const fetchQualitativeRatings = async (companyId) => {
  const res = await axios.get(`/companies/${companyId}/qualitative_ratings`);
  return res.data;
};

