import { useCallback } from "react";
import {
  evaluateFormula,
  findDifferences,
} from "../EditDataGrid/UtilsForEditDataGrids";
import { putForecastTimeSlicePositions } from "../../api";

export const useMutation = () => {
  return useCallback(
    (updatedRow, originalRow) =>
      new Promise((resolve, reject) => {
        const diff = findDifferences(updatedRow, originalRow);
        const columnId = diff[0];
        const positionId = updatedRow.key;
        const updatedValue = evaluateFormula(updatedRow[diff[0]]);
        putForecastTimeSlicePositions(columnId, {
          id: positionId,
          value: updatedValue,
        })
          .then(() => {
            resolve({ ...updatedRow });
          })
          .catch((error) => {
            reject(error);
          });
      }),
    []
  );
};
