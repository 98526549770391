import React, { useState, useEffect } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import ScienceIcon from '@mui/icons-material/Science';
import { MdGroup } from "react-icons/md";
import { DataGrid } from "@mui/x-data-grid";
import { fetchBorrowerGroupsPerCompany, createBorrowerGroup } from "../../api";
import {
  useParams,
  useNavigate,
  createSearchParams,
} from "react-router-dom";
import UpdateBorrowerGroup from "./UpdateBorrowerGroup";
import {DialogManager} from "../TimeSliceGroupDialog/DialogManager";

function BorrowerGroupDialog() {
  const [open, setOpen] = useState(false);
  const [rows, setRows] = useState([]);
  const [rowSelectionModel, setRowSelectionModel] = useState([]);
  const [showUpdateDialog, setShowUpdateDialog] = useState(false);
  const [showCreateDialog, setShowCreateDialog] = useState(false);
  const [selectedBorrowerGroupId, setSelectedBorrowerGroupId] = useState(null);

  const { companyId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (open) {
      // Fetch borrower_group_id from the URL
      const searchParams = createSearchParams(window.location.search);
      const borrowerGroupIdFromUrl = searchParams.get("borrower_group_id");
      if (borrowerGroupIdFromUrl) {
        setRowSelectionModel([borrowerGroupIdFromUrl]);
      }

      // Fetch data
      fetchBorrowerGroupsPerCompany(companyId).then((response) => {
        setRows(response.data);
      });
    }
  }, [open, companyId]);

  const handleClose = () => setOpen(false);
  const handleClickOpen = () => setOpen(true);

  const handleSelect = () => {
    if (rowSelectionModel.length > 0) {
      const params = new URLSearchParams(window.location.search);
      params.set("borrower_group_id", rowSelectionModel[0]);
      navigate({ search: params.toString() });
    } else {
      const params = new URLSearchParams(window.location.search);
      params.delete("borrower_group_id");
      navigate({ search: params.toString() });
    }
    setOpen(false);
  };

  const handleRowSelection = (newRowSelectionModel) => {
    setRowSelectionModel(newRowSelectionModel);
  };

  const handleCreate = () => {
    setShowCreateDialog(true);
  }

  const handleCreateBorrowerGroup= (timeSliceGroupId, timeSliceList) => {
    const keys = Object.keys(timeSliceGroupId);
    const key = keys[0];
    const borrowerGroupName = timeSliceList.find(obj => obj.id === parseInt(key)).name
    createBorrowerGroup(companyId, key, borrowerGroupName).then(()  =>
    {
      fetchBorrowerGroupsPerCompany(companyId).then((response) => {
        setRows(response.data);
      });
    })
    setShowCreateDialog(false);
  }

  const closeUpdateDialog = () => setShowUpdateDialog(false);
  const closeCreateDialog = () => setShowCreateDialog(false);

  const columns = [
    { field: "id", headerName: "ID", width: 70 },
    { field: "name", headerName: "Name", width: 300 },
    {
      field: "edit",
      headerName: "Edit",
      width: 100,
      renderCell: (params) => (
        <Button
          variant="contained"
          color="primary"
          size="small"
          onClick={() => {
            setSelectedBorrowerGroupId(params.row.id);
            setShowUpdateDialog(true);
          }}
        >
          Edit
        </Button>
      ),
    },
  ];


  return (
    <div>
      <Button
        variant="outlined"
        color="primary"
        onClick={handleClickOpen}
        size="small"
      >
        <MdGroup
          style={{ marginBottom: "3px", marginRight: "5px", fontSize: "1rem" }}
        />
        Borrower Group
        <ScienceIcon style={{ marginBottom: "3px", marginRight: "5px", fontSize: "1rem", color : "#B0BF1A"  }}/>
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Borrower Group</DialogTitle>
        <DialogContent>
          <DataGrid
            checkboxSelection
            disableRowSelectionOnClick
            columns={columns}
            rows={rows}
            disableMultipleSelection
            onRowSelectionModelChange={handleRowSelection}
            rowSelectionModel={rowSelectionModel}
          />
          <Button variant="contained" color="primary" sx={{marginTop:"1rem", alignContent: "right"}} onClick={handleCreate}>
            Create New
          </Button>

        </DialogContent>
        <DialogActions>

          <Button variant="outlined" color="primary" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="contained" color="primary" onClick={handleSelect}>
            Select
          </Button>
        </DialogActions>
      </Dialog>
      {showUpdateDialog && (
        <UpdateBorrowerGroup
          borrowerGroupId={selectedBorrowerGroupId}
          onClose={closeUpdateDialog}
        />
      )}
      {showCreateDialog && (
          <DialogManager companyId={companyId} onClose={closeCreateDialog} open={showCreateDialog} handleCreate={handleCreateBorrowerGroup}>

          </DialogManager>
      )}
    </div>
  );
}

export default BorrowerGroupDialog;
