import * as React from "react";
import PropTypes from "prop-types";
import { ActionButton } from "./ActionButton";
import { ActionsDropdown } from "./ActionsDropdown";
import { Box } from "@mui/system";

export const CrefoDropdown = ({ dropDownListActions, dropdownText }) => {
  return (
    <Box>
      {Array.isArray(dropDownListActions) ? (
        <ActionsDropdown
          stepActions={dropDownListActions}
          dropdownText={dropdownText}
        />
      ) : (
        <ActionButton stepAction={dropDownListActions} />
      )}
    </Box>
  );
};

CrefoDropdown.propTypes = {
  dropDownListActions: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({
        onClick: PropTypes.func.isRequired,
        href: PropTypes.string,
        text: PropTypes.string.isRequired,
      })
    ),
    PropTypes.shape({
      onClick: PropTypes.func.isRequired,
      href: PropTypes.string,
      text: PropTypes.string.isRequired,
    }),
  ]).isRequired,
  dropdownText: PropTypes.string,
};