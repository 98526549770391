import React, { useEffect, useState } from "react";
import {useLocation, useParams} from "react-router-dom";
import { Layout } from "../components/Layout/Layout";
import RatingBadgeComponent from "../components/rating/RatingBadgeComponent";
import RatingTableComponent from "../components/rating/RatingTableComponent";
import IndustryComponent from "../components/IndustryComponent/IndustryComponent";

const RatingDetails = () => {
  const [timeSliceGroupId, setTimeSliceGroupId] = useState(null);
  const [borrowerGroupId, setBorrowerGroupId] = useState(null);
  const location = useLocation();
  const {companyId} = useParams();
  const [refreshData, setRefreshData] = useState(false);

  const getUrlParams = () => {
    const params = new URLSearchParams(window.location.search);
    setTimeSliceGroupId(params.get("time_slice_group_id"));
    const borrowerGroupIdParam = params.get("borrower_group_id");
    if (borrowerGroupIdParam !== "null" && borrowerGroupIdParam !== null) {
      const parsedBorrowerGroupId = parseInt(borrowerGroupIdParam);
      if (Number.isInteger(parsedBorrowerGroupId)) {
        setBorrowerGroupId(parsedBorrowerGroupId);
      }
    }
  };

  useEffect(() => {
    getUrlParams();
    console.log("location", location)
    setRefreshData((prevState) => !prevState);
  }, [location]);

  console.log("companyId", companyId)
  console.log("location", location)

  return (
    <Layout>
      <RatingBadgeComponent
        groupId={timeSliceGroupId}
        refreshData={refreshData}
        borrowerGroupId={borrowerGroupId}
        companyId={companyId}
      ></RatingBadgeComponent>
      <IndustryComponent refreshData={refreshData} />
      <RatingTableComponent
        groupId={timeSliceGroupId}
        refreshData={refreshData}
        borrowerGroupId={borrowerGroupId}
      ></RatingTableComponent>
    </Layout>
  );
};

export default RatingDetails;
