import { React, useState, useMemo } from "react";
import PropTypes from "prop-types";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import App from "./Router";
import { ThemeModeContext } from "./context/ThemeModeContext";

// MAIN COMPONENT
const AppWithProviders = ({ msalInstance }) => {
  // THEME STATE

  const [mode, setMode] = useState(
    localStorage.getItem("RS_THEME_MODE") || "light"
  );

  // THEME MODE TOGGLE FUNCTION
  const colorMode = useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode) => {
          const newMode = prevMode === "light" ? "dark" : "light";
          localStorage.setItem("RS_THEME_MODE", newMode);
          return newMode;
        });
      },
    }),
    []
  );
  // CREATE THEME
  const theme = useMemo(
    () =>
      createTheme({
        palette: {
          mode,
          primary: {
            main: "#009688",
          },
        },
      }),
    [mode]
  );
  return (
    <ThemeModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <App pca={msalInstance} />
      </ThemeProvider>
    </ThemeModeContext.Provider>
  );
};

AppWithProviders.propTypes = {
  msalInstance: PropTypes.object.isRequired,
};
export default AppWithProviders;
