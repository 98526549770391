import React from "react";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography,} from "@mui/material";
import {MaterialReactTable} from "material-react-table";

const TimeSliceGroupDialogForm = ({
                                      open,
                                      onClose,
                                      columns,
                                      data,
                                      rowSelection,
                                      onRowSelectionChange,
                                      errorMessage,
                                      onCreate,
                                  }) => (<Dialog open={open} onClose={onClose}>
        <DialogTitle>Select Data</DialogTitle>
        <DialogContent>
            <div>
                {errorMessage && <Typography color="error">{errorMessage}</Typography>}
            </div>
            <div>
                <MaterialReactTable
                    columns={columns}
                    data={data}
                    enableRowSelection
                    onRowSelectionChange={onRowSelectionChange}
                    state={{rowSelection}}
                    getRowId={(originalRow) => originalRow.id}
                    muiTableBodyRowProps={({row}) => ({
                        onClick: row.getToggleSelectedHandler(), sx: {cursor: "pointer"},
                    })}
                    enableMultiRowSelection={false}
                />
            </div>
        </DialogContent>
        <DialogActions>
            <Button variant="outlined" color="primary" onClick={onClose}>
                Cancel
            </Button>
            <Button variant="contained" color="primary" onClick={onCreate}>
                Select
            </Button>
        </DialogActions>
    </Dialog>);

export default TimeSliceGroupDialogForm;
