import React, {useEffect, useRef, useState} from "react";
import {useNavigate, useSearchParams} from "react-router-dom";
import { Button, Chip } from "@mui/material";
import { useFetchForecastScenario } from "../../hooks";
import { ForecastScenarioDialog } from "./ForecastScenarioDialog";
import PropTypes from "prop-types";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { deleteForecastScenario } from "../../api";
import { toast } from "sonner";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import { Box } from "@mui/system";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import CustomNoRowsOverlay from "../Utils/CustomNoRowsOverlay";
import ConfirmDialog from "../Utils/ConfirmDialog";
import ForecastScenarioComparison from "./ForecastScenarioComparison";



const ForecastScenarioOverview = ({
  companyId,
  baseTimeSliceId,
  yearMonth,
}) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { data: forecastScenarios, isLoading: forecastScenariosLoading } =
    useFetchForecastScenario(baseTimeSliceId);
  const baseForecastScenarioId =
    forecastScenarios?.find((scenario) => scenario.type === "base")?.id || null;
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [editingScenario, setEditingScenario] = useState(null);
  const deleteDialogRef = useRef(null);
  const [rowSelectionModel, setRowSelectionModel] = useState([])

   useEffect(() => {
    const selectedScenarioIdsString = searchParams.get("selectedScenarioIds");
    if (selectedScenarioIdsString) {
      const selectedIds = selectedScenarioIdsString.split(",").map(Number);
      setRowSelectionModel(selectedIds);
    }
  }, [searchParams]);

   useEffect(() => {
    if (rowSelectionModel.length >= 2) {
      const comparisonSection = document.getElementById(
        "forecast-scenario-comparison-section"
      );
      if (comparisonSection) {
        comparisonSection.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [rowSelectionModel]);

  const deleteScenarioMutation = useMutation({
    mutationFn: deleteForecastScenario,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["forecastScenarios", baseTimeSliceId],
      });
      toast.success("Scenario deleted successfully");
    },
    onError: (error) => {
      toast.error(error.response.data.detail);
    },
  });

  // Function to open the dialog
  const handleOpenDialog = () => {
    setIsEditMode(false);
    setEditingScenario(null);
    setIsDialogOpen(true);
  };

  // Function to close the dialog
  const handleCloseDialog = () => {
    setIsDialogOpen(false);
    setIsEditMode(false);
    setEditingScenario(null);
  };

  const updateUrlWithSelectedScenarioIds = (selectedScenarioIds) => {
      const selectedScenarioIdsString = selectedScenarioIds.join(',');
      const searchParams = new URLSearchParams(window.location.search);
      searchParams.set('selectedScenarioIds', selectedScenarioIdsString);
      const newUrl = `${window.location.pathname}?${searchParams.toString()}`;
      window.history.pushState({ path: newUrl }, '', newUrl);
  };

  const getSelectedForecastScenarios = () => {
      const selectedScenarioIds = forecastScenarios
          .filter(scenario => rowSelectionModel.includes(scenario.id))
          .map(scenario => scenario.id);

      updateUrlWithSelectedScenarioIds(selectedScenarioIds);

      return forecastScenarios.filter(scenario =>
          rowSelectionModel.includes(scenario.id)
      );
  };

  function handleEditClick(id) {
    // Find the scenario data from the fetched scenarios
    const scenarioToEdit = forecastScenarios.find(
      (scenario) => scenario.id === id
    );
    if (scenarioToEdit) {
      setEditingScenario(scenarioToEdit);
      setIsEditMode(true);
      setIsDialogOpen(true);
    }
  }

  function handleOpenClick(id, scenarioType) {
    let currentSearchParams = new URLSearchParams(searchParams);
    currentSearchParams.append("forecast_scenario_type", scenarioType);
    if (scenarioType === "stress") {
      currentSearchParams.append("base_scenario_id", baseForecastScenarioId);
    }
    let url = `/company/${companyId}/forecast-scenario/${id}/input`;
    if (currentSearchParams.toString()) {
      url += `?${currentSearchParams.toString()}`;
    }
    return navigate(url, { state: { yearMonth: yearMonth } });
  }

  // Define the columns for the DataGrid
  // Define columns specific to forecast scenarios
  const forecastScenarioColumns = [
    { field: "name", headerName: "Name", flex: 2 },
    // { field: 'description', headerName: 'Description', flex: 2 },
    { field: "number_of_years", headerName: "Number of Years", flex: 1 },
    {
      field: "type",
      headerName: "Type",
      flex: 1,
      renderCell: (params) => (
        <Chip
          label={params.value.toUpperCase()}
          color={params.value === "base" ? "success" : "warning"}
        />
      ),
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      flex: 1,
      getActions: (params) =>
        [
          <GridActionsCellItem
            key={`view-${params.id}`}
            data-testid={`reusable-data-grid-view-button-${params.id}`}
            icon={<OpenInNewIcon />}
            label="View"
            onClick={() => handleOpenClick(params.id, params.row.type)}
          />,
          <GridActionsCellItem
            key={`edit-${params.id}`}
            data-testid={`reusable-data-grid-edit-button-${params.id}`}
            icon={<EditIcon />}
            label="Edit"
            onClick={() => handleEditClick(params.id)}
          />,
          <GridActionsCellItem
            key={`delete-${params.id}`}
            data-testid={`reusable-data-grid-delete-button-${params.id}`}
            icon={<DeleteIcon />}
            label="Delete"
            onClick={() => handleDeleteClickForDialog(params.id)}
          />,
        ].filter(Boolean),
    },
  ];

  if (forecastScenariosLoading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <p>Loading...</p>{" "}
        {/* You can replace this with a loading spinner or animation */}
      </div>
    );
  }
  // This deletes the scenario
  function handleDeleteClick(id) {
    deleteScenarioMutation.mutate(id);
  }
  // Handle delete action click for Dialog
  const handleDeleteClickForDialog = (id) => {
    if (deleteDialogRef.current) {
      deleteDialogRef.current.show(id);
    }
  };

  return (
      <Box style={{height: 400, width: "100%"}}>
          <DataGrid
              rows={forecastScenarios}
              columns={forecastScenarioColumns}
              loading={forecastScenariosLoading}
              onRowSelectionModelChange={(newRowSelectionModel) => {
                  setRowSelectionModel(newRowSelectionModel);
              }}
              rowSelectionModel={rowSelectionModel}
              checkboxSelection={true}
              slots={{
                  noRowsOverlay: CustomNoRowsOverlay,
              }}
          />
          {handleDeleteClick && (
              <ConfirmDialog
                  ref={deleteDialogRef}
                  onAction={handleDeleteClick}
                  title={"Szenario löschen?"}
              />
          )}
          <Button
              sx={{marginTop: "20px"}}
              onClick={handleOpenDialog}
              color="primary"
              variant="contained"
              disabled={!baseTimeSliceId || baseTimeSliceId === "null"}
          >
              Create New Scenario
          </Button>
          <ForecastScenarioDialog
              open={isDialogOpen}
              handleClose={handleCloseDialog}
              timeSliceId={baseTimeSliceId}
              isEditMode={isEditMode}
              existingScenario={editingScenario}
          />
          {rowSelectionModel.length >= 2 && <div id="forecast-scenario-comparison-section">
              <ForecastScenarioComparison
                  forecastScenarios={getSelectedForecastScenarios()}
              />
          </div>
          }

      </Box>
  );
};

export default ForecastScenarioOverview;

ForecastScenarioOverview.propTypes = {
    companyId: PropTypes.string.isRequired,
    baseTimeSliceId: PropTypes.string.isRequired,
    yearMonth: PropTypes.string.isRequired,
};
