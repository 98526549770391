import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Box, CircularProgress, Divider } from "@mui/material";

import AddDdCallDialog from "./AddCallDialog";
import { useFetchDdCalls, useFetchQa } from "./hooks";

import FileSelectionDialog from "../CreateDialog/CreateDialogHelpers/FileSelectionDialog";
import { addTranscriptToDDCall} from "../../api";
import Speaker from "./Speaker";
import TranscriptView from "./subComponents/TranscriptView";
import DDCallSelector from "./subComponents/DDCallSelector";
import ViewModeToggle from "./subComponents/ViewModeToggle";
import QuestionsView from "./subComponents/QuestionView";
import LLMComponent from "./LLMComponent";

const DDCallComponent = () => {
  const { companyId } = useParams();
  const navigate = useNavigate();
  const [selectedDdCallId, setSelectedDdCallId] = useState("");
  const { data: ddCalls, isLoading: isLoadingDdCalls } =
    useFetchDdCalls(companyId);
  const { data: qaData, isLoading: isLoadingQa } = useFetchQa(selectedDdCallId);

  const [viewMode, setViewMode] = useState("both"); // 'left', 'right', or 'both'
  const [isAddDialogOpen, setIsAddDialogOpen] = useState(false);

  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: addTranscriptToDDCall,
    onSuccess: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries({
        queryKey: ["ddCallTranscript", selectedDdCallId],
      });
    },
  });

  const handleChangeViewMode = (event, newViewMode) => {
    if (newViewMode !== null) {
      setViewMode(newViewMode);
      pushViewModeToUrl(newViewMode); // Push view mode to URL
    }
  };

  const pushViewModeToUrl = (viewMode) => {
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.set("dd_view", viewMode);
    navigate({ search: `?${searchParams.toString()}` }, { replace: true });
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const ddCallId = searchParams.get("dd_call_id");
    const viewModeFromUrl = searchParams.get("dd_view");

    if (ddCallId) {
      setSelectedDdCallId(ddCallId);
    }

    if (
      viewModeFromUrl &&
      ["left", "right", "both"].includes(viewModeFromUrl)
    ) {
      setViewMode(viewModeFromUrl);
    }
  }, []);

  const pushDDCallIdToUrl = (dd_call_id) => {
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.set("dd_call_id", dd_call_id);
    navigate({ search: `?${searchParams.toString()}` });
  };

  const handleSelectChange = (event) => {
    if (event.target.value === "new") {
      setIsAddDialogOpen(true);
    } else {
      const selectedValue = event.target.value;
      setSelectedDdCallId(selectedValue);
      pushDDCallIdToUrl(selectedValue);
    }
  };

  if (isLoadingDdCalls || isLoadingQa) return <CircularProgress />;
  if (!ddCalls || !qaData) return <div>Error loading data</div>;

  const safeDdCalls = Array.isArray(ddCalls) ? ddCalls : [];
  const safeQaData = Array.isArray(qaData) ? qaData : [];

  const onFileSelection = (documentId) => {
    documentId = Object.keys(documentId)[0];
    if (selectedDdCallId) {
      mutation.mutate({ ddCallId: selectedDdCallId, documentId: documentId });
    }
  };
  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          m: 2,
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
          <DDCallSelector
            selectedDdCallId={selectedDdCallId}
            setSelectedDdCallId={setSelectedDdCallId}
            handleSelectChange={handleSelectChange}
            ddCalls={safeDdCalls}
          />

          <FileSelectionDialog
            selectMultiple={false}
            onSelection={onFileSelection}
            queryType={{ companyId, fileType: 5 }}
          />

          <Speaker ddCallId={selectedDdCallId} />
          <LLMComponent ddCallId={selectedDdCallId} />
        </Box>
        <ViewModeToggle
          viewMode={viewMode}
          handleChangeViewMode={handleChangeViewMode}
        />
      </Box>

      <AddDdCallDialog
        open={isAddDialogOpen}
        onClose={() => setIsAddDialogOpen(false)}
        companyId={companyId}
        onSuccessUpdate={(data) => {
          pushDDCallIdToUrl(data.id);
          setSelectedDdCallId(data.id);
          queryClient.invalidateQueries({ queryKey: ["qaData", data.id] });
        }}
      />
      <Divider sx={{ my: 2 }} />
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <QuestionsView
          viewMode={viewMode}
          qaData={safeQaData}
          selectedDdCallId={selectedDdCallId}
        />
        <TranscriptView
          companyId={companyId}
          viewMode={viewMode}
          selectedDdCallId={selectedDdCallId}
        />
      </Box>
    </Box>
  );
};

export default DDCallComponent;
