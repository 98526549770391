/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import * as api from "../../api";
import { DataGrid } from "@mui/x-data-grid";
import useMediaQuery from "@mui/material/useMediaQuery";
import RatingsDataGridTitle from "./RatingsDataGridTitle";
import {
  getTimeSliceColumnsForRatings,
  getScoreColumns,
  commonGridStylesForRatings,
  getGapColumn,
} from "./Utils/RatingsGridColumnStructures";
import { getCombinedRow } from "./Utils/RatingsKpiProformaScoresRowUtils";

const RatingTableComponent = ({ groupId, refreshData, borrowerGroupId }) => {
  const [data, setData] = useState({
    kpi: { non_score_kpi: [], score_kpi: [] },
    scores: [],
  });
  const [loading, setLoading] = useState(false);
  const [timeSlices, setTimeSlices] = useState([]);
  const [proformaYear, setProformaYear] = useState("");
  const matches = useMediaQuery("(max-width:1920px)");

  const getTimeSlices = async (groudId) => {
    setLoading(true);
    return api
      .fetchTimeSlicesFromGroup(groupId)
      .then((response) => {
        setProformaYear(
          response.data[response.data.length - 3].year_month.split("-")[0]
        );
        let res = getTimeSliceColumnsForRatings(response.data, matches);
        let duplicateItem = { ...res[res.length - 3] };
        duplicateItem.field += "_proforma";
        res.splice(res.length - 3, 0, duplicateItem);
        res.forEach((r, i) => {
          if (r["flex"]) {
            r["flex"] = 1;
          }
          if (r["width"] && i > 0) {
            r["width"] = matches ? 200 : 100;
          }
          return r;
        });

        setTimeSlices(res);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  };

  const fetchData = async (groupId) => {
    const result = await api.fetchRatingsFromGroup(groupId, borrowerGroupId);
    setData(result.data);
  };

  useEffect(() => {
    // Define a function to fetch data from the database
    if (groupId !== null) {
      getTimeSlices(groupId);
      fetchData(groupId);
    }
    // Fetch data whenever `doUpdate` changes
  }, [groupId, refreshData, matches]);

  const mergedColumns = [
    ...timeSlices,
    getGapColumn(matches),
    ...getScoreColumns(matches, proformaYear, false),
  ];

  const generateRowForPL = (rows) => {
    return rows.slice(0, 14);
  };
  const generateRowForBS = (rows) => {
    return rows.slice(14);
  };

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          width: "100%",
        }}
      >
        <Box
          sx={{
            margin: "1.5rem",
            width: matches ? "90vw" : "70vw",
          }}
        >
          <DataGrid
            columns={mergedColumns}
            // @getCombinedRow : It creates the combined array of score kpi and proforma scores to show in one table
            rows={getCombinedRow(data.kpi.score_kpi, data.scores)}
            density="compact"
            hideFooter={true}
            getRowHeight={() => "auto"}
            slots={{
              toolbar: RatingsDataGridTitle("KPI SCORES", loading),
            }}
            getRowClassName={(params) => {
              if (params.row.subHeading) {
                return "super-app--sub__kpi-score-headers";
              }
            }}
            sx={commonGridStylesForRatings}
          />
        </Box>
      </Box>
      <Box
        sx={{
          marginTop: "3rem",
          width: "100%",
          display: "flex",
          justifyContent: "space-evenly",
          flexDirection: matches ? "column" : "row",
          alignItems: matches ? "center": 'flex-start',
          gap: 2
        }}
      >
        <Box sx={{ width: matches ? "80vw" : "45vw", height: "100%" }}>
          <DataGrid
            columns={timeSlices}
            rows={generateRowForPL(data.kpi.non_score_kpi)}
            density="compact"
            hideFooter={true}
            getRowHeight={() => "auto"}
            slots={{
              toolbar: RatingsDataGridTitle(
                "P&L Statement Informations",
                loading
              ),
            }}
            sx={commonGridStylesForRatings}
          ></DataGrid>
        </Box>

        <Box sx={{ width: matches ? "80vw" : "45vw", height: "100%" }}>
          <DataGrid
            columns={timeSlices}
            rows={generateRowForBS(data.kpi.non_score_kpi)}
            density="compact"
            hideFooter={true}
            getRowHeight={() => "auto"}
            slots={{
              toolbar: RatingsDataGridTitle(
                "Balance Sheet Statement Informations",
                loading
              ),
            }}
            sx={commonGridStylesForRatings}
            disableRowSelectionOnClick
          ></DataGrid>
        </Box>
      </Box>
    </Box>
  );
};

export default RatingTableComponent;
