import SmartToyRoundedIcon from "@mui/icons-material/SmartToyRounded";
import { ChatBubble } from "../Chat/ChatBubble";
import { ChatBubbleWriting } from "../Chat/ChatBubbleWriting";
import PropTypes from "prop-types";

export const ThreadMessage = ({ message, isLoadingDummy }) => {
  if (isLoadingDummy) {
    return (
      <ChatBubbleWriting
        isRight={message.message.type === "human"}
        name={message.message.type === "human" ? "You" : "Risk Bot"}
        ChatIcon={
          message.message.type === "ai" ? SmartToyRoundedIcon : undefined
        }
      />
    );
  }
  return (
    <ChatBubble
      content={message.message.data?.content}
      isRight={message.message.type === "human"}
      sources={message.message?.sources}
      name={message.message.type === "human" ? "You" : "Risk Bot"}
      ChatIcon={
        message.message.type === "ai" ? SmartToyRoundedIcon : undefined
      }
      isCopyable={message.message.type === "ai"}
      timeStamp={message?.created_at}
    />
  );
};

ThreadMessage.propTypes = {
    message: {
        message: PropTypes.shape({
            type: PropTypes.string,
            data: PropTypes.shape({
                content: PropTypes.string,
            }),
            sources: PropTypes.array,
        }),
        created_at: PropTypes.string,
    },
    isLoadingDummy: PropTypes.bool,

}