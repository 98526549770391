import React from "react";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";

const MonthYearPicker = ({ onDateChange, initDate }) => {
  const [value, setValue] = React.useState(initDate);
  const handleDateChange = (newValue) => {
    setValue(newValue);
    onDateChange(newValue); // call the callback function with the new value
  };

  return (
    <div>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          views={["year", "month"]}
          label="Year and Month"
          minDate={dayjs("2018-01-01")}
          maxDate={dayjs("2029-12-31")}
          value={value}
          onChange={handleDateChange}
        />
      </LocalizationProvider>
    </div>
  );
};

export default MonthYearPicker;
