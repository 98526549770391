import React from "react";
import PropTypes from "prop-types";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import ContentPasteSearchIcon from "@mui/icons-material/ContentPasteSearch";
import SummarizeIcon from "@mui/icons-material/Summarize";
import RequestPageIcon from "@mui/icons-material/RequestPage";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import LinkOffIcon from "@mui/icons-material/LinkOff";
// CREFO MODAL - DIALOG COMPONENT
const CrefoDialog = ({
  openCrefoModal,
  setOpenCrefoModal,
  modalTitle,
  crefoFunctionSelected,
  children,
}) => {
  // HELPER FUNCTION TO CLOSE MODAL
  const handleClose = () => {
    setOpenCrefoModal(false);
  };
  // HELPER FUNCTION TO GET ICONS BASED ON CREFO FUNCTION SELECTED
  const getIcons = (crefoFunctionSelected) => {
    const style = {
      fontSize: "25px",
      marginRight: "1rem",
    };
    switch (crefoFunctionSelected) {
      case "search":
        return <ContentPasteSearchIcon sx={style} />;
      case "reports":
        return <SummarizeIcon sx={style} />;
      case "request":
        return <ReceiptLongIcon sx={style} />;
      case "unlink":
        return <LinkOffIcon sx={style} />;
      default:
        return <RequestPageIcon sx={style} />;
    }
  };

  return (
    <Box>
      <Dialog
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "1000px",
            },
          },
        }}
        open={openCrefoModal}
        onClose={handleClose}
      >
        <DialogTitle
          sx={{
            display: "flex",
            color: "teal",
            margin: "1rem 0rem 1rem 0.5rem",
            alignItems: "center",
          }}
        >
          {getIcons(crefoFunctionSelected)}
          {modalTitle}
        </DialogTitle>
        <DialogContent>{children}</DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            color="primary"
            onClick={handleClose}
            sx={{ margin: "0rem 0.5rem 1rem 0rem" }}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

CrefoDialog.propTypes = {
  openCrefoModal: PropTypes.bool.isRequired,
  setOpenCrefoModal: PropTypes.func.isRequired,
  modalTitle: PropTypes.string.isRequired,
  crefoFunctionSelected: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default CrefoDialog;
