import React from "react";
import { riskStudioColors } from "../../styles/colors";
import { BsChevronRight } from "react-icons/bs";
import CustomToolTip from "../../components/CustomToolTip";
import clsx from "clsx";
import {
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid";
import { fetchAllCommentsByGroup } from "../../api";
import { getPositionAttributesForLiquidity } from "../../components/Forecast/ForcastDataGridHelpers";

/**
 * This class provides the necessary functions to format the data and styles for the input data grids used in Forcast input grid and HGB input grid.
 * @param {Array} positionAttributes - It is an array of objects containing the positon attributes for each row in the grid.
 * Example of positionAttributes:  [{
  "id": "a_a",
  "name": "Anlagevermögen",
  "editable": false,
  "position_type": "activa",
  "level": 1,
  "font_weight": "bold"
}]
* @returns nothing
 */

class InputDataGridFormatterClient {
  constructor(positionAttributes, comments = {}) {
    this.positionAttributes = positionAttributes || [];
    this.colors = riskStudioColors;
    this.comments = comments;
  }
  /**
   * This is the fuction to refresh the comments for the input grid.
   * @param {String} groupId - Group id for the input grid.
   * @return {undefined}
   * Example of usage: gridFormatterClient.refreshComments("groupId")
   */
  refreshComments = (groupId) => {
    return fetchAllCommentsByGroup(groupId).then((response) => {
      this.comments = response;
    });
  };
  /**
   * Compute the styles for different levels of row metadata.
   * @param {Object} rowMetaData - Row metadata (Here is position attributes).
   * @return {Object} Style object.
   */
  getPositionNameStyleForLevels = (rowMetaData) => {
    const paddingScale = 1;
    const isAdj = rowMetaData.id.includes("adj");
    const isBilanzsumme = !!rowMetaData.bilanzsumme;
    const isLiquidity = rowMetaData.position_type === "liquidity";
    // Compute the color for different levels for position name
    const colorCalc = (level) => {
      if (isBilanzsumme && level === 1) {
        return this.colors.balanceSumTextColor;
      } else if (isAdj && level === 2) {
        return this.colors.adjacentTextColor;
      } else if (rowMetaData.id.includes("gap") && level === 1) {
        return this.colors.transparent;
      } else {
        return "";
      }
    };
    const fixedStyle = {
      overflow: "none",
      margin: "7px",
    };
    if (rowMetaData.level === 1) {
      return {
        ...fixedStyle,
        fontWeight: rowMetaData.font_weight,
        paddingLeft: `${paddingScale * 0}rem`,
        fontSize: "1rem",
        color: colorCalc(rowMetaData.level),
      };
    }
    if (rowMetaData.level === 2) {
      let paddingLeft;
      if (isAdj) {
        paddingLeft = `${paddingScale * 3}rem`;
      } else {
        paddingLeft = `${isLiquidity ? paddingScale * 0 : paddingScale * 1}rem`;
      }
      return {
        ...fixedStyle,
        fontWeight: rowMetaData.font_weight,
        paddingLeft,
        color: colorCalc(rowMetaData.level),
      };
    }
  };

  /**
   * This is the fuction to render the cell for values. It is used in the data grid columns.The position attributes are used to compute the styles for the cell.
   * @param {Object} params - MUI Data grid params.
   * @param {Object} options - Options for different views when the scenario is in stress.
   * @return {JSX} numbers as string inside jsx.
   */
  renderCellForValues = (params) => {
    const positionAttributes = [
      ...this.positionAttributes,
      ...getPositionAttributesForLiquidity(),
    ];
    const comment = this.comments?.[params.colDef.field]?.[params.row.key];
    const rowMetaData = positionAttributes?.find(
      (position) => position.id === params.row.key
    );
    const isBilanzsumme = rowMetaData?.position_type === "ap_sum";
    const getCellFontColor = (rowMetaData, isBilanzsumme) => {
      if (
        rowMetaData?.position_type === "liquidity" &&
        rowMetaData?.id.includes("gap")
      ) {
        return this.colors.transparent;
      } else if (isBilanzsumme) {
        return this.colors.balanceSumTextColor;
      }
      return "";
    };
    const cellStyle = {
      padding: 0,
      margin: 0,
      fontWeight: rowMetaData?.font_weight,
      fontSize: rowMetaData?.level === 1 ? "0.9rem" : "",
      color: getCellFontColor(rowMetaData, isBilanzsumme),
    };
    const getTooplipCommentTitle = () => {
      if (comment) {
        return comment.comment_content;
      }
      if (params.row.comment) {
        return params.row.comment[params.field];
      }
      return "";
    };
    return (
      <CustomToolTip title={getTooplipCommentTitle()}>
        <p style={cellStyle}>
          {comment ||
          (params.row.comment &&
            Object.keys(params.row.comment).includes(params.field)) ? (
            <span style={{ color: "#a9c45e" }}>{"●    "}</span>
          ) : (
            ""
          )}
          {params.field.includes("percentage")
            ? (new Intl.NumberFormat("de-DE").format(Math.round(params.value * 100))) 
            : new Intl.NumberFormat("de-DE").format(params.value)}
        </p>
      </CustomToolTip>
    );
  };
  /**
   * This is the fuction to render the LEFT COLUMN cells for position names. It is used in the data grid columns.The position attributes are used to compute the styles for the cell.
   * @param {Object} params - MUI Data grid params.
   * @return {JSX} numbers as string inside jsx.
   */
  renderCellForPositionName = (params) => {
    const positionAttributes = [
      ...this.positionAttributes,
      ...getPositionAttributesForLiquidity(),
    ];
    const rowMetaData = positionAttributes.find(
      (position) => position.id === params.row.key
    );
    const isLiquidity = rowMetaData?.position_type === "liquidity";

    if (rowMetaData) {
      return (
        <div style={this.getPositionNameStyleForLevels(rowMetaData)}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {rowMetaData.level === 2 && !isLiquidity ? (
              <span
                style={{
                  marginRight: "0.5rem",
                }}
              >
                <BsChevronRight size="10px" />
                {"  "}
              </span>
            ) : (
              ""
            )}
            <CustomToolTip arrow title={rowMetaData.title || ""}>
              <span
                style={{
                  textDecoration: rowMetaData.title ? "underline" : "none",
                }}
              >
                {params.value}
              </span>
            </CustomToolTip>
          </div>
        </div>
      );
    }
    return params.value;
  };
  /**
   * This is the fuction to generate the cell class name for balance sum row key. It is used in the data grid columns.
   * @param {Object} params - MUI Data grid params.
   * @return {String} class name.
   * Example of class name: "ap_sum ap_sum_different"
   */
  generateCellClassNameForBalanceSum = (params) => {
    if (params.row.key === "ap_sum") {
      return clsx("ap_sum", {
        ap_sum_different: params.value < 0 || params.value > 0,
        ap_sum_not_different: params.value === 0,
      });
    }
  };
  /**
   * This is a util function to get Grid name based on position type.
   * @param {String} positionType - Position type e.g: ap_sum, activa.
   * @return {String} Grid name.
   * Example of grid name: "Bilanzsummen checken"
   */

  getGridName = (positionType) => {
    switch (positionType) {
      case "ap_sum":
        return "Bilanzsummen checken";
      case "activa":
        return "Aktiva";
      case "passiva":
        return "Passiva";
      case "pl":
        return "Gewinn- und Verlustrechnung";
      case "liquidity":
        return "Liquidität";
      case "other":
        return "andere Positionen";
      default:
        return "Grid Name";
    }
  };
  /**
   * This is a util function to get styles for the input grid wrapper.
   * @param {Boolean} isApSum - If the grid is for Bilanzsummen checken.
   * @return {Object} Style object.
   * Example of style object: { containerStyle: { backgroundColor: "#fff" }, typographyStyle: { color: "#fff" } }
   */

  getInputGridWrapperStyles = (isApSum) => {
    return {
      containerStyle: {
        backgroundColor: isApSum
          ? this.colors.white
          : this.colors.gridWrapperPrimaryColor,
        borderRadius: "5px 5px 0px 0px",
        padding: "0.3rem 1rem",
        textTransform: "uppercase",
        border: isApSum
          ? `3px solid ${this.colors.gridWrapperPrimaryColor}`
          : "none",
      },
      typographyStyle: {
        color: isApSum
          ? this.colors.gridWrapperPrimaryColor
          : this.colors.white,
        fontWeight: "bold",
      },
    };
  };
  /**
   * This is a util function to get height for the input grid container.
   * @param {Boolean} matches - If the screen size matches the breakpoint.
   * @param {Number} rowsLength - Number of rows in the grid.
   * @return {String} Height of the grid container.
   * Example of height: "100%"
   */
  getInputGridContainerHeight = (matches, rowsLength) => {
    if (matches && rowsLength > 2) {
      return "100%";
    } else if (matches && rowsLength <= 2) {
      return "100%";
    } else if (!matches && rowsLength > 2) {
      return "60vh";
    } else if (!matches && rowsLength <= 2) {
      return "100%";
    }
  };
  /**
   * This is a util function to get toolbar for the input grid.
   * @return {JSX} Toolbar jsx.
   * Example of toolbar jsx: <GridToolbarContainer>...</GridToolbarContainer>
   */
  getGridCustomToolbar = () => {
    return (
      <GridToolbarContainer>
        <GridToolbarDensitySelector />
        <GridToolbarQuickFilter />
      </GridToolbarContainer>
    );
  };

  /**
   * This is a data grid function to get if the cell is editable or not.
   * @param {Object} params - MUI Data grid params.
   * @return {Boolean} If the cell is editable or not.
   * Example of return value: true
   */
  getIsCellEditable = (params) => {
    // In positionAttributes, id is the row key
    return this.positionAttributes.find(
      (position) => position.id === params.row.key
    )?.editable;
  };

  /**
   * This is a util function to get styles for the input grid primary - sx for DataGrid.
   * @return {Object} Style object.
   * Example of style object: { borderRadius: "0px 0px 5px 5px" }
   * Usage: <DataGrid sx={gridFormatterClient.getDataGridPrimaryStyles()} />
   */
  getDataGridPrimaryStyles = () => ({
    "&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell": {
      py: "0px",
    },
    "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": {
      py: "0px",
    },
    "&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell": {
      py: "0px",
    },
    borderRadius: "0px 0px 5px 5px",
  });
  /**
   * This is a util function to get box container styles for the input grid primary container - sx for Box.
   * @param {Boolean} matches - If the screen size matches the breakpoint.
   * @param {Object} theme - MUI theme object.
   * @param {Number} rowsLength - Number of rows in the grid.
   * @return {Object} Style object.
   * Example of style object: { height: "100%", width: "100%" }
   * Usage: <Box sx={gridFormatterClient.getDataGridPrimaryContainerStyles(matches, theme, rowsLength)}>...<DataGrid>...</DataGrid></Box>
   */
  getDataGridPrimaryContainerStyles = (matches, theme, rowsLength) => ({
    height: this.getInputGridContainerHeight(matches, rowsLength),
    width: "100%",
    "& .MuiDataGrid-cell--textLeft": {
      backgroundColor:
        theme.palette.mode === "dark"
          ? "rgba(255, 255,255, 0.05)"
          : this.colors.transparent,
    },
    "& .MuiDataGrid-columnHeaders": {
      border: `2px solid #b0bec5`,
      borderRadius: "0px",
    },
    "& .MuiDataGrid-cell--editable": {
      backgroundColor:
        theme.palette.mode === "dark"
          ? this.colors.editableCellBgColorDarkMode
          : this.colors.editableCellBgColor,
    },
    "& .ap_sum": {
      backgroundColor:
        theme.palette.mode === "dark"
          ? "rgba(255, 255,255, 0.2)"
          : this.colors.balanceSumBgColor,
      fontWeight: "600",
    },
    "& .MuiDataGrid-row .ap_sum.ap_sum_different p": {
      color: "#ff5722 !important",
    },
    "& .forecast-grid__sub-heading": {
      backgroundColor: `${this.colors.gridGroupHeadingColor} !important`,
    },
  });
  /**
   * This is a util function to removed the objects with specific keys from an array of objects.
   * @param {Array} arrayOfObjects - Array of objects.
   * @return {Array} Array of objects.
   */
  removeObjectsWithKey = (arrayOfObjects) => {
    const keysToRemove = [
      "KPIs",
      "Liquiditätskennzahlen",
      "Effizienz-Kennzahlen",
      "Rentabilitätskennzahlen",
      "Verschuldungskennzahlen",
      "EMPTY",
    ];
    // Filter the array, keeping only objects whose 'key' attribute is NOT in the keysToRemove list
    return arrayOfObjects.filter((obj) => !keysToRemove.includes(obj.name));
  };
}
export default InputDataGridFormatterClient;
