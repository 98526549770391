import React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import WarningIcon from "@mui/icons-material/Warning";

const getStatusColor = (isValid) => (isValid ? "#66bb6a" : "#ff5252");

const formatNumber = (number) => new Intl.NumberFormat("de-DE").format(number);

const ProformaStatusBox = ({
  isValid,
  sourcesSum,
  usesSum,
  changeOfActiva,
  changeOfPassiva,
}) => (
  <Box sx={{ alignSelf: "flex-start", marginTop: "1rem" }}>
    <Typography
      variant="h6"
      sx={{
        width: "80%",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: "1px 5px",
        borderRadius: "3px",
        color: "white",
        backgroundColor: getStatusColor(isValid),
      }}
    >
      Checks{" "}
      {isValid ? (
        <CheckCircleIcon
          style={{ color: "white", marginLeft: "10px" }}
        />
      ) : (
        <WarningIcon
          style={{ color: "white", marginLeft: "10px" }}
        />
      )}
    </Typography>

    {[
      "Sum of sources",
      "Sum of uses",
      "Change of Activa",
      "Change of Passiva",
    ].map((label, index) => (
      <Typography
        key={`proforma-statusbox--${index + 1}`}
        sx={{ margin: "0.5rem 0rem", padding: "0px 5px" }}
      >
        {label}:{" "}
        <span
          style={{
            color: "teal",
            fontWeight: "bold",
            letterSpacing: "1px",
          }}
        >
          {formatNumber(
            index === 0
              ? sourcesSum
              : index === 1
              ? usesSum
              : index === 2
              ? changeOfActiva
              : changeOfPassiva
          )}
        </span>
      </Typography>
    ))}
  </Box>
);

// PROPS VALIDATIONS
ProformaStatusBox.propTypes = {
  isValid: PropTypes.bool.isRequired,
  sourcesSum: PropTypes.number.isRequired,
  usesSum: PropTypes.number.isRequired,
  changeOfActiva: PropTypes.number.isRequired,
  changeOfPassiva: PropTypes.number.isRequired,
};

export default ProformaStatusBox;
