import React from "react";
import { useFetchTranscript } from "./hooks";
import { ChatBubble } from "../Chat/ChatBubble";
import PersonIcon from "@mui/icons-material/Person";
import PropTypes from "prop-types";
import { Box, Typography } from "@mui/material";

// MAIN COMPONENT ===========>
const CallTranscript = ({ ddCallId }) => {
  // STATE VARIABLES =============>
  // RefetchTranscript is used to refetch the transcript when a new transcript is added or updated
  const [refetchTranscript, setRefetchTranscript] = React.useState(false);
  // QUERY HOOKS =============>
  // Fetching the transcript data
  const { data: transcript, isLoading: isLoadingTranscript } = useFetchTranscript(
    ddCallId,
    refetchTranscript
  );
  // RENDER =============>
  return (
    <Box>
      {/* Loading */}
      {isLoadingTranscript && (
        <Box sx={{ textAlign: "center", margin: "3rem auto" }}>
          <Typography variant="h5" sx={{ color: "GrayText" }}>
            {" "}
            Loading Transcript...
          </Typography>
        </Box>
      )}
      {/* Chat Bubbles */}
      {transcript?.map((qa, i) => (
        <ChatBubble
          transcriptId={qa.id}
          key={`${i}-${qa.from_timestamp}-transcript`}
          content={qa.content}
          isRight={qa.employee_id === null}
          name={qa.speaker}
          customTimeStamp={qa.from_timestamp}
          ChatIcon={PersonIcon}
          isCopyable={true}
          setRefetchTranscript={setRefetchTranscript}
        ></ChatBubble>
      ))}
    </Box>
  );
};

export default CallTranscript;

CallTranscript.propTypes = {
  ddCallId: PropTypes.string.isRequired,
};
