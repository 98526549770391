import React from "react";
import PropTypes from "prop-types";
import "./style/ScenarioDataTable.css";

const TableHeader = ({ data, indicator, limitedIndicator }) => (
  <thead>
    <tr>
      <th rowSpan="2">Time Period / Scenario</th>
      {data.map((scenario) => (
        <th key={scenario.id}>{scenario.name}</th>
      ))}
    </tr>
    <tr>
        <th colSpan={data.length + 1}  className="table-title"
                                              title={indicator}>
            {limitedIndicator}
        </th>

    </tr>
  </thead>
);

const TableBody = ({keys, data, forecastScenarioData}) => (
    <tbody>
    {keys.map((key) => {
        const scenario = forecastScenarioData ? forecastScenarioData.find(scenario => scenario.id == key) : null;
        const scenarioName = scenario ? scenario.name : "Unknown";
        return <TableRow key={key} data={data} scenarioId={key} scenarioName={scenarioName}/>;
    })}
  </tbody>
);

const TableRow = ({data, scenarioId, scenarioName}) => (
  <tr>
      <td><strong>{scenarioName}</strong></td>
    {data.map((scenario) => (
        <td key={`${scenario.id}-${scenarioId}`} className={scenario[scenarioId] < 1 ? 'red-highlight' : ''}>
            {scenario[scenarioId] || 0}
        </td>
    ))}
  </tr>
);

const ScenarioDataTable = ({indicator, data, lemmatization_length, forecastScenarioData}) => {
  if (data.length < 1) {
    return <div></div>;
  }

  const keys = Object.keys(data[0]).filter((key) => key !== "name");
  const limitedIndicator = indicator.length > lemmatization_length
      ? indicator.substring(0, lemmatization_length) + "..." : indicator;

  return (
    <div>
      <table>
        <TableHeader data={data} indicator={indicator} limitedIndicator={limitedIndicator}/>
          <TableBody keys={keys} data={data} forecastScenarioData={forecastScenarioData}/>
      </table>
    </div>
  );
};

TableHeader.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired
    })
  ).isRequired,
  indicator: PropTypes.string.isRequired,
  limitedIndicator: PropTypes.string.isRequired
};

TableBody.propTypes = {
  keys: PropTypes.arrayOf(PropTypes.string).isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  forecastScenarioData: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired
    })
  )
};

TableRow.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  scenarioId: PropTypes.string.isRequired,
  scenarioName: PropTypes.string.isRequired
};

ScenarioDataTable.propTypes = {
  indicator: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired
    })
  ).isRequired,
  lemmatization_length: PropTypes.number.isRequired,
  forecastScenarioData: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired
    })
  )
};

export default ScenarioDataTable;
