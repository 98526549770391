import React, { useState, useEffect } from "react";
import { TextField } from "@mui/material";

const NumberField = ({ value: initialValue, onChange, params, ...props }) => {
  const [value, setValue] = useState(0);
  const [error, setError] = useState(false);

  useEffect(() => {
    if (initialValue !== undefined) {
      setValue(initialValue);
      // Setting the initial value of percentage fields to 10, 5 and 5
      onChange(initialValue);
    }
  }, []); // Empty dependency array to run only on initial render

  const handleChange = (event) => {
    let inputValue = event.target.value;
    if (inputValue === "") {
      // If inputValue is empty, set to 0
      setValue("");
      onChange(0);
      setError(false);
    } else if (!isNaN(inputValue)) {
      // Checks if inputValue is a number
      inputValue = Number(inputValue);
      setValue(inputValue);
      onChange(inputValue);
      setError(false);
    } else {
      /[,.]/.test(inputValue) ? setError(false) : setError(true);
    }
  };

  return (
    <div>
      <TextField
        {...props}
        value={value}
        onChange={handleChange}
        error={error}
        helperText={error ? "Invalid! Only positive numbers are allowed!" : ""}
        onFocus={(event) => event.target.select()}
        sx={{ margin: 0 }}
      />
    </div>
  );
};

NumberField.propTypes = {
  ...TextField.propTypes, // Propagate any prop-types from TextField
  // You can specify additional propTypes here if needed
};

export default NumberField;
