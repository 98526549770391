import React from 'react';

import {Layout} from "../components/Layout/Layout";

import DDCallComponent from "../components/dueDilligenceCall/DDCallComponent";


const DDCallPage = () => {


    return (<Layout>
            <DDCallComponent></DDCallComponent>
        </Layout>);
};

export default DDCallPage;
