import * as React from "react";
import PropTypes from "prop-types";
import { Button, Menu, MenuItem } from "@mui/material";
import { ActionButton } from "./ActionButton";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

export const ActionsDropdown = ({ stepActions, dropdownText }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button
        id="actions-dropdown-button"
        aria-controls={open ? "actions-dropdown-menu_crefo" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        variant="outlined"
        disableElevation
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon />}
        size="small"
      >
        {dropdownText || "Options"}
      </Button>
      <Menu
        id="actions-dropdown-menu_crefo"
        MenuListProps={{
          "aria-labelledby": "actions-dropdown-button",
        }}
        open={open}
        elevation={2}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        onClose={handleClose}
        anchorEl={anchorEl}
      >
        {stepActions.map((stepAction) => {
          return (
            <MenuItem disableRipple key={stepAction.text} sx={{ padding: 0 }}>
              <ActionButton stepAction={stepAction} isMenuItem />
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
};

ActionsDropdown.propTypes = {
  stepActions: PropTypes.arrayOf(
    PropTypes.shape({
      onClick: PropTypes.func.isRequired,
      href: PropTypes.string,
      text: PropTypes.string.isRequired,
    })
  ).isRequired,
  dropdownText: PropTypes.string,
};