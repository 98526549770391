import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  TextField,
  InputAdornment,
  Box,
  Typography,
  IconButton,
} from "@mui/material";
import { Search as SearchIcon, Clear as ClearIcon } from "@mui/icons-material";
import { useNavigate, useLocation } from "react-router-dom";
import { debounce } from "lodash";
import { searchCompanies } from "../api";
import { useTheme } from "@mui/material/styles";

export default function CompanySelection({ onCompanySelected }) {
  const [options, setOptions] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [key, setKey] = useState(Math.random());
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();
  useEffect(() => {
    const debouncedFetch = debounce(async () => {
      if (inputValue.length >= 3 || /^[0-9]+$/.test(inputValue)) {
        // Replace fetchCompanies with your API fetching logic
        const companies = await fetchCompanies(inputValue);
        setOptions(companies);
      }
    }, 1000); // 1 seconds delay
    debouncedFetch();

    return () => {
      debouncedFetch.cancel();
    };
  }, [inputValue]);

  async function fetchCompanies(query) {
    try {
      const response = await searchCompanies(query); // passing query here
      return response.data.companies; // assuming companies is a field in the response
    } catch (error) {
      console.error("Could not fetch companies:", error);
    }
  }

  const handleFetch = debounce(async () => {
    if (inputValue.length >= 3 || /^[0-9]+$/.test(inputValue)) {
      const companies = await fetchCompanies(inputValue);
      setOptions(companies);
    }
  }, 2000);

  const handleImmediateFetch = async () => {
    if (inputValue.length >= 3 || /^[0-9]+$/.test(inputValue)) {
      const companies = await fetchCompanies(inputValue);
      setOptions(companies);
    }
  };

  const handleInputChange = (event, newInputValue) => {
    setInputValue(newInputValue);
  };

  const handleClear = () => {
    setInputValue("");
    setOptions([]);
    setKey(Math.random());
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleFetch.flush();
    }
  };

  const handleCompanySelect = (event, value) => {
    if (value) {
      if (onCompanySelected) {
        // Call the provided function with the selected company's id and name
        onCompanySelected(value.id, value.name);
      } else {
        // Existing navigation logic
        const newPath = location.pathname.replace(
          /\/company\/([^/]+)\/[^/]*$/,
          `/company/${value.id}/view`
        );
        navigate(newPath);
      }
    }
  };

  return (
    <Autocomplete
      key={key}
      onKeyDown={(event) => {
        if (event.key === "Enter") {
          handleImmediateFetch();
        }
      }}
      sx={{
        marginRight: "1rem",
        width: 300,
        backgroundColor: "transparent",
        "&:focus-within": {
          backgroundColor: "white",
          borderRadius: "5px",
        },
        "& .MuiOutlinedInput-root": {
          backgroundColor:
            theme.palette.mode === "dark" ? "rgba(0,0,0,0.5)" : "azure",
          borderRadius: "5px",
        },
        "& .MuiOutlinedInput-input": {
          padding: "10px",
        },
      }}
      options={options}
      getOptionLabel={(option) => `${option.name} (${option.id})`}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder="Search company"
          variant="outlined"
          size="small"
          onKeyDown={handleKeyDown}
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <>
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
                <IconButton onClick={handleClear} data-testid="clear-button">
                  <ClearIcon />
                </IconButton>
              </>
            ),
          }}
        />
      )}
      renderOption={(props, option, { selected }) => (
        <Box
          {...props}
          style={{ backgroundColor: selected ? "#eee" : "transparent" }}
        >
          <Typography sx={{ fontWeight: "bold", flexGrow: 1 }}>
            {option.name}
          </Typography>
          <Typography sx={{ color: "darkgray" }}>{option.id}</Typography>
        </Box>
      )}
      filterOptions={(options, { inputValue }) => {
        const matcher = new RegExp(inputValue, "i");
        return options
          .filter(
            (option) =>
              matcher.test(String(option.id)) || matcher.test(option.name)
          )
          .slice(0, 10);
      }}
      onInputChange={handleInputChange}
      onChange={handleCompanySelect}
    />
  );
}
