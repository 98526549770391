import { BsChevronRight } from "react-icons/bs";
import { riskStudioColors as colors } from "../../../styles/colors";
import { AllNonScorePositions } from "./AllRatingPositions/NonScorePositions.js";
import clsx from "clsx";

////////// COLUMNS FOR KPIS AND RATINGS - BS & PL ///////////
export const commonGridStylesForRatings = {
  "& .MuiDataGrid-columnHeaders": {
    border: `2px solid #b0bec5`,
    borderRadius: "0px",
  },
  "&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell": {
    py: "8px",
  },
  "& .super-app--sub__kpi-score-headers": {
    backgroundColor: colors.gridGroupHeadingColor,
  },
  "& .raitng-score-grid_gap--custom": {
    backgroundColor: colors.gridGroupHeadingColor,
  },
  "& .raitng-score-grid_gap--custom.raitng-score-grid_gap--custom__false": {
    backgroundColor: colors.transparent,
  },
  "& .rating-kpi-score": {
    backgroundColor: colors.gridInsideColorLight,
  },
  "& .rating-kpi-score_gap--active": {
    backgroundColor: colors.transparent,
  },
};
export const getGapColumn = (matches) => ({
  field: "gap",
  width: matches ? 40 : 80,
  editable: false,
  renderCell: (params) => "",
  renderHeader: (params) => "",
  cellClassName: (params) =>
    clsx("raitng-score-grid_gap--custom", {
      "raitng-score-grid_gap--custom__false":
        params.row.key.includes("profile"),
    }),
});
const renderCellSubHeadingForKpiProformaScores = (params) => {
  if (params.row.subHeading) {
    const cellStyle = {
      padding: "0 0 0 0.4rem",
      margin: 0,
      fontWeight: params.row.fontWeight,
      fontSize: "1rem",
    };
    return <p style={cellStyle}>{params.value}</p>;
  } else {
    return (
      <div style={{ paddingLeft: "1.5rem", margin: 0 }}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <span
            style={{
              marginRight: "0.5rem",
            }}
          >
            <BsChevronRight size="10px" />
            {"  "}
          </span>
          <span style={{ fontSize: "0.95rem" }}>{params.value}</span>
        </div>
      </div>
    );
  }
};

export const getFormattedValue = (params) => {
  const forOneDecimalRoundPercentage = [
    "ebitmargin_score",
    "equity_total_assets_score",
    "ffo_debt_score",
    "cash_total_assets_score",
    "cash_short_term_debt_score",
    "short_term_assets_short_term_liabilities_score",
  ];
  const forOnlyOneDecimalRound = [
    "debt_ebitda_score",
    "ebit_interest_charge_score",
  ];
  if (forOneDecimalRoundPercentage.includes(params.row.key)) {
    const oneDecimalRoundPercentage = new Intl.NumberFormat("de-DE").format(
      (params.value * 100).toFixed(0)
    );
    return `${oneDecimalRoundPercentage}%`;
  } else if (forOnlyOneDecimalRound.includes(params.row.key)) {
    const oneDecimalRound = params.value.toFixed(1);
    return new Intl.NumberFormat("de-DE").format(oneDecimalRound);
  } else if (params.row.key === "liquidty_excess_12_month_forward_score") {
    return new Intl.NumberFormat("de-DE").format(params.value.toFixed(2));
  }
  return new Intl.NumberFormat("de-DE").format(params.value);
};

export const getTimeSliceColumnsForRatings = (data, matches) => {
  const transformedData = data.reduce((acc, item) => {
    const baseField = item.id.toString();
    acc.push({
      field: baseField,
      width: matches ? 80 : 100,
      editable: false,
      type: "number",
      flex: 1,
      headerName: baseField,
      renderCell: (params) => {
        const rowMetaDataForStatementInfos = AllNonScorePositions.find(
          (position) => position.key === params.row.key
        );
        if (rowMetaDataForStatementInfos) {
          const cellStyle = {
            padding: 0,
            margin: 0,
            fontWeight: rowMetaDataForStatementInfos.fontWeight,
            fontSize: "0.95rem",
          };
          return (
            <p style={cellStyle}>
              {new Intl.NumberFormat("de-DE").format(params.value)}
            </p>
          );
        }
        return params.row.subHeading ? (
          ""
        ) : (
          <p
            style={{
              padding: 0,
              margin: 0,
              fontSize: "0.95rem",
            }}
          >
            {getFormattedValue(params)}
          </p>
        );
      },
      renderHeader: (params) => {
        return (
          <strong style={{ color: colors.balanceSumTextColor }}>
            {params.field.endsWith("_proforma")
              ? new Date(item.year_month).getFullYear() + " proforma"
              : new Date(item.year_month).getFullYear()}
          </strong>
        );
      },
    });

    return acc;
  }, []);

  const positionColumn = {
    field: "name",
    width: matches ? 350 : 450,
    editable: false,
    renderCell: (params) => {
      const rowMetaDataForStatementInfos = AllNonScorePositions.find(
        (position) => position.key === params.row.key
      );
      if (rowMetaDataForStatementInfos) {
        return (
          <div
            style={{
              fontWeight: rowMetaDataForStatementInfos.fontWeight,
              paddingLeft: "0.5rem",
              margin: 0,
              fontSize: "0.95rem",
            }}
          >
            <span>{params.value}</span>
          </div>
        );
      } else if (params.row.hasOwnProperty("subHeading")) {
        return renderCellSubHeadingForKpiProformaScores(params);
      }
      return params.value;
    },
    renderHeader: (params) => {
      return (
        <strong style={{ color: colors.balanceSumTextColor }}>
          Position Name
        </strong>
      );
    },
    cellClassName: (params) => {
      return clsx("rating-kpi-score", {
        "rating-kpi-score_gap--active": params.row.key.includes("profile"),
      });
    },
  };

  transformedData.unshift(positionColumn);

  return transformedData;
};

////////// COLUMNS FOR PROFORMA SCORES ///////////
export const getScoreColumns = (
  matches,
  proformaYear,
  onlyPositionHeader = false
) => {
  const getProformaValueColor = (val) => {
    if (val > 0 && val <= 2) {
      return "#009688";
    }
    if (val >= 6 && val <= 7) {
      return "#ef6c00";
    }
    if (val >= 8) {
      return "red";
    }
    return "#424242";
  };
  const renderCell = (params) => {
    const color = getProformaValueColor(params.value);
    return (
      <span style={{ color, textShadow: `0px 1px 0px ${color}` }}>
        {params.value.toString()}
      </span>
    );
  };
  const scorePositionColumn = [
    {
      field: "name",
      width: 400,
      headerAlign: "left",
      type: "number",
      align: "left",
      renderHeader: (params) => {
        return (
          <strong style={{ color: colors.balanceSumTextColor }}>
            Position Name
          </strong>
        );
      },
      renderCell: (params) => {
        if (params.row.hasOwnProperty("subHeading")) {
          return renderCellSubHeadingForKpiProformaScores(params);
        }
        return params.value;
      },
    },
  ];
  const scoreColumns = [
    {
      field: "proforma_last_year",
      headerName: "proforma_last_year",
      width: matches ? 100 : 150,
      flex: 1,
      type: "number",
      renderCell,
      renderHeader: (params) => {
        return (
          <strong style={{ color: colors.balanceSumTextColor }}>
            {`${proformaYear} proforma`}
          </strong>
        );
      },
    },
    {
      field: "last_year",
      headerName: "last_year",
      width: matches ? 100 : 150,
      flex: 1,
      type: "number",
      renderCell,
      renderHeader: (params) => {
        return (
          <strong style={{ color: colors.balanceSumTextColor }}>
            {proformaYear}
          </strong>
        );
      },
    },
    {
      field: "last_3_years",
      headerName: "last_3_years",
      width: matches ? 100 : 150,
      flex: 1,
      type: "number",
      renderCell,
      renderHeader: (params) => {
        return (
          <strong style={{ color: colors.balanceSumTextColor }}>
            Score 3yrs
          </strong>
        );
      },
    },
    {
      field: "proforma_last_3_years",
      headerName: "proforma_last_3_years",
      width: matches ? 100 : 150,
      flex: 1,
      type: "number",
      renderCell,
      renderHeader: (params) => {
        return (
          <strong style={{ color: colors.balanceSumTextColor }}>
            3yrs Proforma
          </strong>
        );
      },
    },
  ];
  if (onlyPositionHeader) {
    return scorePositionColumn;
  }
  return scoreColumns;
};
