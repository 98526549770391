import React, { useState } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { TextField, Button } from "@mui/material";
import { postQaForDdCall } from "../../api";
import PropTypes from "prop-types";

const AddQaComponent = ({ ddCallId }) => {
  const queryClient = useQueryClient();
  const [question, setQuestion] = useState("");
  const [answer, setAnswer] = useState("");
  const [category, setCategory] = useState("");
  
  const mutation = useMutation({
    mutationFn: postQaForDdCall,
    onSuccess: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries({ queryKey: ["qaData"] });
    },
  });


  const handleSubmit = async () => {
    mutation.mutate({ ddCallId, data: { question, answer, category } });

    setQuestion("");
    setAnswer("");
    setCategory("");
  };

  return (
    <div>
      <TextField
        label="Frage"
        value={question}
        onChange={(e) => setQuestion(e.target.value)}
        fullWidth
        margin="normal"
      />
      <TextField
        label="Antwort"
        value={answer}
        onChange={(e) => setAnswer(e.target.value)}
        fullWidth
        multiline
        rows={4}
        margin="normal"
      />

      <TextField
        label="Kategorie" // Input field for category
        value={category}
        onChange={(e) => setCategory(e.target.value)}
        fullWidth
        margin="normal"
      />

      <Button
        onClick={handleSubmit}
        variant="contained"
        color="primary"
        disabled={!question || question.trim() === ""}
      >
        Frage Hinzufügen
      </Button>
    </div>
  );
};

export default AddQaComponent;

AddQaComponent.propTypes = {
  ddCallId: PropTypes.string.isRequired,
};
