import CustomToolTip from "../CustomToolTip";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import WarningIcon from "@mui/icons-material/Warning";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { riskStudioColors as colors } from "../../styles/colors";
import { useTheme } from "@mui/material";
import CommentEditBox from "./CommentEditBox";

/**
 * This is the common grid style
 */
export const commonGridStyle = {
  "&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell": {
    py: "5px",
  },
  "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": {
    py: "10px",
  },
  "&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell": {
    py: "12px",
  },
  borderRadius: "0px 0px 5px 5px",
};

/**
 * This function counts the flag property values
 * @param {Array} data - The data to count the flag property values for
 * @returns {Object} - The object with the counts
 */
export function countFlagPropertyValues(data) {
  const valueCounts = {};
  for (const item of data) {
    const value = item.value;
    if (valueCounts[value] === undefined) {
      valueCounts[value] = 1;
    } else {
      valueCounts[value]++;
    }
  }
  return valueCounts;
}

/**
 * This function returns the icon for the value
 * @param {Number} value - The value to get the icon for
 * @param {Boolean} forCount - If the icon is for the count
 * @returns {JSX.Element} - The icon element
 */
export const GetIcon = (value, forCount = false) => {
  const iconStyle = { fontSize: forCount ? "1.4rem" : "1.3rem" };
  const theme = useTheme();
  switch (value) {
    case 0:
      return <CheckCircleIcon style={{ ...iconStyle, color: "green" }} />;
    case 1:
      return <WarningIcon style={{ ...iconStyle, color: "#FDD835" }} />;
    case 2:
      return <RemoveCircleIcon style={{ ...iconStyle, color: "red" }} />;
    case 3:
      return (
        <CancelIcon
          style={{
            ...iconStyle,
            color: theme.palette.mode === "dark" ? "white" : "black",
          }}
        />
      );
    default:
      return (
        <HelpOutlineIcon
          style={{
            ...iconStyle,
            color: "grey",
          }}
        />
      );
  }
};

/**
 * This function returns the columns for the DataGrid
 * @param {Boolean} matches - The result of the useMediaQuery hook
 * @param {Function} setCachedSelectedValue - Function to set the cached selected value
 * @param {Function} setCachedComment - Function to set the cached comment
 * @returns {Array} - An array of column objects for the DataGrid
 */
export const getFlagColumns = ({
  matches,
  setCachedSelectedValue,
  setCachedComment,
}) => {
  return [
    {
      field: "id",
      width: 100,
      editable: false,
      renderHeader: () => {
        return (
          <strong style={{ color: colors.balanceSumTextColor }}>ID</strong>
        );
      },
    },
    {
      field: "stage",
      headerName: "Stage",
      width: 100,
      editable: false,
      renderHeader: () => {
        return (
          <strong style={{ color: colors.balanceSumTextColor }}>Stage</strong>
        );
      },
    },
    {
      field: "value",
      width: 150,
      editable: false,
      renderCell: (params) => GetIcon(params.row.value),
      renderHeader: () => {
        return (
          <strong style={{ color: colors.balanceSumTextColor }}>Value</strong>
        );
      },
    },
    {
      field: "name",
      headerAlign: "center",
      width: matches ? 500 : 700,
      flex: 1,
      editable: false,
      renderCell: (params) => {
        return (
          <CustomToolTip
            title={params.row.definition?.toString()}
            placement="right"
            leaveDelay={200}
          >
            <span>{params?.value?.toString()}</span>
          </CustomToolTip>
        );
      },
      renderHeader: () => {
        return (
          <strong style={{ color: colors.balanceSumTextColor }}>Name</strong>
        );
      },
    },
    {
      field: "comment",
      headerAlign: "center",
      width: matches ? 300 : 400,
      flex: 1,
      editable: true,
      renderEditCell: (params) => {
        return (
          <CommentEditBox
            params={params}
            setCachedSelectedValue={setCachedSelectedValue}
            setCachedComment={setCachedComment}
          />
        );
      },
      renderHeader: () => {
        return (
          <strong style={{ color: colors.balanceSumTextColor }}>
            Kommentar
          </strong>
        );
      },
      renderCell: (params) => {
        const isCommentLarge = params.row.comment?.length > 30;
        return (
          <CustomToolTip
            title={isCommentLarge ? params.row.comment?.toString() : ""}
            leaveDelay={200}
          >
            <span>
              {isCommentLarge
                ? params?.row.comment.slice(0, 30) + "..."
                : params.row.comment}
            </span>
          </CustomToolTip>
        );
      },
    },
    {
      field: "original_value",
      width: 70,
      editable: false,
      renderCell: (params) => {
        // Check if original_value and value are not the same
        if (params.row.original_value !== params.row.value) {
          return GetIcon(params.row.original_value);
        }
        return GetIcon(null);
      },
      renderHeader: () => {
        return (
          <strong style={{ color: colors.balanceSumTextColor }}>
            Original
          </strong>
        );
      },
    },
  ];
};
