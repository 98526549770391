import * as React from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

export default function DeleteAlertDialog({
  openDeleteDialog,
  setOpenDeleteDialog,
  internalRows,
  setInternalRows,
  onDeleteRow,
  wantToDeleteRowId,
  setWantToDeleteRowId,
  color,
}) {
  const handleClose = () => {
    setOpenDeleteDialog(false);
    setWantToDeleteRowId(null);
  };
  return (
    <Dialog
      open={openDeleteDialog}
      onClose={handleClose}
      aria-labelledby="FO-delete-alert-dialog-title"
      aria-describedby="FO-deletealert-dialog-description"
    >
      <DialogTitle
        id="FO-deletealert-dialog-title"
        sx={{
          margin: "1rem 0rem",
          backgroundColor: color ? "#3c3c3c" : "teal",
          color: "white",
        }}
      >
        {"⚠️ Delete Caution"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="FO-deletealert-dialog-description">
          Are you sure you want to delete this data?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Disagree</Button>
        <Button
          onClick={() => {
            setInternalRows(
              internalRows.filter((row) => row.id !== wantToDeleteRowId)
            );
            onDeleteRow(
              wantToDeleteRowId,
              internalRows.find((row) => row.id === wantToDeleteRowId),
              internalRows
            );
            setOpenDeleteDialog(false);
          }}
          autoFocus
        >
          Agree
        </Button>
      </DialogActions>
    </Dialog>
  );
}

DeleteAlertDialog.propTypes = {
   openDeleteDialog: PropTypes.bool.isRequired,
   setOpenDeleteDialog: PropTypes.func.isRequired,
   internalRows: PropTypes.array.isRequired,
   setInternalRows: PropTypes.func.isRequired,
   onDeleteRow: PropTypes.func.isRequired,
   wantToDeleteRowId: PropTypes.number,
   setWantToDeleteRowId: PropTypes.func.isRequired,
   color: PropTypes.string,
};