import React, { useEffect, useState } from 'react';
import { validateExcelFile } from "../../../api";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import PropTypes from "prop-types";
import { Box } from "@mui/system";



import CompanySelection from "../../CompanySelection";
import {Button} from "@mui/material";
import {toast} from "sonner";

const CompanyInfo = ({ company, onCompanyUpdate }) => {
    const { company_name, company_id } = company;
    const handleUnsetCompany = () => {
        onCompanyUpdate(null, company_name); // Sets company_id to null and retains the company name
    };


    return (
        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-start', gap: '10px'}}>
            <div style={{minWidth: '250px'}}>{company_name}</div>
            {!company_id ? (
                <CompanySelection
                    onCompanySelected={(id, name) => {
                        onCompanyUpdate(id, name);
                    }}
                    initialCompanyName={company_name}
                />
            ) : (
                <>
                    <Button href="#" style={{fontWeight: 'bold', color: 'blue', textDecoration: 'none'}} onClick={(e) => {
                        e.preventDefault(); // Prevent the default link action
                        // Place any action you want to perform when the ID is clicked
                    }}>
                        {company_id}
                    </Button>
                    <Button variant="outlined" size="small" onClick={handleUnsetCompany}>
                        Unset
                    </Button>
                </>
            )}
        </div>
    );
};

CompanyInfo.propTypes = {
    company: PropTypes.object.isRequired,
    onCompanyUpdate: PropTypes.func.isRequired,
}


const ValidateCompaniesOfRiskExcel = ({companyId, documentId, onValidation}) => {
    const [validationResponse, setValidationResponse] = useState(null);
    const [error, setError] = useState(null);

    useEffect(() => {
        const validateCompaniesOfRiskExcel = async () => {
            try {
                const response = await validateExcelFile(companyId, documentId);
                setValidationResponse(response);
            } catch (error) {
                console.error(error);
                toast.error("An error occurred while validating the companies of the risk excel file");
                setError(error);
            }
        };
        validateCompaniesOfRiskExcel();
    }, [companyId, documentId]);


    useEffect(() => {
        // This effect checks if all companies have an ID and calls onValidation with the result
        onValidation(validationResponse)
    }, [validationResponse]); // Depends on validationResponse and onValidation

    if (!validationResponse && !error) {
        return (
            <Stack spacing={1}>
                <Skeleton variant="rectangular" width={210} height={30} />
                <Skeleton variant="rectangular" width={210} height={30} />
                <Skeleton variant="rectangular" width={210} height={30} />
                <Skeleton variant="rectangular" width={210} height={30} />
            </Stack>
        );
    }
    if (error) {
        return (<div> There is an error reading the excel file.</div>);
    }



    const handleCompanyUpdate = (id, name, isMain = true, index = null) => {
        setValidationResponse(prevState => {
            const newState = { ...prevState };
            if (isMain) {
                newState.main = { ...prevState.main, company_id: id, company_name: prevState.main.company_name };
            } else {
                newState.related[index] = { ...prevState.related[index], company_id: id, company_name: prevState.related[index].company_name };
            }
            return newState;
        });
    };

    return (
        <Box sx={{ justifyContent: 'flex-start', display: 'flex', flexDirection: 'column', gap: '12px' }}>
            <CompanyInfo
                company={validationResponse.main}
                onCompanyUpdate={(id, name) => handleCompanyUpdate(id, name)}
            />
            {validationResponse.related.map((company, index) => (
                <CompanyInfo
                    key={company.id}
                    company={company}
                    onCompanyUpdate={(id, name) => handleCompanyUpdate(id, name, false, index)}
                />
            ))}
        </Box>
    );
};

ValidateCompaniesOfRiskExcel.propTypes = {
    companyId: PropTypes.string.isRequired,
    documentId: PropTypes.string.isRequired,
    onValidation: PropTypes.func.isRequired,
};

export default ValidateCompaniesOfRiskExcel;
