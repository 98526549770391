import React, { useState } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { patchTranscriptSpeaker } from "../../api";
import { useFetchTranscriptSpeaker } from "./hooks";
import SpeakerItem from './SpeakerItem';
import PropTypes from "prop-types";

const Speaker = ({ ddCallId }) => {
    const { data: speakers, isLoading: isLoadingSpeaker } = useFetchTranscriptSpeaker(ddCallId);
    const [open, setOpen] = useState(false);
    const queryClient = useQueryClient();

    const updateSpeakerMutation = useMutation({
        mutationFn: patchTranscriptSpeaker,
        onSuccess: () => {
            queryClient.invalidateQueries(['speaker', ddCallId]);
        },
    });

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const handleUpdate = (speaker, newSpeaker, employeeId, peopleId) => {
        updateSpeakerMutation.mutate({
            ddCallId,
            data: {
                speaker,
                new_speaker: newSpeaker,
                employee_id: employeeId,
                people_id: peopleId,
            }
        });
    };

    return (
        <div>
            <Button color="primary" variant="outlined" onClick={handleOpen}>
                Teilnehmer
            </Button>
            <Dialog open={open} onClose={handleClose} maxWidth="lg" fullWidth>
                <DialogTitle>Teilnehmer aktualisieren</DialogTitle>
                <DialogContent>
                    {speakers?.map((s) => (
                        <SpeakerItem
                            key={s.id}
                            speaker={s}
                            onSpeakerUpdate={handleUpdate}
                            isLoading={isLoadingSpeaker || updateSpeakerMutation.isLoading}
                        />
                    ))}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary" variant="outlined" >Close</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default Speaker;

Speaker.propTypes = {
    ddCallId: PropTypes.number.isRequired,
}