import React, { useState, useRef, useEffect } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Box, Grid, IconButton, TextField, Typography } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import DeleteIcon from "@mui/icons-material/Delete";
import { deleteQA, patchQuestionAnswer } from "../../api";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

const EditQaComponent = ({
  qa,
  showAgentAnswer,
  showQuestionsOnly,
  onSuccessUpdate,
  ddCallId,
}) => {
  const queryClient = useQueryClient();
  const [question, setQuestion] = useState(qa.question);
  const [answer, setAnswer] = useState(qa.answer);
  const [agentAnswer, setAgentAnswer] = useState(qa.agent_answer);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [isQuestionEditable, setIsQuestionEditable] = useState(false);
  const [answerRows, setAnswerRows] = useState(1);
  const [category, setCategory] = useState(qa.category);

  useEffect(() => {
    setCategory(qa.category || "Keine Kategorie");
  }, [qa]);

  const handleDelete = () => {
    deleteQa.mutate({ qaId: qa.id });
  };

  const handleKeyPress = (event) => {
    if (event.key === "Escape") {
      setIsQuestionEditable(false);
    }
  };

  useEffect(() => {
    if (isQuestionEditable) {
      window.addEventListener("keydown", handleKeyPress);
    }

    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, [isQuestionEditable]);

  useEffect(() => {
    setQuestion(qa.question);
    setAnswer(qa.answer);
    setAgentAnswer(qa.agent_answer);
  }, [qa]);

  const questionInputRef = useRef(null);

  useEffect(() => {
    if (isQuestionEditable) {
      questionInputRef.current?.focus();
    }
  }, [isQuestionEditable]);

  useEffect(() => {
    // Use a fallback empty string if 'answer' is null
    const answerLines = answer ? answer.split("\n").length : 1;
    setAnswerRows(showAgentAnswer ? 8 : Math.max(answerLines, 4));
  }, [answer]);

  const mutation = useMutation({
    mutationFn: patchQuestionAnswer,
    onSuccess: (data) => {
      onSuccessUpdate(); // Callback to refetch data or any other action
      queryClient.invalidateQueries({ queryKey: ["qaData", ddCallId] });
    },
  });

  const deleteQa = useMutation({
    mutationFn: deleteQA,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["qaData", ddCallId] });
    },
  });

  const handleBlur = () => {
    mutation.mutate({
      qaId: qa.id,
      data: {
        question,
        answer: showQuestionsOnly ? "" : answer,
        agent_answer: agentAnswer,
        category,
      },
    });
    setIsQuestionEditable(false);
  };

  const handleArrowClick = () => {
    mutation.mutate({
      qaId: qa.id,
      data: {
        question,
        answer: showQuestionsOnly ? "" : agentAnswer,
        agent_answer: agentAnswer,
      },
    });
  };

  return (
    <div
      style={{
        border: "1px solid lightgray",
        borderRadius: "10px",
        padding: "5px 10px",
        marginBottom: "10px",
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center" }}>
        {isQuestionEditable ? (
          <TextField
            ref={questionInputRef}
            label="Question"
            value={question}
            onChange={(e) => setQuestion(e.target.value)}
            onBlur={handleBlur}
            onKeyDown={handleKeyPress}
            fullWidth
            margin="normal"
          />
        ) : (
          <>
            <Typography
              variant="h6"
              sx={{ fontWeight: "bold", cursor: "pointer", flexGrow: 1 }}
              onClick={() => setIsQuestionEditable(true)}
            >
              {question}
            </Typography>
            <IconButton
              onClick={() => setShowDeleteDialog(true)}
              label="delete"
              aria-label="delete"
              size="small"
              sx={{ color: "lightgray" }}
            >
              <DeleteIcon />
            </IconButton>
          </>
        )}
      </Box>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} md={showAgentAnswer ? 5 : 12}>
            {showQuestionsOnly ? (
              ""
            ) : (
              <TextField
                label="Antwort"
                value={showQuestionsOnly ? "" : answer}
                onChange={(e) => setAnswer(e.target.value)}
                onBlur={handleBlur}
                fullWidth
                multiline
                rows={answerRows}
                margin="normal"
                InputLabelProps={{
                  shrink: !!answer, // force the label to shrink if the answer is not empty
                }}
              />
            )}
          </Grid>
          {showAgentAnswer && (
            <>
              <Grid item xs={12} md={1}>
                <IconButton
                  onClick={handleArrowClick}
                  color="primary"
                  data-testid="EditQaComponent-IconButton"
                >
                  <ArrowBackIcon />
                </IconButton>
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField
                  label="AI"
                  value={agentAnswer}
                  onChange={(e) => setAgentAnswer(e.target.value)}
                  onBlur={handleBlur}
                  fullWidth
                  multiline
                  rows={answerRows} // Ensure this is the same as the other TextField
                  margin="normal" // Ensure margins are consistent
                />
              </Grid>
            </>
          )}
        </Grid>
      </Box>

      <Dialog
        open={showDeleteDialog}
        // onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        data-testid="QuestionView-Dialog"
      >
        <DialogTitle id="alert-dialog-title">{"Bestätigung"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Wollen Sie wirklich löschen?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setShowDeleteDialog(false)}
            color="primary"
            data-testid="QuestionView-Dialog-Button-Close"
          >
            Cancel
          </Button>
          <Button
            onClick={handleDelete}
            color="primary"
            variant="contained"
            autoFocus
            data-testid="QuestionView-Dialog-Button-Confirm"
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default EditQaComponent;

EditQaComponent.propTypes = {
  qa: PropTypes.shape({
    id: PropTypes.number.isRequired,
    question: PropTypes.string.isRequired,
    answer: PropTypes.string,
    agent_answer: PropTypes.string,
    category: PropTypes.string,
  }).isRequired,
  onSuccessUpdate: PropTypes.func.isRequired,
  showAgentAnswer: PropTypes.bool.isRequired,
  ddCallId: PropTypes.string.isRequired,
};
