import { useState, useEffect } from "react";

const calculations = [
  {
    result: "pl_13_proforma_sources_4",
    formula: ({
      p_c_2_adj1_proforma_sources_1,
      p_c_2_adj2_proforma_sources_2,
      proforma_sources_3,
    }) =>
      ((Number(p_c_2_adj1_proforma_sources_1) +
        Number(p_c_2_adj2_proforma_sources_2)) *
        Number(proforma_sources_3)) /
      100,
  },
  {
    result: "pl_13_proforma_sources_8",
    formula: ({
      p_c_2_adj1_proforma_sources_5,
      p_c_2_adj2_proforma_sources_6,
      proforma_sources_7,
    }) =>
      ((Number(p_c_2_adj1_proforma_sources_5) +
        Number(p_c_2_adj2_proforma_sources_6)) *
        Number(proforma_sources_7)) /
      100,
  },
  {
    result: "pl_13_proforma_uses_10",
    formula: ({
      p_c_2_adj1_proforma_uses_7,
      p_c_2_adj2_proforma_uses_8,
      proforma_uses_9,
    }) =>
      ((Number(p_c_2_adj1_proforma_uses_7) +
        Number(p_c_2_adj2_proforma_uses_8)) *
        Number(proforma_uses_9)) /
      100,
  },
];

// Custom hook for handling dynamic calculations
export const useDynamicCalculations = (inputs) => {
  const [values, setValues] = useState(inputs);
  const [isValid, setIsValid] = useState(false);
  const [sourcesSum, setSourcesSum] = useState(0);
  const [usesSum, setUsesSum] = useState(0);
  const [changeOfActiva, setChangeOfActiva] = useState(0);
  const [changeOfPassiva, setChangeOfPassiva] = useState(0);
  const [reset, setReset] = useState(false); // New state to track reset

  // Function to handle changes in NumberField
  const handleChange = (id) => (value) => {
    setValues((values) => {
      const newValues = {
        ...values,
        [id]: value === "" ? 0 : Number(value),
      };
      return calculateNewValues(newValues);
    });
  };
  const calculateNewValues = (values) => {
    let newValues = { ...values };
    calculations.forEach(({ result, formula }) => {
      const newValue = formula(newValues);
      if (newValues[result] !== newValue) {
        newValues[result] = newValue;
      }
    });
    return newValues;
  };

  useEffect(() => {
    const sourceIds = Object.keys(values).filter((id) =>
      [
        "p_c_2_adj1_proforma_sources_1",
        "p_c_2_adj2_proforma_sources_2",
        "p_c_2_adj1_proforma_sources_5",
        "p_c_2_adj2_proforma_sources_6",
        "p_a_ii_proforma_sources_9",
        "p_c_2_adj2_proforma_sources_10",
        "p_c_8_adj1_proforma_sources_11",
        "a_b_iv_1_proforma_sources_12",
      ].includes(id)
    );
    const useIds = Object.keys(values).filter((id) =>
      [
        "a_a_i_3_proforma_uses_1",
        "a_a_ii_2_proforma_uses_2",
        "a_b_i_3_proforma_uses_3",
        "a_b_ii_1_proforma_uses_4",
        "a_b_ii_4_proforma_uses_5",
        "a_b_iv_1_proforma_uses_6",
        "p_c_2_adj1_proforma_uses_7",
        "p_c_2_adj2_proforma_uses_8",
        "p_c_4_proforma_uses_11",
        "p_c_8_proforma_uses_12",
      ].includes(id)
    );

    const sourceIdsChangeOfActiva = Object.keys(values).filter((id) =>
      [
        "a_a_i_3_proforma_uses_1",
        "a_a_ii_2_proforma_uses_2",
        "a_b_i_3_proforma_uses_3",
        "a_b_ii_1_proforma_uses_4",
        "a_b_ii_4_proforma_uses_5",
        "a_b_iv_1_proforma_uses_6",
      ].includes(id)
    );

    const sourceIdsChangeOfPassivaAddition = Object.keys(values).filter((id) =>
      [
        "p_c_2_adj1_proforma_sources_1",
        "p_c_2_adj2_proforma_sources_2",
        "p_c_2_adj1_proforma_sources_5",
        "p_c_2_adj2_proforma_sources_6",
        "p_a_ii_proforma_sources_9",
        "p_c_2_adj2_proforma_sources_10",
        "p_c_8_adj1_proforma_sources_11",
        "a_b_iv_1_proforma_sources_12",
      ].includes(id)
    );

    const sourceIdsChangeOfPassivaSubstraction = Object.keys(values).filter(
      (id) =>
        [
          "p_c_2_adj1_proforma_uses_7",
          "p_c_2_adj2_proforma_uses_8",
          "p_c_4_proforma_uses_11",
          "p_c_8_proforma_uses_12",
        ].includes(id)
    );

    const sourcesSum = sourceIds.reduce(
      (acc, id) => acc + Number(values[id]),
      0
    );
    const usesSum = useIds.reduce((acc, id) => acc + Number(values[id]), 0);

    const changeOfActivaCalc = sourceIdsChangeOfActiva.reduce(
      (acc, id) => acc + Number(values[id]),
      0
    );

    const changeOfPassivaAddition = sourceIdsChangeOfPassivaAddition.reduce(
      (acc, id) => acc + Number(values[id]),
      0
    );

    const changeOfPassivaSubstraction =
      sourceIdsChangeOfPassivaSubstraction.reduce(
        (acc, id) => acc + Number(values[id]),
        0
      );
    const changeOfPassivaCalc =
      changeOfPassivaAddition - changeOfPassivaSubstraction;

    setChangeOfActiva(changeOfActivaCalc);
    setChangeOfPassiva(changeOfPassivaCalc);
    setSourcesSum(sourcesSum);
    setUsesSum(usesSum);
    setIsValid(
      sourcesSum === usesSum &&
        (sourcesSum >= 0 || usesSum >= 0) &&
        changeOfActivaCalc === changeOfPassivaCalc
    );
  }, [values]);

  const resetValues = () => {
    const resetInputs = Object.keys(inputs).reduce((acc, key) => {
      acc[key] = 0;
      return acc;
    }, {});
    setValues(resetInputs);
    setReset(true); // Set reset to true when resetValues is called
  };

  const updateValuesFromFetch = (newValues) => {
    setValues(calculateNewValues(newValues));
  };

  useEffect(() => {
    if (reset) {
      setIsValid(false);
      setSourcesSum(0);
      setUsesSum(0);
      setReset(false); // Set reset back to false
    }
  }, [reset]);

  return [
    values,
    handleChange,
    isValid,
    sourcesSum,
    usesSum,
    changeOfActiva,
    changeOfPassiva,
    resetValues,
    updateValuesFromFetch,
  ];
};
