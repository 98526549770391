import axios from "axios";
import {msalInstance} from "./index";

export const crefoServiceApi = axios.create({
    baseURL: process.env.REACT_APP_CREFO_API_HOST,
});

const axiosAuthMiddlewareFunc = async (config) => {
    try {
        const account = msalInstance.getAllAccounts()[0];
        if (account) {
            const request = {
                scopes: [process.env.REACT_APP_AZURE_SCOPES], // Replace with your API's scope
                account: account,
            };
            try {
                const tokenResponse = await msalInstance.acquireTokenSilent(request);
                config.headers["Authorization"] = "Bearer " + tokenResponse.accessToken;
            } catch (error) {
                try {
                    const tokenResponse = await msalInstance.acquireTokenPopup(request);
                    config.headers["Authorization"] =
                        "Bearer " + tokenResponse.accessToken;
                } catch (innerError) {
                    console.error(innerError);
                }
            }
        }
        return config;
    } catch (error) {
        // Handle token acquisition errors
        return Promise.reject(error);
    }
};
crefoServiceApi.interceptors.request.use(axiosAuthMiddlewareFunc, (error) => {
  return Promise.reject(error);
});
export const crefoAPI = {
  searchCrefo: async (searchRequestPayload) => {
    const res = await crefoServiceApi.post(
      `/crefo/search`,
      searchRequestPayload
    );
    return res.data;
  },
  getAllCrefoReports: async (crefoId) => {
    try {
      const res = await crefoServiceApi.get(`/reports/${crefoId}`);
      return res.data;
    } catch (error) {
      console.log(error);
    }
  },
  getCrefoFile: async (fileId) => {
    try {
      const res = await crefoServiceApi.get(`/file/${fileId}/pdf`, {
        responseType: "blob",
      });
      return res.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  },
  getCrefoProductAvailability: async (crefoId) => {
    try {
      const res = await crefoServiceApi.get(
        `/crefo/productavailability/${crefoId}`
      );
      return res.data;
    } catch (error) {
      console.log(error);
    }
  },
  // get crefo data
  getCrefoData: async (report_id) => {
    try {
      return  axios.get(`/crefo/${report_id}/data`);
    } catch (error) {
     console.error(error);
    }
  },
  // get company data
  getCompanyData: async (company_id) => {
    try {
      return await axios.get(`/companies/${company_id}`);
    } catch (error) {
      console.error(error);
    }
  },
  // update company data
  updateCompanyData: async (company_id, data) => {
    try {
      return await axios.patch(`/companies/${company_id}`, data);
    } catch (error) {
      console.error(error);
    }
  },
  // get industry
  getIndustryData: async (companyId) => {
    try {
      return await axios.get(`/companies/${companyId}/industry`);
    } catch (error) {
      console.log(error);
    }
  },
  requestCrefoReport: async (requestCrefoReportPayload) => {
    const res = await crefoServiceApi.post(
      `/crefo/report`,
      requestCrefoReportPayload
    );
    return res.data;
  },
  getCrefoReportData: async (reportId, categoryName) => {
    try {
      const res = await crefoServiceApi.get(
        `/report-data/${reportId}/category/${categoryName}`
      );
      return res.data;
    } catch (error) {
      console.log(error);
    }
  },
  getShareholderReportData: async (reportId) => {
    try {
      const res = await crefoServiceApi.get(
        `/crefo/shareholder/report/${reportId}`
      );
      return res.data;
    } catch (error) {
      console.log(error);
    }
  },
}
