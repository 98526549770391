import FinalOpinionPage from "../components/FinalOpinion/FinalOpinionPage";
import { Layout } from "../components/Layout/Layout";

const FinalOpinion = () => {
  return (
    <Layout>
      <FinalOpinionPage />
    </Layout>
  );
};

export default FinalOpinion;
