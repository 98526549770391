import { Box } from "@mui/material";

export const ChatBubbleWriting = ({ isRight, name, ChatIcon }) => {
  return (
    <>
      <style>
        {`
      .chat-bubble-writing-left {
        animation: bubble-appear-writing-left 0.5s ease;
        animation-delay: 1s;
        animation-fill-mode: forwards;
        height: 0px;
        opacity: 0;
      }
      .chat-bubble-writing-right {
        animation: bubble-appear-writing-right 0.5s ease;
        animation-delay: 1s;
        animation-fill-mode: forwards;
        height: 0px;
        opacity: 0;
      }

      @keyframes bubble-appear-writing-left {
        0% {
          opacity: 0;
          transform: translateY(5px) scale(0.95) translateX(-2.5%);
          height: 0px;
        }
        1% {
          height: auto;
        }
        20% {
          opacity: 0.9;
        }
        100% {
          opacity: 1;
          transform: translateY(0px) scale(1) translateX(0px);
          height: auto;
        }
      }
      @keyframes bubble-appear-writing-right {
        0% {
          opacity: 0;
          transform: translateY(5px) scale(0.95) translateX(2.5%);
          height: 0px;
        }
        1% {
          height: auto;
        }
        20% {
          opacity: 0.9;
        }
        100% {
          opacity: 1;
          transform: translateY(0px) scale(1) translateX(0px);
          height: auto;
        }
      }
    `}
      </style>
      <Box
        className={`chat-bubble-writing-${isRight ? "right" : "left"}`}
        sx={{
          display: "flex",
          justifyContent: isRight ? "flex-end" : "flex-start",
          paddingBottom: "10px",
        }}
      >
        <Box sx={{ maxWidth: "800px" }}>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: isRight ? "flex-end" : "space-between",
              fontWeight: "bold",
              color: isRight ? "teal" : "gray",
              fontSize: "14px",
              paddingBottom: "3px",
            }}
          >
            <Box sx={{ display: "flex", gap: "3px" }}>
              {!isRight && ChatIcon && (
                <ChatIcon
                  sx={{ height: "21px", width: "21px" }}
                  data-testid="chat-icon"
                />
              )}
              {name}
              {isRight && ChatIcon && (
                <ChatIcon
                  color="inherit"
                  sx={{ color: "teal", height: "21px", width: "21px" }}
                  data-testid="chat-icon"
                />
              )}
            </Box>
          </Box>
          <Box
            sx={{
              marginBottom: "10px",
              fontSize: "14px",
              padding: "10px 12px",
              backgroundColor: isRight ? "rgb(193, 242, 237)" : "#f5f5f5",
              borderRadius: "10px",
              position: "relative",
              width: "fit-content",
            }}
          >
            <style>
              {`
              
              .bouncing-dot1 {
                animation: bounce 1s infinite;
                animation-delay: 0.1s;
              }
              .bouncing-dot2 {
                animation: bounce 1s infinite;
                animation-delay: 0.2s;
              }
              .bouncing-dot3 {
                animation: bounce 1s infinite;
                animation-delay: 0.3s;
              }

              @keyframes bounce {
                0% {
                  transform: translateY(0px);
                  background-color: gray;
                }
                90% {
                  transform: translateY(2px);
                  background-color: #c7c7c7;
                }
                100% {
                  transform: translateY(0px);
                  background-color: gray;
                }
              }

            `}
            </style>
            <Box sx={{ display: "flex", gap: "3px" }}>
              <Box
                sx={{
                  width: "4px",
                  height: "4px",
                  backgroundColor: "gray",
                  borderRadius: "50%",
                }}
                className="bouncing-dot1"
                data-testid="bouncing-dot"
              />
              <Box
                sx={{
                  width: "4px",
                  height: "4px",
                  backgroundColor: "gray",
                  borderRadius: "50%",
                }}
                className="bouncing-dot2"
                data-testid="bouncing-dot"
              />
              <Box
                sx={{
                  width: "4px",
                  height: "4px",
                  backgroundColor: "gray",
                  borderRadius: "50%",
                }}
                className="bouncing-dot3"
                data-testid="bouncing-dot"
              />
            </Box>
            {/** Bubble design */}
            {isRight ? (
              <Box
                sx={{
                  position: "absolute",
                  bottom: "-10px",
                  right: "0",
                  width: "20px",
                  height: "20px",
                  backgroundColor: "rgb(151, 219, 212)",
                  zIndex: "-1",
                  overflow: "hidden",
                }}
              >
                <Box
                  sx={{
                    width: "40px",
                    height: "20px",
                    backgroundColor: "white",
                    transform: "translate(-20%, 70%) rotate(24deg)",
                  }}
                />
              </Box>
            ) : (
              <Box
                sx={{
                  position: "absolute",
                  bottom: "-10px",
                  left: "0",
                  width: "20px",
                  height: "20px",
                  backgroundColor: "#e7e7e7",
                  zIndex: "-1",
                  overflow: "hidden",
                }}
              >
                <Box
                  sx={{
                    width: "40px",
                    height: "20px",
                    backgroundColor: "white",
                    transform: "translate(-20%, 70%) rotate(-24deg)",
                  }}
                />
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </>
  );
};
