import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Typography, Box, CircularProgress, IconButton } from "@mui/material";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import { DataGrid } from "@mui/x-data-grid";
import { riskStudioColors as colors } from "../../styles/colors";
import { crefoAPI } from "../../crefoServiceAPI.js";
import ErrorIcon from "@mui/icons-material/Error";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import CustomNoRowsOverlay from "../Utils/CustomNoRowsOverlay.js";
import { CrefoReportDataFiller } from "./CrefoReportDataFiller.js";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { get } from "lodash";
import ArticleIcon from "@mui/icons-material/Article";

// THIS IS THE CREFO ALL SELECTED REPORTS COMPONENT
const CrefoAllReports = ({ companyId, crefoId, closeOpenCrefoModal }) => {
  const [loadingFile, setLoadingFile] = useState(null);
  const [crefoReports, setCrefoReports] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [defaultSortModel, setDefaultSortModel] = useState([
    { field: "createdAt", sort: "desc" },
  ]);
  const [showDataFillerDialog, setShowDataFillerDialog] = useState(false); // State for showing report data filler dialog
  const [selectedRow, setSelectedRow] = useState(null);
  const [companyData, setCompanyData] = useState(null);

  // State to store selected row
  get(crefoReports, "0.id", null);
  // SIDE EFFECT: This fetches the data for the company
  useEffect(() => {
    const getCompanyData = (companyId) => {
      crefoAPI
        .getCompanyData(companyId)
        .then((res) => {
          setCompanyData(res.data);
          return res;
        })
        .catch((err) => {
          console.error("Error fetching company data!", err);
          setError("Error fetching company data!");
        });
    };
    getCompanyData(companyId);
  }, [companyId]);

  // SIDE EFFECT: This fetches the data for all crefo reports accosiated with the company
  useEffect(() => {
    setLoading(true);
    crefoAPI
      .getAllCrefoReports(crefoId)
      .then((data) => {
        const transformedData = data?.reports.map((report) => ({
          id: report.id,
          identificationNumber: report.identification_number,
          productType: report.product_type,
          legitimateInterest: report.legitimate_interest,
          version: report.version,
          referenceNumber: report.reference_number,
          createdAt: report.created_at,
          infoFill: "Info Fill",
          actions: "Actions",
          s3PathJson: report.s3_path_json,
          s3PathPdf: report.s3_path_pdf,
          s3PathXml: report.s3_path_xml,
          provider: report.provider,
        }));
        setCrefoReports(transformedData);
        setLoading(false);
      })
      .catch((_) => {
        setError("Error fetching Crefo reports");
        setLoading(false);
      });
  }, [crefoId]);

  useEffect(() => {
    setDefaultSortModel([{ field: "createdAt", sort: "desc" }]);
  }, [crefoReports]);

  const getCrefoData = (reportId) => {
    crefoAPI
      .getCrefoData(reportId)
      .then((res) => {
        setSelectedRow(res.data);
        setShowDataFillerDialog(true);
        return res;
      })
      .catch((error) => {
        console.error("Error fetching crefo data", error);
      });
  };

  const openDataFillerDialog = ({ id: reportId }) => {
    getCrefoData(reportId);
  };

  const closeDataFillerDialog = () => {
    setShowDataFillerDialog(false);
    setSelectedRow(null);
  };

  // HELPER FUNCTION: This function is called when the user clicks on the view button to view the report in new tab as pdf
  const getFile = (fileId) => {
    setLoadingFile(fileId);
    crefoAPI
      .getCrefoFile(fileId)
      .then((data) => {
        const fileURL = URL.createObjectURL(data);
        window.open(fileURL);
      })
      .catch((_) => {
        setError("ERROR:: Error in loading file. Try again later.");
      })
      .finally(() => {
        setLoadingFile(null);
      });
  };

  // ERROR STATE MANAGEMENT
  if (error) {
    return (
      <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
        <Typography
          color="error"
          sx={{ display: "flex", alignItems: "center" }}
        >
          <ErrorIcon sx={{ marginRight: "5px", fontSize: "20px" }} />
          {error}
        </Typography>
      </Box>
    );
  }

  // SUCCESS STATE MANAGEMENT
  if (crefoReports || loading) {
    // GRID ROWS FOR CREFO REPORTS
    const crefoReportRows = crefoReports || [];
    // GRID COLUMNS FOR CREFO REPORTS
    const crefoReportsColumns = [
      {
        field: "id",
        flex: 0.1,
        renderCell: ({ value }) => {
          return <span>{value}</span>;
        },
        renderHeader: (_) => {
          return (
            <strong style={{ color: colors.balanceSumTextColor }}>ID</strong>
          );
        },
      },
      {
        field: "productType",
        flex: 0.2,
        renderCell: ({ value }) => {
          return <span>{value || "-"}</span>;
        },
        renderHeader: (_) => {
          return (
            <strong style={{ color: colors.balanceSumTextColor }}>
              Produkt
            </strong>
          );
        },
      },
      {
        field: "legitimateInterest",
        flex: 0.2,
        renderCell: ({ value }) => {
          return <span>{value || "-"}</span>;
        },
        renderHeader: (_) => {
          return (
            <strong style={{ color: colors.balanceSumTextColor }}>
              Berechtigtes Interesse
            </strong>
          );
        },
      },
      {
        field: "version",
        flex: 0.15,
        renderCell: ({ value }) => {
          return <span>{value}</span>;
        },
        renderHeader: (_) => {
          return (
            <strong style={{ color: colors.balanceSumTextColor }}>
              Version
            </strong>
          );
        },
      },
      {
        field: "referenceNumber",
        flex: 0.15,
        renderCell: ({ value }) => {
          return <span>{value}</span>;
        },
        renderHeader: (_) => {
          return (
            <strong style={{ color: colors.balanceSumTextColor }}>
              Referenznummer
            </strong>
          );
        },
      },
      {
        field: "createdAt",
        headerName: "Created At",
        flex: 0.2,
        renderCell: ({ value }) => {
          const formattedDate = value
            ? new Intl.DateTimeFormat("en-US", {
                day: "2-digit",
                month: "2-digit",
                year: "numeric",
                hour: "2-digit",
                minute: "2-digit",
              }).format(new Date(value))
            : "-";
          return <span>{formattedDate}</span>;
        },
        renderHeader: (_) => {
          return (
            <strong style={{ color: colors.balanceSumTextColor }}>
              Erstellt am
            </strong>
          );
        },
      },

      {
        field: "actions",
        width: 100,
        disableColumnMenu: true,
        sortable: false,
        renderCell: ({ row }) => {
          const { id, s3PathPdf } = row;
          // IF NO FILE IS AVAILABLE
          if (!s3PathPdf) {
            return (
              <IconButton disabled={true}>
                <PictureAsPdfIcon color="error" sx={{ fontSize: 26 }} />
              </IconButton>
            );
          }

          return (
            <Box width="100%" textAlign="center">
              <IconButton
                onClick={() => {
                  getFile(id);
                }}
              >
                {loadingFile && loadingFile === id ? (
                  <CircularProgress size={16} />
                ) : (
                  <PictureAsPdfIcon color="primary" sx={{ fontSize: 26 }} />
                )}
              </IconButton>
            </Box>
          );
        },
        renderHeader: (_) => {
          return (
            <strong style={{ color: colors.balanceSumTextColor }}>Sicht</strong>
          );
        },
      },
      {
        field: "fillData",
        width: 100,
        headerName: "Fill Data",
        disableColumnMenu: true,
        sortable: false,
        renderCell: ({ row }) => {
          return (
            <IconButton
              data-testid="Fill Data"
              onClick={() => openDataFillerDialog(row)}
            >
              <FileCopyIcon />
            </IconButton>
          );
        },
        renderHeader: () => (
          <strong style={{ color: colors.balanceSumTextColor }}>
            Fill Data
          </strong>
        ),
      },
    ];

    return (
      <Box sx={{ height: 400, width: "100%" }}>
        <DataGrid
          rows={crefoReportRows}
          columns={crefoReportsColumns}
          sortModel={defaultSortModel}
          onSortModelChange={(newSortModel) =>
            setDefaultSortModel(newSortModel)
          }
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 10,
              },
            },
          }}
          slots={{
            noRowsOverlay: CustomNoRowsOverlay,
          }}
          pageSizeOptions={[10]}
          disableRowSelectionOnClick
        />

        <Dialog
          open={showDataFillerDialog}
          onClose={closeDataFillerDialog}
          fullWidth
          maxWidth="lg"
        >
          <DialogTitle sx={{ display: "flex", alignItems: "center" }}>
            <ArticleIcon sx={{ marginRight: "10px" }} />
            <Typography variant="h6">Fill Report Data</Typography>
          </DialogTitle>
          <DialogContent>
            {selectedRow && ( // Conditional rendering for report data filler modal
              <Box>
                <CrefoReportDataFiller
                  onBackClick={closeDataFillerDialog}
                  companyId={companyId}
                  companyType={companyData?.company_type || "company type"}
                  companyData={{
                    nace_code_level_three: companyData.nace_code_level_three,
                    gics_code_level_three: companyData.gics_code_level_three,
                    name: companyData.name,
                    hq_street: companyData.hq_street,
                    hq_zip_code: companyData.hq_zip_code,
                    hq_city: companyData.hq_city,
                    hq_country: companyData.hq_country,
                    website: companyData.website,
                    taxId: companyData.taxid,
                    local_court: companyData.local_court,
                    register_number: companyData.register_number,
                  }}
                  reportData={{
                    nace_code_level_three: selectedRow.nace.code_level_three,
                    nace_code_level_three_name:
                      selectedRow.nace.name_level_three_de,
                    gics: selectedRow.gics,
                    name: selectedRow.name,
                    hq_street: selectedRow.hq_street,
                    hq_zip_code: selectedRow.hq_zip_code,
                    hq_city: selectedRow.hq_city,
                    hq_country: selectedRow.hq_country,
                    website: selectedRow.website,
                    taxId: selectedRow.taxid,
                    local_court: selectedRow.local_court,
                    register_number: selectedRow.register_number,
                  }}
                />
              </Box>
            )}
          </DialogContent>
        </Dialog>
      </Box>
    );
  }
};

CrefoAllReports.propTypes = {
  companyId: PropTypes.number.isRequired,
  crefoId: PropTypes.string.isRequired || PropTypes.number.isRequired,
  closeOpenCrefoModal: PropTypes.func.isRequired,
};

export default CrefoAllReports;
