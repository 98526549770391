import {Box, Button, Modal, Tooltip} from "@mui/material";
import HistoryEduRoundedIcon from "@mui/icons-material/HistoryEduRounded";
import SendRoundedIcon from "@mui/icons-material/SendRounded";
import {useEffect, useState} from "react";
import {fetchAiMessageThread, postAiQuery} from "../../api";
import {toast} from "sonner";
import {ThreadMessage} from "./ThreadMessage";
import PropTypes from "prop-types";

export const MessageTextgenerationModal = ({
                                               keyName, keyData, open, onClose, companyId, creditProjectId
                                           }) => {
    const [generatedTextThreadMessages, setGeneratedTextThreadMessages] = useState([]);
    const [loadingMessages, setLoadingMessages] = useState(false);
    const [loadingQuery, setLoadingQuery] = useState(false);
    const [errorOverlayText, setErrorOverlayText] = useState("");
    const [prompt, setPrompt] = useState("");

    const fetchMessages = async () => {
        fetchAiMessageThread(companyId, creditProjectId, keyName)
            .then((res) => {
                setGeneratedTextThreadMessages(res);
                setLoadingMessages(false);
                if (res.length === 0) {
                    setPrompt(keyData.default_prompt)
                }
            })
            .catch((err) => {
                console.error(err);
                if (err.response.data.detail.includes("There were no documents found for company ID")) {
                    toast.error("The necessary documents were not found while trying to create an assistant for this field's context. " + "Please upload the necessary documents to the company in CSP and try again.");
                } else {
                    toast.error("Error loading messages");
                }
                setLoadingMessages(false);
                setErrorOverlayText("Error loading messages or assistant");
            });
    };

    useEffect(() => {
        if (open) {
            setLoadingMessages(true);
            setErrorOverlayText("");
            fetchMessages();
        }
    }, [open]);


    const handlePromptChange = (e) => {
        e.preventDefault();
        setPrompt(e.target.value);
    };


    const handlePostMessage = () => {
        if (prompt === "") {
            toast.error("Please enter a prompt");
        } else {
            setLoadingQuery(true);
            // create temp id for displaying
            let messageId = 0;
            if (generatedTextThreadMessages.length > 0) {
                messageId = generatedTextThreadMessages[0].id + 1000000;
            } else {
                messageId = 1000000;
            }
            // create temp date for displaying
            const localDate = new Date();
            const userTimezoneOffset = localDate.getTimezoneOffset() * 60000;
            const normalisedDate = new Date(localDate.getTime() + userTimezoneOffset);
            const newMessage = {
                id: messageId, created_at: normalisedDate.toUTCString(), message: {
                    type: "human", data: {content: prompt}, sources: [],
                }
            };
            setGeneratedTextThreadMessages((prev) => {
                return [newMessage, ...prev];
            });
            // TODO: change context from hardcoded to dynamically adapted once multiple contexts are available
            setPrompt("")
            postAiQuery(companyId, creditProjectId, keyName, prompt)
                .then((res) => {
                    fetchMessages().then(() => {
                        setLoadingQuery(false);
                    })
                        .catch((pollErr) => {
                                toast.error("Error generating an answer, try again later");

                            setLoadingQuery(false);
                        });
                })
                .catch((err) => {
                    toast.error("Error creating the query, try again later");
                    setGeneratedTextThreadMessages((prev) => {
                        return prev.slice(1);
                    });
                    setLoadingQuery(false);
                });
        }
    };

    const promptBoxColor = "#eaeaea";
    return (<Modal
        open={open}
        onClose={onClose}
        aria-labelledby="text-generation-modal-title"
        sx={{}}
    >
        <Box
            sx={{
                padding: "10px",
                backgroundColor: "white",
                border: "2px solid lightgray",
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                maxHeight: "90vh",
                height: "600px",
                width: "1000px",
                maxWidth: "90vw",
                display: "flex",
                flexDirection: "column",
            }}
        >
            <Box sx={{marginBottom: "15px", display: "flex"}}>
                <HistoryEduRoundedIcon sx={{marginRight: "4px"}}/>
                <Box sx={{display: "flex", gap: "8px"}}>
                    <Box sx={{fontWeight: "bold"}}>{keyData.name}</Box>
                    <Box
                        sx={{
                            height: "100%", display: "flex", flexDirection: "column", justifyContent: "center",
                        }}
                    >
                        <Box
                            sx={{
                                height: "3px", width: "3px", borderRadius: "50%", backgroundColor: "gray",
                            }}
                        />
                    </Box>
                    <Box sx={{fontSize: "15px", color: "gray"}}>
                        Automatically generate content and copy and paste it
                    </Box>
                </Box>
            </Box>
            <Box
                sx={{
                    width: "100%",
                    overflowY: "scroll",
                    display: "flex",
                    flexDirection: "column-reverse",
                    position: "relative",
                    borderBottom: "1px solid lightgray",
                    padding: "10px",
                    borderRadius: "5px",
                    marginBottom: "15px",
                    flexGrow: "1",
                }}
            >
                {errorOverlayText !== "" ? (<Box
                    sx={{
                        color: "gray",
                        fontStyle: "italic",
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        fontSize: "14px",
                    }}
                >
                    {errorOverlayText}
                </Box>) : (<>
                    {loadingMessages ? (<Box
                        sx={{
                            color: "gray",
                            fontStyle: "italic",
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                            fontSize: "14px",
                        }}
                    >
                        Loading Conversation...
                    </Box>) : (<>
                        {generatedTextThreadMessages.length === 0 ? (<ThreadMessage
                            message={{
                                message: {
                                    data: {content: "👋 Hallo!" + "\nℹ️ Senden sie bitte eine Anfrage ab, damit ich eine Antwort für " + "das Feld erstellen kann. \nSollte die Antwort nicht ihren Vorstellungen entsprechen, " + "können sie den verwendeten Prompt unten bearbeiten und erneut absenden." + "\nSie können auch speziefische Fragen zu den Dokumenten stellen die mir zur verfügung stehen, " + "oder mich fragen auf welche Dokumente ich zugriff habe."},
                                    sources: [],
                                    type: "ai",
                                }
                            }}
                        />) : (<>
                            {loadingQuery && (<ThreadMessage
                                message={{
                                    message: {
                                        type: "ai", data: {content: "⏳ Waiting for response..."},
                                    }
                                }}
                                isLoadingDummy={true}
                            />)}
                            {generatedTextThreadMessages.map((messageItem) => {
                                return (<ThreadMessage
                                    message={messageItem}
                                    key={messageItem.id}
                                    isLoadingDummy={false}
                                />);
                            })}
                        </>)}
                    </>)}
                </>)}
            </Box>
            <Box>
                <Box sx={{marginBottom: "3px", fontSize: "14px", color: "gray"}}>
                    Edit the default prompt for different results:
                </Box>
                <Box
                    sx={{
                        width: "100%", position: "relative", display: "flex", gap: "3px",
                    }}
                >
            <textarea
                style={{
                    flexGrow: "1",
                    height: "80px",
                    padding: "10px",
                    fontSize: "14px",
                    border: "none",
                    backgroundColor: promptBoxColor,
                    borderRadius: "15px 5px 5px 15px",
                    fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
                    resize: "none",
                }}
                value={prompt}
                onChange={handlePromptChange}
                data-testid="message-input"
            />
                    <Tooltip title="Send" placement="top" arrow>
                        <Button
                            type="button"
                            sx={{
                                height: "80px",
                                maxWidth: "45px",
                                minWidth: "45px",
                                backgroundColor: promptBoxColor,
                                borderRadius: "5px 15px 15px 5px",
                                position: "relative",
                                border: "none",
                                cursor: "pointer",
                                padding: "0",
                            }}
                            onClick={handlePostMessage}
                            disabled={loadingQuery || loadingMessages || prompt === ""}
                            data-testid="send-query-button"
                        >
                            <SendRoundedIcon
                                sx={{
                                    position: "absolute",
                                    top: "50%",
                                    left: "50%",
                                    transform: "translate(-50%,-50%)",
                                }}
                            />
                        </Button>
                    </Tooltip>

                    <Box
                        sx={{
                            position: "absolute",
                            top: "70px",
                            right: "48px",
                            width: "20px",
                            height: "20px",
                            backgroundColor: promptBoxColor,
                            zIndex: "-1",
                            overflow: "hidden",
                        }}
                    >
                        <Box
                            sx={{
                                width: "40px",
                                height: "20px",
                                backgroundColor: "white",
                                transform: "translate(-20%, 70%) rotate(24deg)",
                            }}
                        />
                    </Box>
                </Box>
            </Box>
        </Box>
    </Modal>);
};

MessageTextgenerationModal.propTypes = {
    keyName: PropTypes.string.isRequired,
    keyData: PropTypes.object.isRequired,
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    companyId: PropTypes.number.isRequired,
    creditProjectId: PropTypes.number.isRequired,
}
