import FullFeaturedCrudGrid from "../../../utils/FullFeaturedCrudGrid/FullFeaturedCrudGrid";
import {
  createLiabilities,
  deleteLiabilities,
  updateLiabilities,
} from "../../../api";
import { useFetchLiabilities } from "../../../hooks";
import { useQueryClient } from "@tanstack/react-query";
import PropTypes from "prop-types";
import { calculateYears } from '../../../utils/dateUtils';

const transformToGridFormat = (apiData, years) => {
  return apiData.map((item) => {
    const row = {
      id: item.id,
      name: item.name,
      liability_type: item.liability_type,
      initial_amount: item.initial_amount,
      is_new_liability: item.is_new_liability,
      term_years: item.term_years,
    };

    years.forEach((year) => {
      const redemption = item.redemptions.find((r) => r.year === year);
      row[year] = redemption ? redemption.amount : null;
    });

    return row;
  });
};

const LiabilitiesView = ({ companyId, projectDuration, projectYear }) => {
  const queryClient = useQueryClient();
  const {
    data: liabilities,
    isLoading: liabilitiesLoading,
    isError: liabilitiesError,
    isFetching: liablitiesFetching,
  } = useFetchLiabilities(companyId);

  if (liabilitiesLoading || liablitiesFetching) {
    return <div>Loading...</div>;
  }
  if (liabilitiesError) {
    return <div>Error</div>;
  }

  const years = calculateYears(projectYear, projectDuration);

  const gridData = liabilities ? transformToGridFormat(liabilities, years) : [];

  const yearColumns = years.flatMap((year, index, array) => {
    const yearColumn = {
      field: year.toString(),
      headerName: `${year}+-`, // Renamed column YYYY+-
      flex: 1,
      editable: true,
      type: "number",
    };

    // Skip the calculation for the first year
    if (index === 0) {
      return [yearColumn];
    }

    const standCalcColumn = {
      field: `${year}+-`, // Field name for calculation
      headerName: `${year} Stand`,  // Renamed column YYYY Stand
      flex: 1,
      valueGetter: (params) => {
        const prevYearValue = params.row[array[index - 1].toString()];
        const currentYearValue = params.row[year.toString()];

        // Check if either value is NaN, and return empty if true
        if (isNaN(prevYearValue) || isNaN(currentYearValue)) {
          return "";
        }

        return prevYearValue + currentYearValue;
      },
    };

    return [yearColumn, standCalcColumn];
  });

  const columns = [
    { field: "id", headerName: "ID", width: 70 },
    {
      field: "name",
      headerName: "Name",
      flex: 2,
      editable: true,
      type: "string",
    },
    {
      field: "is_new_liability",
      headerName: "Neu",
      editable: true,
      flex: 1,
      type: "boolean",
    },
    {
      field: "liability_type",
      headerName: "Type",
      flex: 1,
      editable: true,
      type: "singleSelect",
      valueOptions: ["short_term", "long_term", "debit"],
      valueFormatter: (params) => {
        if (params.value === "short_term") {
          return "Short Term";
        } else if (params.value === "long_term") {
          return "Long Term";
        } else if (params.value === "debit") {
          return "Debit";
        } else {
          return params.value;
        }
      },
    },
    {
      field: "initial_amount",
      headerName: "Anfangsbetrag",
      flex: 1,
      editable: true,
      type: "number",
    },
    ...yearColumns,
  ];

  const transformToApiFormat = (rowData) => {
    const redemptions = years
      .map((year) => {
        if (rowData[year]) {
          return { year: year, amount: rowData[year] };
        }
        return null;
      })
      .filter((item) => item !== null);

    return {
      name: rowData.name,
      liability_type: rowData.liability_type,
      initial_amount: rowData.initial_amount,
      is_new_liability: rowData.is_new_liability,
      term_years: rowData.term_years,
      redemptions: redemptions,
    };
  };

  const createRowData = (rows) => {
    const newId = Math.max(...rows.map((r) => (r.id ? r.id : 0) * 1)) + 1;
    return {
      id: newId,
      name: "",
      is_new_liability: false,
    };
  };

  const onSaveRow = (id, updatedRow, oldRow, internalRows) => {
    const formattedData = transformToApiFormat(updatedRow);
    console.log(id, updatedRow, oldRow, internalRows);
    createLiabilities(companyId, formattedData)
      .then((res) =>
        queryClient.invalidateQueries({ queryKey: ["liabilities", companyId] })
      )
      .catch((err) => {
        console.error(err);
        // toast.error("Error in onDeleteRow", {"description": err})
      });
  };

  const onUpdateRow = (id, updatedRow, oldRow, internalRows) => {
    const formattedData = transformToApiFormat(updatedRow);
    updateLiabilities(companyId, id, formattedData)
      .then((res) =>
        queryClient.invalidateQueries({ queryKey: ["liabilities", companyId] })
      )
      .catch((err) => {
        console.error(err);
        // toast.error("Error in onDeleteRow", {"description": err})
      });
  };

  const onDeleteRow = (id, oldRow, oldRows) => {
    deleteLiabilities(companyId, id)
      .then((res) => {
        queryClient.invalidateQueries({ queryKey: ["liabilities", companyId] });
      })
      .catch((err) => {
        console.error(err);
        // toast.error("Error in onDeleteRow", {"description": err})
      });
  };

  return (
    <FullFeaturedCrudGrid
      data-testid="liabilities-data-grid"
      columns={columns}
      rows={gridData}
      createRowData={createRowData}
      onSaveRow={onSaveRow}
      onDeleteRow={onDeleteRow}
      onUpdateRow={onUpdateRow}
    >
      {" "}
    </FullFeaturedCrudGrid>
  );
};

export default LiabilitiesView;

LiabilitiesView.propTypes = {
  companyId: PropTypes.string.isRequired,
  projectDuration: PropTypes.number.isRequired,
  projectYear: PropTypes.number.isRequired,
};
