import { CircularProgress, IconButton } from "@mui/material";
import { AutoAwesome } from "@mui/icons-material";
import React, { useState } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { patchAnswerQuestionsWithLLM } from "../../api";
import { toast } from "sonner";
import PropTypes from "prop-types";

const LLMComponent = ({ ddCallId }) => {
  const queryClient = useQueryClient();
  const [loading, setLoading] = useState(false);
  const mutation = useMutation({
    mutationFn: patchAnswerQuestionsWithLLM,
    onSuccess: () => {
      // Invalidate and refetch
      setLoading(false);
      toast.success("DD Call erfolgreich mit LLM beantwortet");
      queryClient.invalidateQueries({ queryKey: ["qaData"] });
    },
    onError: (error) => {
      toast.error("DD Call konnte nicht mit LLM beantwortet werden", {
        description: error.response.data.detail,
      });
      console.log(error);
      setLoading(false);
    },
    onMutate: () => {
      setLoading(true);
    },
  });

  const handleOnClick = () => {
    mutation.mutate({ ddCallId });
  };

  return (
    <IconButton
      onClick={handleOnClick}
      color="primary"
      variant="outlined"
      data-testid="LLM-Component-Button-Confirm"
    >
      AI
      {loading ? (
        <CircularProgress
          size={24}
          data-testid="LLM-Component-CircularProgress"
        />
      ) : (
        <AutoAwesome />
      )}
    </IconButton>
  );
};

export default LLMComponent;

LLMComponent.propTypes = {
  ddCallId: PropTypes.string.isRequired,
};
