import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  Box,
} from "@mui/material";
import Skeleton from "@mui/material/Skeleton";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import SuccessIcon from "@mui/icons-material/Check";
import { crefoAPI } from "../../crefoServiceAPI.js";
import ErrorIcon from "@mui/icons-material/Error";
import ErrorImg from "../../assets/images/Error.jpg";
import DoneImg from "../../assets/images/Done.jpg";
import { CrefoReportDataFiller } from "./CrefoReportDataFiller.js";
import { toast } from "sonner";

// THIS IS THE CREFO REQUEST REPORT COMPONENT
const CrefoRequestReport = ({ companyData, closeOpenCrefoModal }) => {
  const { crefo_id: crefoId } = companyData;
  const [crefoProductAvailability, setCrefoProductAvailability] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [selectedProduct, setSelectedProduct] = useState("");
  const [selectedLegitimateInterest, setSelectedLegitimateInterest] =
    useState("");
  const [requestStep, setRequestStep] = useState(0);
  const [isRequestLoading, setRequestLoading] = useState(false);
  const [requestError, setRequestError] = useState(null);
  const [reportRequested, setReportRequested] = useState(false);

  // SIDE EFFECT: This fetches the data for crefo product availability
  useEffect(() => {
    setLoading(true);
    crefoAPI
      .getCrefoProductAvailability(crefoId)
      .then((data) => {
        setCrefoProductAvailability(data.products);
        setLoading(false);
      })
      .catch((e) => {
        setError("Error fetching Crefo product availability");
        setLoading(false);
      });
  }, [crefoId]);

  const getCrefoDataByReportId = async (reportId) => {
    setRequestLoading(true);
    try {
      const res = await crefoAPI.getCrefoData(reportId);
      if (res.data) {
        setSelectedRow(res.data);
      }
      setRequestLoading(false);
      setReportRequested(true);
      return res;
    } catch (e) {
      console.error(e);
      setRequestLoading(false);
      setReportRequested(false);
      toast.error(`ERROR:: Error fetching crefo report data! `);
    }
  };
  // HELPER FUNCTION: This function is called when the user clicks on the request button
  const requestReport = async () => {
    const requestPayload = {
      identificationnumber: crefoId,
      producttype: selectedProduct,
      legitimateinterest: selectedLegitimateInterest,
    };

    setRequestLoading(true);
    setRequestError(null);

    try {
      crefoAPI
        .requestCrefoReport(requestPayload)
        .then((res) => {
          setRequestStep(2);
          getCrefoDataByReportId(res.report_id);
        })
        .catch((e) => {
          console.error("Error", e);
        });
    } catch (e) {
      setRequestError(
        e.response.status === 400
          ? "Sorry! There is a mismatch in product type and legitimate interest. Try different match!"
          : e.message || "An error occurred"
      );
      console.error("Error", e);
      setRequestLoading(false);
    }
  };

  // LOADING STATE MANAGEMENT
  if (loading || isRequestLoading) {
    return (
      <Box width={"100%"} sx={{ display: "flex", justifyContent: "center" }}>
        <Skeleton variant="rectangular" width={500} height={60} />
      </Box>
    );
  }

  // ERROR STATE MANAGEMENT
  if (error || requestError) {
    return (
      <Box
        sx={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <img
          src={ErrorImg}
          alt="Error"
          width="200px"
          height="200px"
          style={{ objectFit: "contain", filter: "hue-rotate(50deg)" }}
        />
        <Typography
          color="error"
          sx={{ display: "flex", alignItems: "center" }}
        >
          <ErrorIcon sx={{ marginRight: "5px", fontSize: "20px" }} />
          {error || requestError}
        </Typography>
      </Box>
    );
  }
  // SUCCESS STATE MANAGEMENT
  if (crefoProductAvailability) {
    // IF PRODUCT AND LEGITIMATE INTEREST IS SELECTED AND REPORT IS REQUESTED
    if (requestStep === 1) {
      return (
        <Grid container spacing={2}>
          <Grid item xs={12} textAlign="center" sx={{ marginBottom: "1rem" }}>
            <Typography variant="h7" sx={{ padding: "1rem" }}>
              Are you sure you want to request a Crefo Report for this company?
              The cost of the premium report is{" "}
              <Typography
                variant="h7"
                sx={{ color: "teal", fontWeight: "bold" }}
              >
                32€
              </Typography>
              . Others may vary
            </Typography>
          </Grid>
          <Grid item xs={6} textAlign="center">
            <Button onClick={requestReport} variant="contained">
              <AttachMoneyIcon sx={{ marginRight: "5px", fontSize: "15px" }} />
              Confirm
            </Button>
          </Grid>
          <Grid item xs={6} textAlign="center">
            <Button
              variant="outlined"
              size="large"
              onClick={() => {
                setRequestStep(0);
              }}
            >
              Cancel
            </Button>
          </Grid>
        </Grid>
      );
    }
    // IF REPORT REQUEST IS SUCCESSFULLY PROCEED
    if (requestStep === 2) {
      return (
        <Box
          sx={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <img
            src={DoneImg}
            alt="Error"
            width="150px"
            height="150px"
            style={{ objectFit: "contain", filter: "hue-rotate(80deg)" }}
          />
          <Typography
            color="info"
            sx={{ display: "flex", alignItems: "center" }}
          >
            <SuccessIcon
              color="success"
              sx={{ marginRight: "5px", fontSize: "20px" }}
            />
            Your request has been successfully proceed!
          </Typography>
          {reportRequested ? (
            <CrefoReportDataFiller
              companyId={companyData.id} // Change companyId to your actual companyId
              companyType={"companyType"}
              companyData={{
                nace_code_level_three: companyData.nace_code_level_three,
                name: companyData.name,
                hq_street: companyData.hq_street,
                hq_zip_code: companyData.hq_zip_code,
                hq_city: companyData.hq_city,
                hq_country: companyData.hq_country,
                website: companyData.website,
                taxId: companyData.taxid,
                local_court: companyData.local_court,
                register_number: companyData.register_number,
              }}
              reportData={{
                nace_code_level_three: selectedRow.nace.code_level_three,
                nace_code_level_three_name:
                  selectedRow.nace.name_level_three_de,
                gics: selectedRow.gics,
                name: selectedRow.name,
                hq_street: selectedRow.hq_street,
                hq_zip_code: selectedRow.hq_zip_code,
                hq_city: selectedRow.hq_city,
                hq_country: selectedRow.hq_country,
                website: selectedRow.website,
                taxId: selectedRow.taxid,
                local_court: selectedRow.local_court,
                register_number: selectedRow.register_number,
              }}
              onBackClick={closeOpenCrefoModal}
            />
          ) : (
            <Typography variant="h7" color="error" margin={1}>
              ⚠️ No report data available in the database with newly created
              report ID! So cannot show details here.
            </Typography>
          )}
        </Box>
      );
    }
    // FORM FOR SELECTING  PRODUCT AND LEGITIMATE INTEREST TO REQUEST REPORT
    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-evenly",
          padding: "2rem 0rem",
        }}
      >
        <FormControl sx={{ width: "400px", marginRight: "2rem" }}>
          <InputLabel id="product-select-label">Product Type</InputLabel>
          <Select
            labelId="product-select-label"
            id="product-select"
            value={selectedProduct}
            label="Product Type"
            onChange={(e) => {
              setSelectedProduct(e.target.value);
              setSelectedLegitimateInterest("");
            }}
          >
            {crefoProductAvailability.map((p) => {
              return (
                <MenuItem key={p.key} value={p.key}>
                  {p.type}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>

        {selectedProduct && (
          <FormControl sx={{ width: "400px", marginRight: "2rem" }}>
            <InputLabel id="legitimate-interest-select-label">
              Legitimate Interest
            </InputLabel>
            <Select
              labelId="legitimate-interest-select-label"
              id="legitimate-interest-select"
              value={selectedLegitimateInterest}
              label="Legitimate Interest"
              onChange={(e) => {
                setSelectedLegitimateInterest(e.target.value);
              }}
            >
              {crefoProductAvailability
                .find((el) => el.key === selectedProduct)
                ?.legitimateinterest.map((li) => {
                  return (
                    <MenuItem key={li.key} value={li.key}>
                      {li.type}
                    </MenuItem>
                  );
                })}
            </Select>
          </FormControl>
        )}

        {selectedProduct && selectedLegitimateInterest && (
          <Button
            variant="contained"
            size="large"
            onClick={() => {
              setRequestStep(1);
            }}
          >
            Request
          </Button>
        )}
      </Box>
    );
  }
  // IF NO PRODUCT IS AVAILABLE
  return (
    <Box width={"100%"} sx={{ display: "flex", justifyContent: "center" }}>
      <Typography variant="h7">
        ⚠️ Crefo report request is not possible. Currently no product is
        available!
      </Typography>
    </Box>
  );
};

CrefoRequestReport.propTypes = {
  companyData: PropTypes.object.isRequired,
  closeOpenCrefoModal: PropTypes.func.isRequired,
};
export default CrefoRequestReport;
