import CustomToolTip from "../../CustomToolTip";
import { Typography } from "@mui/material";
/**
 * This file contains utility functions for the CrefoDataFiller component.
 * These functions are used to fill the table with data from the CrefoReport.
 */
export const CrefoDataFillerUtils = {
  /**
   * This function returns the name of the given key from the given object.
   * @param {string} cspKey - The key of the object.
   * @returns {string} - The name of the key.
   * @example getFieldNameWithKey("legalForm") => "Legal Form"
   **/
  getFieldNameWithKey(cspKey) {
    // Special cases
    if (cspKey === "legalForm") {
      return "Legal Form";
    }
    if (cspKey === "taxId") {
      return "Tax ID";
    } else {
      // Other cases
      const words = cspKey.split("_");
      const formatted = words
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ");

      return formatted;
    }
  },
  /**
   * This function returns the button text value for the given table cell.
   * @param {boolean} isGicsCode - Whether the cell is a GICS code.
   * @param {string | Array} crefoReportValue - The value of the cell.
   * @returns {string} - The button text value.
   * @example getButtonTextValueForTableCell(true, "45.2") => "45.2"
   **/
  getButtonTextValueForTableCell(isGicsCode, crefoReportValue) {
    if (
      isGicsCode &&
      (crefoReportValue === null || crefoReportValue.length === 0)
    ) {
      return "N/A";
    }
    if (
      isGicsCode &&
      Array.isArray(crefoReportValue) &&
      crefoReportValue.length === 1
    ) {
      return crefoReportValue[0].code_level_three;
    }
    return crefoReportValue;
  },
  /**
   * This function returns the text value after selecting a new value from the dropdown.
   * @param {boolean} isGicsCode - Whether the cell is a GICS code.
   * @param {string | Array} crefoReportValue - The value of the cell.
   * @param {string} gicsCode - The GICS code.
   * @returns {string} - The text value after selecting a new value from the dropdown.
   **/
  getTextValueAfterSelect(isGicsCode, crefoReportValue, gicsCode) {
    if (isGicsCode && crefoReportValue === null) {
      return "N/A";
    }
    if (
      isGicsCode &&
      Array.isArray(crefoReportValue) &&
      crefoReportValue.length === 1
    ) {
      return crefoReportValue[0].code_level_three;
    }
    if (isGicsCode && Array.isArray(crefoReportValue) && crefoReportValue.length > 1) {
      return gicsCode;
    }
    return crefoReportValue;
  },
  /**
   * This function returns the boolean value of whether the cell value is equal to the new value.
   * @param {string} cspValue - The value of the cell.
   * @param {string | Array} crefoReportValue - The value of the cell.
   * @param {string} gicsCode - The GICS code.
   * @returns {boolean} - The boolean value of whether the cell value is equal to the new value.
   * @example getIsValueEqual("45.2", "45.2") => true
   **/
  getIsValueEqual(cspValue, crefoReportValue, gicsCode) {
    if (crefoReportValue === null) {
      return false;
    }
    if (Array.isArray(crefoReportValue)) {
      return cspValue === gicsCode;
    }
    if (cspValue && crefoReportValue) {
      return cspValue === crefoReportValue;
    }
    if (!cspValue || !crefoReportValue) {
      return false;
    }
    return false;
  },
  /**
   * This function returns the boolean value of whether the CrefoReport has been selected.
   * @param {string} cspValue - The value of the cell.
   * @param {string} newValue - The new value of the cell.
   * @param {string | Array} crefoReportValue - The value of the cell.
   * @param {string} gicsCode - The GICS code.
   * @returns {boolean} - The boolean value of whether the CrefoReport has been selected.
   * @example getHasCrefoBeenSelected("45.2", "45.2", "45.2") => true
   **/
  getHasCrefoBeenSelected(cspValue, newValue, crefoReportValue, gicsCode) {
    if (crefoReportValue === null) {
      return false;
    }
    if (Array.isArray(crefoReportValue) && crefoReportValue.length === 1) {
      return (
        cspValue !== crefoReportValue[0].code_level_three &&
        newValue === crefoReportValue[0].code_level_three
      );
    }
    if (Array.isArray(crefoReportValue) && crefoReportValue.length > 1) {
      return cspValue !== gicsCode && newValue === gicsCode;
    }
    if (cspValue !== newValue && crefoReportValue === newValue) {
      return true;
    }
    return false;
  },
  /**
   * This function returns the level three name for nace and gics codes.
   * @param {string} name - The name of the code.
   * @returns {JSX.Element} - The level three name for nace and gics codes.
   **/
  getLevelThreeName(name = "") {
    const allowedCodeNameLength = 30;
    return (
      <CustomToolTip title={name.length > allowedCodeNameLength ? name : ""}>
        <Typography variant="h7">
          {name?.length > allowedCodeNameLength
            ? name.substring(0, allowedCodeNameLength) + "..."
            : name}
        </Typography>
      </CustomToolTip>
    );
  },
};
