/*
 * Quill modules to attach to editor
 * See https://quilljs.com/docs/modules/ for complete options
 */
export const getModules = (toolbar, parchment) => {
  return {
    toolbar: toolbar,
    clipboard: {
      // toggle to add extra line breaks when pasting HTML:
      matchVisual: false,
    },
    imageResize: {
      parchment: parchment,
      modules: ["Resize", "DisplaySize"],
    },
  };
};
