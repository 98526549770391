import React, { useEffect, useState } from "react";
import {
  useNavigate,
  useLocation,
  createSearchParams,
  useParams,
} from "react-router-dom";
import { Layout } from "../components/Layout/Layout";
import CreateDialog from "../components/CreateDialog/CreateDialog";
import DeleteDialog from "../components/DeleteDialog/DeleteDialog";
import CreateSelectionDialog from "../components/CreateSelection/CreateSelectionDialog";
import ProformaDialog from "../components/Proforma/ProformaDialog";
import { Box, TextField } from "@mui/material";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";
import FinancialInputComponent from "../components/FinancialInputComponent/FinancialInputComponent";
import {
  fetchBorrowerGroup,
  fetchCompany,
  fetchTimeSliceGroup,
  putTimeSliceGroupsName,
} from "../api";
import BorrowerGroupDialog from "../components/BorrowerGroup/BorrowerGroupDialog";

const FinancialInput = () => {
  let navigate = useNavigate();
  const [groupId, setGroupId] = useState(null);
  const location = useLocation();
  const [groupName, setGroupName] = useState("");
  const [refreshData, setRefreshData] = useState(false);
  const [refreshConsolidationData, setRefreshConsolidationData] =
    useState(false);
  const [borrowerGroup, setBorrowerGroup] = useState(null);
  const [companyNames, setCompanyNames] = useState({});
  // Need this to open selection dialog if time slices with groups can be created
  const [openSelectionDialog, setOpenSelectionDialog] = useState(false);

  const { companyId } = useParams();
  const handleUpdate = (groupId) => {
    setRefreshData((prevState) => !prevState);
    setRefreshConsolidationData((prevState) => !prevState);
  };

  const handleUpdateConsolidation = (groupId) => {
    setRefreshConsolidationData((prevState) => !prevState);
  };

  const handleBlur = () => {
    updateGroupName(groupName);
  };

  const getTimeSliceGroupId = () => {
    const params = new URLSearchParams(window.location.search);
    setGroupId(params.get("time_slice_group_id"));
  };

  useEffect(() => {
    getTimeSliceGroupId();
  }, [location]);

  const handleCancel = () => {
    // Handling cancel action
  };

  useEffect(() => {
    if (groupId !== null) {
      setRefreshData((prevState) => !prevState);
    }
  }, [groupId]);

  // New state for loading
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const borrowerGroupId = queryParams.get("borrower_group_id");
    // Wrap your fetch inside an async function
    if (borrowerGroupId !== null) {
      // Wrap your fetch inside an async function
      const fetchBorrowerData = async () => {
        setIsLoading(true); // Set loading to true when fetching begins
        const response = await fetchBorrowerGroup(borrowerGroupId); // Use the query param here
        setBorrowerGroup(response?.data);
        setIsLoading(false); // Set loading to false when fetching is complete
      };

      fetchBorrowerData();
    } else {
      setBorrowerGroup(null);
      setIsLoading(false);
    }
  }, [location]);

  useEffect(() => {
    // Define an async function within the effect
    const fetchAllCompanyNames = async () => {
      const names = {};
      for (const assoc of borrowerGroup.borrower_group_associations) {
        const companyId = assoc.time_slice_group.time_slices[0].company_id;
        if (!names[companyId]) {
          // Avoid fetching the same company multiple times
          const companyData = await fetchCompany(companyId);
          names[companyId] = companyData?.name;
        }
      }
      setCompanyNames(names);
    };

    // If borrowerGroup is available, fetch all company names
    if (borrowerGroup?.borrower_group_associations) {
      fetchAllCompanyNames();
    }
  }, [borrowerGroup]);

  useEffect(() => {
    const getGroupName = async () => {
      try {
        const response = await fetchTimeSliceGroup(groupId);
        if (response && response.name !== "") {
          setGroupName(response.name);
        } else {
          setGroupName("");
        }
      } catch (error) {
        console.error("Error fetching group name:", error);
      }
    };

    if (groupId) {
      getGroupName().then((r) => console.error(r));
    }
  }, [groupId]);
  const handleCreateSelection = (response) => {
    navigate({
      pathname: `/company/${companyId}/input`,
      search: createSearchParams({
        time_slice_group_id: response.data.id,
      }).toString(),
    });
    if (response.data.group_name !== null) {
      setGroupName(response.data.group_name);
    }
  };

  const generateFinancialInputComponents = () => {
    if (isLoading) {
      return <div>Loading...</div>;
    }

    if (!borrowerGroup?.borrower_group_associations) {
      return (
        <FinancialInputComponent
          groupId={groupId}
          refreshData={refreshData}
          handleUpdate={handleUpdate}
          showPositions={true}
          editable={true}
          associatedWithBorrowerGroup={false}
        />
      );
    }

    const headCompanyId = borrowerGroup.head_company_id;

    // Split the associations into categories
    const headCompanyTimeSlicesWithBorrowerGroup = [];
    const headCompanyTimeSlicesWithoutBorrowerGroup = [];
    const additionalCompanyTimeSlices = [];
    const groupAdjustmentTimeSlices = [];

    for (const assoc of borrowerGroup.borrower_group_associations) {
      const timeSliceGroupId = assoc.time_slice_group_id;
      const companyId = assoc.time_slice_group.time_slices[0].company_id;
      const scope = assoc.time_slice_group.scope;

      if (companyId === headCompanyId && scope !== "group_adjustment") {
        headCompanyTimeSlicesWithBorrowerGroup.push({
          timeSliceGroupId: timeSliceGroupId,
          title: "Consolidated",
          showPositions: true,
          refreshDataprop: refreshConsolidationData,
          handleUpdatefunc: handleUpdateConsolidation,
          editable: false,
        });
        headCompanyTimeSlicesWithoutBorrowerGroup.push({
          timeSliceGroupId: timeSliceGroupId,
          title: companyNames[companyId],
          companyId: companyId,
          showPositions: true,
          refreshDataprop: refreshData,
          handleUpdatefunc: handleUpdate,
          editable: true,
        });
      } else if (companyId !== headCompanyId && scope !== "group_adjustment") {
        additionalCompanyTimeSlices.push({
          timeSliceGroupId: timeSliceGroupId,
          title: companyNames[companyId],
          companyId: companyId,
          showPositions: true,
          refreshDataprop: refreshData,
          handleUpdatefunc: handleUpdate,
          editable: true,
        });
      } else if (scope === "group_adjustment") {
        groupAdjustmentTimeSlices.push({
          timeSliceGroupId: timeSliceGroupId,
          title: "Group Adjustment",
          showPositions: true,
          refreshDataprop: refreshData,
          handleUpdatefunc: handleUpdate,
          editable: true,
        });
      }
    }

    // Generate components
    const generateComponents = (data, borrowerGroupId) =>
      data.map((item) => (
        <Box key={item.timeSliceGroupId} marginRight={2}>
          <FinancialInputComponent
            groupId={item.timeSliceGroupId}
            refreshData={item.refreshDataprop}
            handleUpdate={item.handleUpdatefunc}
            borrowerGroupId={borrowerGroupId}
            title={item.title}
            companyId={item.companyId}
            showPositions={item.showPositions}
            editable={item.editable}
          />
        </Box>
      ));

    return (
      <>
        {generateComponents(
          headCompanyTimeSlicesWithBorrowerGroup,
          borrowerGroup.id
        )}
        {generateComponents(headCompanyTimeSlicesWithoutBorrowerGroup, null)}
        {generateComponents(additionalCompanyTimeSlices, null)}
        {generateComponents(groupAdjustmentTimeSlices, null)}
      </>
    );
  };

  function updateGroupName(value) {
    //console.clear()
    //console.log('Function for updateGroupName invoked')
    putTimeSliceGroupsName(groupId, { group_name: value }).then((r) =>
      console.error(r)
    );
  }

  return (
    <Layout>
      <Stack
        direction="row"
        divider={<Divider orientation="vertical" flexItem />}
        spacing={3}
        sx={{ marginLeft: "2rem" }}
      >
        <Box>
          <CreateDialog
            setOpenSelectionDialog={setOpenSelectionDialog}
            onCreate={handleUpdate}
          />
        </Box>
        <Box>
          <DeleteDialog onDelete={handleUpdate} onCancel={handleCancel} />
        </Box>
        <Box>
          <CreateSelectionDialog
            openSelectionDialog={openSelectionDialog}
            setOpenSelectionDialog={setOpenSelectionDialog}
            onCreate={handleCreateSelection}
          />
        </Box>
        <Box>
          <ProformaDialog></ProformaDialog>
        </Box>
        <Box>
          <BorrowerGroupDialog></BorrowerGroupDialog>
        </Box>
      </Stack>
      <TextField
        value={groupName}
        onChange={(e) => setGroupName(e.target.value)}
        onBlur={handleBlur}
        sx={{
          color: "teal",
          margin: "1rem 2rem",
          fontSize: "1rem",
          width: "53%",
        }}
        label="Group Name"
        variant="outlined"
      />
      <Box
        display="flex"
        flexDirection="row"
        sx={{ margin: "0rem 1rem 0rem 1.8rem" }}
      >
        {generateFinancialInputComponents()}
      </Box>
    </Layout>
  );
};

export default FinancialInput;
