import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import EuroOutlinedIcon from "@mui/icons-material/EuroOutlined";
import RequestPageOutlinedIcon from "@mui/icons-material/RequestPageOutlined";
import CallOutlinedIcon from "@mui/icons-material/CallOutlined";
import InsertChartOutlinedIcon from "@mui/icons-material/InsertChartOutlined";
import StarOutlineIcon from "@mui/icons-material/StarOutline";
import ShowChartIcon from "@mui/icons-material/ShowChart";
import {
  useNavigate,
  createSearchParams,
  useLocation,
  useParams,
} from "react-router-dom";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import ToggleThemeMode from "./ToggleThemeMode";

const drawerWidth = 240;

const handleOpenUserManual = () => {
  // Open the user manual in a new tab
  window.open(process.env.REACT_APP_USER_MANUAL_URL, "_blank");
};

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "DRAWER_STATE",
})(({ theme, open }) => ({
  width: "drawerWidth",
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function MiniDrawer({ children }) {
  let navigate = useNavigate();
  const location = useLocation();
  const [groupId, setGroupId] = useState(null);
  const [borrowerGroupId, setBorrowerGroupId] = useState(null);
  const [ddCallId, setDdCallId] = useState(null);
  const [baseTimeSliceId, setBaseTimeSliceId] = useState(null);
  const [creditProjectId, setCreditProjectId] = useState(null);

  const [open, setOpen] = useState(() => {
    const storedValue = localStorage.getItem("DRAWER_STATE");
    return storedValue !== null ? JSON.parse(storedValue) : true;
  });

  // Update local storage whenever the 'open' state changes
  useEffect(() => {
    window.localStorage.setItem("DRAWER_STATE", JSON.stringify(open));
  }, [open]);

  const { companyId } = useParams();

  const getTimeSliceGroupId = () => {
    const params = new URLSearchParams(window.location.search);
    setGroupId(params.get("time_slice_group_id"));
    setBorrowerGroupId(params.get("borrower_group_id"));
    setDdCallId(params.get("dd_call_id"));
    setBaseTimeSliceId(params.get("base_time_slice_id"));
    setCreditProjectId(params.get("credit_project_id"));
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    getTimeSliceGroupId();
  }, [location]);

  const stages = [
    {
      label: "Financial Overview",
      icon: EuroOutlinedIcon,
      route: {
        pathname: `/company/${companyId}/view`,
        search: createSearchParams({
          time_slice_group_id: groupId,
          borrower_group_id: borrowerGroupId,
          dd_call_id: ddCallId,
          base_time_slice_id: baseTimeSliceId,
          credit_project_id: creditProjectId,
        }).toString(),
      },
    },
    {
      label: "Financial Input",
      icon: RequestPageOutlinedIcon,
      route: {
        pathname: `/company/${companyId}/input`,
        search: createSearchParams({
          time_slice_group_id: groupId,
          borrower_group_id: borrowerGroupId,
          dd_call_id: ddCallId,
          base_time_slice_id: baseTimeSliceId,
          credit_project_id: creditProjectId,
        }).toString(),
      },
    },
    {
      label: "Rating",
      icon: StarOutlineIcon,
      route: {
        pathname: `/company/${companyId}/rating`,
        search: createSearchParams({
          time_slice_group_id: groupId,
          borrower_group_id: borrowerGroupId,
          dd_call_id: ddCallId,
          base_time_slice_id: baseTimeSliceId,
          credit_project_id: creditProjectId,
        }).toString(),
      },
    },
    {
      label: "DD Call",
      icon: CallOutlinedIcon,
      route: {
        pathname: `/company/${companyId}/dd-call`,
        search: createSearchParams({
          time_slice_group_id: groupId,
          borrower_group_id: borrowerGroupId,
          dd_call_id: ddCallId,
          base_time_slice_id: baseTimeSliceId,
          credit_project_id: creditProjectId,
        }).toString(),
      },
    },
    {
      label: "Final Opinion",
      icon: InsertChartOutlinedIcon,
      route: {
        pathname: `/company/${companyId}/final-opinion`,
        search: createSearchParams({
          time_slice_group_id: groupId,
          borrower_group_id: borrowerGroupId,
          dd_call_id: ddCallId,
          base_time_slice_id: baseTimeSliceId,
          credit_project_id: creditProjectId,
        }).toString(),
      },
    },
    {
      label: "Forecast",
      icon: ShowChartIcon,
      route: {
        pathname: `/company/${companyId}/forecast`,
        search: createSearchParams({
          time_slice_group_id: groupId,
          borrower_group_id: borrowerGroupId,
          dd_call_id: ddCallId,
          base_time_slice_id: baseTimeSliceId,
          credit_project_id: creditProjectId,
        }).toString(),
      },
    },
  ];

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline sx={{ marginTop: "65px !important" }} />
      <Drawer
        sx={{
          "& .MuiDrawer-paper": {
            marginTop: "65px !important",
          },
        }}
        variant="permanent"
        open={open}
        classes={{
          paper: { marginTop: "65px !important", width: drawerWidth },
        }}
      >
        <DrawerHeader>
          {!open ? (
            <IconButton onClick={() => handleDrawerOpen()}>
              <ChevronRightIcon />
            </IconButton>
          ) : (
            <IconButton onClick={() => handleDrawerClose()}>
              <ChevronLeftIcon />
            </IconButton>
          )}
        </DrawerHeader>
        <Divider />
        <List>
          {stages.map(({ label, icon: Icon, route }) => (
            <ListItem key={label} disablePadding sx={{ display: "block" }}>
              <ListItemButton
                onClick={() => {
                  groupId !== null
                    ? navigate({
                        pathname: route.pathname,
                        search: route.search,
                      })
                    : navigate({
                        pathname: route.pathname,
                      });
                }}
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <Icon />
                </ListItemIcon>

                <ListItemText primary={label} sx={{ opacity: open ? 1 : 0 }} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
        <Divider />
        {/* This List will be pinned to the bottom */}

     <List
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-end",
            alignItems: "center",
            height: "100%",
          }}
        >
        <ListItem sx={{ bottom: "70px" }} disablePadding>
            <ToggleThemeMode />
          </ListItem>
          <ListItem sx={{ bottom: "60px" }} disablePadding>
            <ListItemButton onClick={handleOpenUserManual}>
              <ListItemIcon>
                <HelpOutlineIcon sx={{ml: 0.5}} />
              </ListItemIcon>
              <ListItemText primary="User Manual" />
            </ListItemButton>
          </ListItem> 
        </List> 
      </Drawer>
      <Box component="main" sx={{ flexGrow: 1 }}>
        <Box sx={{ marginTop: 10, marginBottom: 10 }}>{children}</Box>
      </Box>
    </Box>
  );
}
