import React, {useState} from "react";
import {Button} from "@mui/material";
import {createSearchParams, useNavigate, useParams} from "react-router-dom";
import {BsCheck2Square} from "react-icons/bs";
import {DialogManager} from "./DialogManager";

const SelectTimeSliceGroupDialog = () => {
    const [open, setOpen] = useState(false);
    const navigate = useNavigate();
    const {companyId} = useParams();

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleCreate = async (selectedRows) => {
        const selectedTimeSliceGroup = Object.keys(selectedRows)[0];

        try {
            handleClose(); // Close the dialog if the request is successful
            navigate({
                pathname: `/company/${companyId}/view`, search: createSearchParams({
                    time_slice_group_id: selectedTimeSliceGroup,
                }).toString(),
            });
        } catch (error) {
            console.error(error);
            // openSnackbar(error?.response?.data?.detail); // Set the error message if the request fails
        }
    };
    return (<div>
            <Button variant="outlined" color="primary" onClick={handleClickOpen} size="small">
                <BsCheck2Square
                    style={{marginBottom: "3px", marginRight: "5px", fontSize: "1rem"}}
                />
                Select Data
            </Button>
            <DialogManager
                open={open}
                onClose={handleClose}
                handleCreate={handleCreate}
                companyId={companyId}
            />
        </div>);
};

export default SelectTimeSliceGroupDialog;
