import React from 'react';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import PropTypes from "prop-types";

const ViewModeToggle = ({ viewMode, handleChangeViewMode }) => {
    return (
        <ToggleButtonGroup
            value={viewMode}
            exclusive
            onChange={handleChangeViewMode}
            aria-label="view mode"
        >
            <ToggleButton value="questions" aria-label="left">Fragen</ToggleButton>
            <ToggleButton value="transcript" aria-label="right">Transkript</ToggleButton>
            <ToggleButton value="both" aria-label="both">Beides</ToggleButton>
        </ToggleButtonGroup>
    );
};

export default ViewModeToggle;

ViewModeToggle.propTypes = {
    viewMode: PropTypes.string.isRequired,
    handleChangeViewMode: PropTypes.func.isRequired,
}
