import { DataGrid } from "@mui/x-data-grid";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { fetchBorrowerGroup, addCompanyToBorrowerGroup } from "../../api";
import CompanySelection from "../CompanySelection";
import PropTypes from "prop-types";

function UpdateBorrowerGroup({ borrowerGroupId, onClose }) {
  const [rows, setRows] = useState([]);
  const [selectedCompanyId, setSelectedCompanyId] = useState(null);
  const columns = [
    {
      field: "id",
      headerName: "ID",
      flex: 1,
    },
    { field: "company_id", headerName: "Company ID", flex: 2 },
    {field: "company_name", headerName: "Company Name", flex: 3},
  ];

  const fetchBorrowerGroups = useCallback(async () => {
    if (borrowerGroupId != null) {
      try {
        const response = await fetchBorrowerGroup(borrowerGroupId);
        if (response?.data) {
          const newRows = [];
          response?.data.borrower_group_associations.forEach((association) => {
            if (
              association.time_slice_group.time_slices[0].company_id !==
              response.data.head_company_id
            ) {
              newRows.push({
                id: association.time_slice_group_id,
                company_id:
                  association.time_slice_group.time_slices[0].company_id,
                company_name: association.time_slice_group.time_slices[0].company_name
              });
            }
          });
          setRows(newRows);
        }
      } catch (error) {
        console.error("An error occurred:", error);
      }
    }
  }, [borrowerGroupId]);

  useEffect(() => {
    fetchBorrowerGroups();
  }, [fetchBorrowerGroups]);
  function handleClose() {
    onClose();
  }

  const handleCompanySelected = async () => {
    try {
      await addCompanyToBorrowerGroup(borrowerGroupId, selectedCompanyId);
      setSelectedCompanyId(null); // Reset selected company ID
      fetchBorrowerGroups(); // Refresh the table
    } catch (error) {
      console.error("An error occurred while adding the company:", error);
    }
  };

  return (
    <Dialog open={true}>
      <DialogContent>
        <DataGrid columns={columns} rows={rows}></DataGrid>
        <Box
          display="flex"
          flexDirection="row"
          margin={2}
          alignContent="center"
        >
          <CompanySelection
            onCompanySelected={(id, name) => {
              setSelectedCompanyId(id);
            }}
          />
          <Button
            variant="contained"
            color="primary"
            disabled={!selectedCompanyId} // Disabled when no company is selected
            onClick={handleCompanySelected}
            sx={{ margin: "0rem 2rem 1rem 2rem" }}
          >
            {" "}
            Add{" "}
          </Button>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          color="primary"
          onClick={handleClose}
          sx={{ margin: "0rem 0.5rem 1rem 0rem" }}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
export default UpdateBorrowerGroup;

UpdateBorrowerGroup.propTypes = {
  borrowerGroupId: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};
