import React from "react";
import { Layout } from "../components/Layout/Layout";
import { Box, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
const Home = () => {
  const theme = useTheme();
  const containerStyle = {
    textAlign: "center",
    padding: "20px",
  };

  const titleStyle = {
    fontSize: "2em",
    color: theme.palette.mode === "dark" ? "" : "#333",
    marginBottom: "10px",
  };

  const subtitleStyle = {
    fontSize: "1.2em",
    color: theme.palette.mode === "dark" ? "gray" : "#666",
  };
  
  return (
    <Layout>
      <Box sx={containerStyle}>
        <Typography variant="h1" sx={titleStyle}>
          Welcome to the Risk Engine!
        </Typography>
        <Typography sx={subtitleStyle}>Select a Company to start</Typography>
      </Box>
    </Layout>
  );
};

export default Home;
