import { Box, Typography } from "@mui/material";
import LinearProgress from "@mui/material/LinearProgress";
import { riskStudioColors as colors } from "../../styles/colors";

const RatingsDataGridTitle = function DataGridTitle(title, loading) {
  return function () {
    return (
      <>
        <Box
          sx={{
            backgroundColor: colors.gridWrapperPrimaryColor,
            width: "100%",
            textTransform: "uppercase",
            border: "0px solid transparent",
            borderRadius: "5px 5px 0px 0px",
            padding: "0.1rem 1rem",
          }}
        >
          <Typography variant="h6" color={colors.white} fontWeight="bold">
            {title}
          </Typography>
        </Box>
        {loading ? <LinearProgress color="success" /> : <div />}
      </>
    );
  };
};
export default RatingsDataGridTitle;
