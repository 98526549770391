import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import CompanySelection from "../CompanySelection";
import BusinessIcon from "@mui/icons-material/Business";
import hspIcon from "../../assets/images/hubspot-icon-521-521.png";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  fetchAllCreditProjects,
  fetchLatestCreditProject,
  searchCompanies,
} from "../../api";
import { Chip, Link, MenuItem, Select } from "@mui/material";

export default function NavBar() {
  const location = useLocation();
  const navigate = useNavigate(); // Use history for navigation
  const [companyId, setCompanyId] = useState(null);
  const [companyName, setCompanyName] = useState("");
  const [hspId, setHspId] = useState(null);
  const [creditProjects, setCreditProjects] = useState([]);
  const [creditProjectId, setCreditProjectId] = useState(null);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const projectParamId = params.get("credit_project_id");
    if (projectParamId) {
      setCreditProjectId(projectParamId);
    }

    // Early return if companyId is not set
    if (companyId == null) return;

    const fetchCompanyDetails = async () => {
      try {
        const response = await searchCompanies(companyId);
        if (response.data.companies && response.data.companies.length > 0) {
          setCompanyName(response.data.companies[0].name);
        } else {
          setCompanyName("No company found");
        }
      } catch (error) {
        console.error("An error occurred while fetching company:", error);
        setCompanyName("Failed to fetch company");
      }
    };

    const fetchCreditProjectDetails = async () => {
      try {
        const creditProjectResponse = await fetchLatestCreditProject(companyId);
        if (
          creditProjectResponse &&
          creditProjectResponse.credit_projects.length > 0
        ) {
          const latestProjectId =
            creditProjectResponse.credit_projects[0].id.toString();
          setHspId(creditProjectResponse.credit_projects[0].hsp_id);
          if (!projectParamId) {
            setCreditProjectId(latestProjectId);
            params.set("credit_project_id", latestProjectId);
            navigate(`?${params.toString()}`, { replace: true });
          }
        }
      } catch (error) {
        console.error(
          "An error occurred while fetching latest credit project:",
          error
        );
        setHspId(null);
      }
    };

    const fetchAllCreditProjectsDetails = async () => {
      try {
        const allCreditProjectsResponse = await fetchAllCreditProjects(
          companyId
        );
        if (allCreditProjectsResponse) {
          setCreditProjects(allCreditProjectsResponse);
        }
      } catch (error) {
        console.error(
          "An error occurred while fetching all credit projects:",
          error
        );
      }
    };

    const fetchData = async () => {
      await fetchCompanyDetails();
      await fetchCreditProjectDetails();
      await fetchAllCreditProjectsDetails();
    };

    fetchData();
  }, [companyId, location.search, navigate]);

  const environment = process.env.REACT_APP_ENVIRONMENT;

  useEffect(() => {
    const path = location.pathname.split("/");
    const id = path[2];
    setCompanyId(Number(id));
  }, [location.pathname]);

  const handleCreditProjectChange = (event) => {
    const newProjectId = event.target.value;
    setCreditProjectId(newProjectId);
    setHspId(
      creditProjects.find((project) => project.id === Number(newProjectId))
        .hsp_id
    );
    const params = new URLSearchParams(location.search);
    params.set("credit_project_id", newProjectId);
    navigate(`?${params.toString()}`, { replace: true }); // Update URL without reloading page
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="fixed">
        <Toolbar
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography
              variant="h6"
              noWrap
              component="div"
              sx={{
                textTransform: "uppercase",
                backgroundColor: "#4ba79b",
                padding: "0.2rem 0.8rem",
                borderRadius: "5px",
                marginRight: "20px", // Add some space between this and the select
              }}
            >
              Risk Studio
              {environment !== "production" && (
                <Typography
                  sx={{
                    color: "red",
                    textTransform: "uppercase",
                    borderRadius: "5px",
                    marginLeft: "10px", // Ensure it doesn't stick to the main title
                  }}
                >
                  {environment}
                </Typography>
              )}
            </Typography>
            <Select
              value={creditProjectId || ""}
              onChange={handleCreditProjectChange}
              displayEmpty
              inputProps={{ "aria-label": "Without label" }}
              sx={{
                color: "white",
                borderColor: "white",
                width: "350px", // Maintain wider select
              }}
              renderValue={(selectedValue) => {
                // Find the selected project based on creditProjectId
                const project = creditProjects?.find(
                  (p) => p.id.toString() === selectedValue
                );
                if (!project) return <em>Select Credit Project</em>;

                // Render the custom display for the selected project
                return (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    <span
                      style={{ color: "white" }}
                    >{`${project.year}/${project.number}`}</span>
                    <Chip
                      label={project.aasm_state_v2}
                      size="small"
                      sx={{
                        fontWeight: "bold",
                        color: "white",
                        backgroundColor: [
                          "cancelled",
                          "defaulted",
                          "invalidated",
                          "declined",
                        ].includes(project.aasm_state_v2.toLowerCase())
                          ? "red"
                          : "green",
                      }}
                    />
                  </Box>
                );
              }}
            >
              {creditProjects?.map((project) => (
                <MenuItem
                  key={project.id}
                  value={project.id.toString()}
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    fontWeight: "bold", // Make text bold
                  }}
                >
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <span>{`${project.year}/${project.number}`}</span>
                    <span
                      style={{ marginLeft: 16, flexGrow: 1 }}
                    >{`${project.title}`}</span>
                  </Box>
                  <Chip
                    label={project.aasm_state_v2}
                    size="small"
                    sx={{
                      ml: 1,
                      fontWeight: "bold",
                      color: "white",
                      backgroundColor: [
                        "cancelled",
                        "defaulted",
                        "invalidated",
                        "declined",
                      ].includes(project.aasm_state_v2.toLowerCase())
                        ? "red"
                        : "green", // Conditionally set color
                    }}
                  />
                </MenuItem>
              ))}
            </Select>
          </Box>
          <Box display="flex" alignItems="center">
            <Typography
              variant="h6"
              component="div"
              sx={{ marginRight: hspId ? "20px" : "40px" }}
              title="Go to company page"
            >
              <Link
                href={`${process.env.REACT_APP_CSP_BASE_URL}/companies/${companyId}`}
                target="_blank"
                rel="noopener noreferrer"
                sx={{ color: "white", display: "flex", alignItems: "center" }}
              >
                <BusinessIcon
                  sx={{ margin: "0px 5px 3px 0px", fontSize: "23px" }}
                />
                {companyName}
              </Link>
            </Typography>
            {hspId && (
              <Link
                title="Go to Hubspot"
                href={`https://app.hubspot.com/contacts/5981278/deals/${hspId}`}
                target="_blank"
                rel="noopener noreferrer"
                disabled={hspId !== null}
                sx={{
                  color: (theme) => theme.palette.text.secondary,
                  margin: 0,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Box
                  component="img"
                  sx={{
                    height: "27px",
                    width: "27px",
                    objectFit: "cover",
                    filter: hspId
                      ? "sepia(50%) drop-shadow(1px 1px 3px #ff7043)"
                      : "grayscale(100%)",
                    marginRight: "20px",
                    padding: 0,
                    transition:
                      "all 0.25s cubic-bezier(0.175, 0.885, 0.32, 1.275)",
                    "&:hover": {
                      filter: "sepia(0%)",
                      transform: "scale(1.1)",
                    },
                    "&:active": {
                      transform: "scale(0.95)",
                    },
                  }}
                  alt="hubspot-icon"
                  src={hspIcon}
                />
              </Link>
            )}
            <CompanySelection />
          </Box>
        </Toolbar>
      </AppBar>
    </Box>
  );
}
