import PropTypes from "prop-types";
import clsx from "clsx";
import React from "react";
import NumberField from "./ProformaNumberField";
import { InputAdornment, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { DataGrid } from "@mui/x-data-grid";
import { riskStudioColors as colors } from "../../styles/colors";

const ProformaGrids = ({
  proformaPositions,
  values,
  handleChange,
  renderType,
}) => {
  // HELPERS FOR GRID COLUMNS
  const getHeader = (title) => {
    return () => (
      <strong style={{ color: colors.balanceSumTextColor }}>{title}</strong>
    );
  };
  const getClassName = (params) => {
    return clsx("proforma_position", {
      "proforma_position--text": params.row.type === "input",
    });
  };
  const handleRenderCell = (params) => {
    const percentageIds = [
      "proforma_sources_3",
      "proforma_sources_7",
      "proforma_uses_9",
    ];
    const getValues = (params) => {
      if (params.id === "proforma_sources_3") {
        return 10;
      }
      if (
        params.id === "proforma_sources_7" ||
        params.id === "proforma_uses_9"
      ) {
        return 5;
      }
      return values[params.id] || 0;
    };
    return params.row.type === "input" ? (
      <NumberField
        params={params}
        value={getValues(params)}
        key={params.id}
        label={params.row.name}
        onChange={handleChange(params.id)}
        InputProps={{
          startAdornment: (
            <InputAdornment position="end">
              {percentageIds.includes(params.id) ? "%" : ""}
            </InputAdornment>
          ),
          inputProps: {
            style: { textAlign: "right" },
          },
        }}
        size="small"
        variant="standard"
      />
    ) : (
      <Typography key={params.id} sx={{ color: "teal" }}>
        {isNaN(values[params.id])
          ? 0
          : new Intl.NumberFormat("de-DE").format(values[params.id].toFixed(0))}
      </Typography>
    );
  };

  // GRID COLUMNS - COMMON PROPERTIES
  const commonColumnPropsForPosition = {
    width: 300,
    headerAlign: "left",
    type: "text",
    align: "left",
  };
  const commonColumnPropsForInputs = {
    width: 160,
    type: "number",
    editable: false,
  };

  // SOURCE COLUMN
  const sourceColumn = [
    {
      ...commonColumnPropsForPosition,
      field: "sourceName",
      renderHeader: getHeader("Position Name"),
      cellClassName: getClassName,
    },
    {
      ...commonColumnPropsForInputs,
      field: "sourceInput",
      renderHeader: getHeader("Amount"),
      renderCell: handleRenderCell,
    },
  ];

  // USES COLUMN
  const usesColumn = [
    {
      ...commonColumnPropsForPosition,
      field: "usesName",
      renderHeader: getHeader("Position Name"),
      cellClassName: getClassName,
    },
    {
      ...commonColumnPropsForInputs,
      field: "usesInput",
      renderHeader: getHeader("Amount"),
      renderCell: handleRenderCell,
    },
  ];

  // GRID ROWS
  const sourceRow = proformaPositions.sources.map((item) => ({
    id: item.id,
    sourceName: item.name,
    sourceInput: values[item.id] || 0,
    type: item.type,
  }));

  const usesRow = proformaPositions.uses.map((item) => ({
    id: item.id,
    usesName: item.name,
    usesInput: values[item.id] || 0,
    type: item.type,
  }));

  return (
    <Box>
      <Box
        sx={{
          backgroundColor: colors.gridWrapperPrimaryColor,
          borderRadius: "4px 4px 0px 0px",
          padding: "0.2rem 1rem",
          textTransform: "uppercase",
          margin: 0,
        }}
      >
        <Typography
          variant="h7"
          color={colors.white}
          fontWeight="bold"
          sx={{ margin: 0 }}
        >
          {renderType === "sources" ? "sources" : "uses"}
        </Typography>
      </Box>
      <DataGrid
        columns={renderType === "sources" ? sourceColumn : usesColumn}
        rows={renderType === "sources" ? sourceRow : usesRow}
        hideFooter={true}
        density="compact"
        getRowHeight={() => "auto"}
        getRowClassName={(params) => {
          if (params.row.type !== "input") {
            return "super-app__proforma-text-row";
          }
        }}
        sx={{
          "& .MuiDataGrid-row.Mui-selected": {
            backgroundColor: colors.transparent,
          },
          "& .MuiDataGrid-row.Mui-selected.Mui-hovered": {
            backgroundColor: colors.transparent,
          },

          "& .MuiDataGrid-row.Mui-hovered": {
            backgroundColor: colors.gridBorderColor,
          },

          "& .MuiDataGrid-cell:focus": {
            outline: "none",
          },
          "& .super-app__proforma-text-row": {
            backgroundColor: colors.lightGray,
          },
          "&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell": {
            py: "5px",
          },
          "& .MuiDataGrid-cell.proforma_position.proforma_position--text": {
            backgroundColor: colors.gridInsideColorLight,
          },
          "& .MuiDataGrid-columnHeaders": {
            border: `1px solid ${colors.gridBorderColor}`,
            borderRadius: "0px",
          },
          borderRadius: "0px 0px 5px 5px",
        }}
      />
    </Box>
  );
};

// PROPS VALIDATIONS
ProformaGrids.propTypes = {
  proformaPositions: PropTypes.shape({
    sources: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired || PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired,
      })
    ).isRequired,
    uses: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired || PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired,
      })
    ).isRequired,
  }).isRequired,
  values: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  renderType: PropTypes.string.isRequired,
};

export default ProformaGrids;
