import React from "react";
import {
  FormControlLabel,
  RadioGroup,
  Radio,
} from "@mui/material";

const OptionsRadioGroup = ({ selectedOption, onOptionChange }) => {
  return (
    <RadioGroup
      aria-label="options"
      name="options"
      value={selectedOption}
      onChange={onOptionChange}
    >
      <FormControlLabel
        value="option1"
        control={<Radio />}
        label="From Comline"
      />
      <FormControlLabel
        value="option2"
        control={<Radio />}
        label="From HGB Input csv"
        data-testid="import-csv"
      />
      <FormControlLabel
        value="option3"
        control={<Radio />}
        label="From extracted Susa"
      />
      <FormControlLabel value="option4" control={<Radio />} label="Manual" />
        <FormControlLabel
            value="option5"
            control={<Radio />}
            label="From Risk Excel"
        />
    </RadioGroup>
  );
};

export default OptionsRadioGroup;
