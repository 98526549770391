import React, { useState, useEffect } from "react";
import { Box, Link, Typography } from "@mui/material";
import EditDataGridWrapper from "../EditDataGrid/EditDataGridWrapper";
import { useNavigate } from "react-router-dom";
import { riskStudioColors as colors } from "../../styles/colors";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import PropTypes from 'prop-types';

const FinancialInputComponent = ({
  groupId,
  refreshData,
  handleUpdate,
  borrowerGroupId,
  title,
  companyId,
  showPositions,
  editable,
  associatedWithBorrowerGroup = true,
}) => {
  const [stickyStyle, setStickyStyle] = useState(false);
  const navigate = useNavigate();

  const handleLinkClick = () => {
    const params = new URLSearchParams();
    params.append("time_slice_group_id", groupId);
    params.append("borrower_group_id", borrowerGroupId);

    navigate({
      pathname: `/company/${companyId}/input`,
      search: params.toString(),
    });
  };

  const gridConfigs = [
    { heading: "Aktiva", positionType: "activa" },
    { heading: "Passiva", positionType: "passiva" },
    { heading: "Bilanzsummen Check", positionType: "ap_sum" },
    { heading: "Gewinn- und Verlustrechnung", positionType: "pl" },
    { heading: "Andere", positionType: "other" },
  ];

  const renderGrid = (config, i) => (
    <EditDataGridWrapper
      groupId={groupId}
      heading={config.heading}
      positionType={config.positionType}
      handleUpdate={handleUpdate}
      refreshData={refreshData}
      borrowerGroupId={borrowerGroupId}
      showPositions={showPositions}
      editable={editable}
      key={`edit_data-grid__${i + 1}`}
    />
  );
  useEffect(() => {
    const handleScrollEvent = () => {
      if (window.scrollY > 100) {
        setStickyStyle(true);
      } else if (window.scrollY < 100) {
        setStickyStyle(false);
      }
    };
    window.addEventListener("scroll", handleScrollEvent);
    return () => window.removeEventListener("scroll", handleScrollEvent);
  }, []);


  // @renderStickyHeader - this creates the sticky header for company names and links in case of companyId is present
  const renderStickyHeader = () => {
    return (
      <Box
        sx={{
          padding: "0.5rem",
          backgroundColor: stickyStyle
            ? colors.gridWrapperPrimaryColor
            : "#fff",
          position: "sticky",
          top: "60px",
          zIndex: 1,
          color: stickyStyle ? colors.white : colors.gridWrapperPrimaryColor,
          textTransform: "uppercase",
          transition: "none",
          borderLeft: `2px solid ${colors.gridBorderColor}`,
          borderTop: stickyStyle
            ? `2px solid ${colors.gridBorderColor}`
            : "none",
          borderRight: stickyStyle
            ? `2px solid ${colors.gridBorderColor}`
            : "none",
          borderBottom: stickyStyle
            ? `2px solid ${colors.gridBorderColor}`
            : "none",
        }}
      >
        <Typography sx={{ fontWeight: "bold" }}>
          {companyId ? (
            <Link
              onClick={handleLinkClick}
              sx={{
                color: stickyStyle
                  ? colors.white
                  : colors.gridWrapperPrimaryColor,
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
              }}
            >
              <OpenInNewIcon sx={{ marginRight: "5px", fontSize: "1rem" }} />
              {title}
            </Link>
          ) : (
            title
          )}
        </Typography>
      </Box>
    );
  };
  const commonFlexStyles = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexWrap: "wrap",
    gap: "50px",
    marginTop: "0.5rem",
  };
  return (
    <Box>
      {associatedWithBorrowerGroup && renderStickyHeader()}
      <Box style={commonFlexStyles}>
        {gridConfigs.slice(0, 2).map(renderGrid)}
      </Box>
      <Box style={{ ...commonFlexStyles, justifyContent: "center" }}>
        {renderGrid(gridConfigs[2])}
      </Box>
      <Box style={commonFlexStyles}>{gridConfigs.slice(3).map(renderGrid)}</Box>
    </Box>
  );
};

// Props Validation for this component
FinancialInputComponent.propTypes = {
  groupId: PropTypes.string.isRequired,
  refreshData: PropTypes.bool.isRequired,
  companyId: PropTypes.string,
  handleUpdate: PropTypes.func.isRequired,
  borrowerGroupId: PropTypes.string,
  title: PropTypes.string,
  showPositions: PropTypes.bool.isRequired,
  editable: PropTypes.bool.isRequired,
  associatedWithBorrowerGroup: PropTypes.bool,
};

export default FinancialInputComponent;
